export default {
    common: {
        expected: "Revenus attendus",
        view: "Voir",
        confirm: "Confirmation",
        cancel: "Annulation",
        deposit: "Dépôt",
        withdraw: "Retrait",
        noData: "Aucune donnée disponible pour le moment",
        submit: "Soumettre",
        modify: "Modifier",
        hand: "Les mains",
        confirm_verify: "Confirmer la vérification",
        next: "Prochaines étapes",
        complete: "Dorn",
        delete: "Supprimer",
        countryCode: "Code du pays",
        no_balance: "Solde insuffisant du compte",
    },
    currency: {
        btcusdt: "Bitcoin",
        ethusdt: "éthériquement",
        ltcusdt: "Litecoin",
        xrpusdt: "Ripple (pièces de monnaie)",
        adausdt: "Ada currency",
        eosusdt: "Pomelo Coin",
        dotusdt: "Boca coin",
        trxusdt: "Wave field coin",
        xmrusdt: "Monroe Coin",
        dogeusdt: "Dogecoin",
        bnbusdt: "BNB",
        solusdt: "SOL",
        aptusdt: "Aptusdt",
        fx_saudusd: "AUD USD",
        fx_sgbpusd: "GBP USD",
        fx_seurusd: "Eurodollar",
        fx_snzdusd: "New Zealand Dollar (NZD) USD",
        fx_susdcad: "USD CAD",
        fx_susdchf: "USD CHF",
        fx_susdjpy: "USD JPY",
        fx_saudcad: "AUD CAD",
        fx_saudchf: "AUD CHF",
        fx_saudjpy: "AUD JPY",
        fx_saudnzd: "AUD New Zealand Dollar (NZD)",
        fx_scadchf: "CAD Swiss Franc",
        fx_scadjpy: "Canadian yen",
        fx_schfjpy: "CHF JPY",
        fx_seuraud: "EUR AUD",
        fx_seurcad: "EUR CAD",
        fx_seurgbp: "Euro Pound",
        fx_seurjpy: "Euro Yen",
        fx_seurnzd: "Euro New Zealand dollar (NZD)",
        fx_sgbpaud: "GBP AUD",
        fx_sgbpchf: "GBP CHF",
        fx_sgbpjpy: "GBP JPY",
        fx_sgbpnzd: "GBP NZD",
        fx_scadnzd: "Dollars canadiens et néo - zélandais",
        hf_CL: "WTI New York Crude Oil",
        hf_OIL: "Pétrole brut Brent",
        hf_GC: "New York gold",
        hf_SI: "New York silver",
        hf_HG: "Cuivre (US)",
        hf_NG: "U.S. Gaz naturel",
        hf_CAD: "Cuivre (Chimique)",
        hf_AHD: "Aluminium à Londres",
        HX_AAPL: "Grenade",
        HX_BABA: "Société chinoise de commerce électronique Alibaba",
        HX_GOOG: "Google",
        HX_WMT: "Walmart, Walmart (détaillant)",
        HX_AMZN: "HX_AMZN",
        HX_TSLA: "Tesla",
        HX_N225: "Nikkei 225",
        HX_GDAXI: "Allemagne",
        HX_IBEX: "IBEX35, Espagne",
        HX_SPX: "S&P 500 index",
        HX_FTSE: "FTSE 100",
        HX_KS11: "KOSPI Corée du Sud",
        HX_NZ50: "Nouvelle - Zélande 50",
        HX_PSI20: "Portugal PSI20",
        hf_ZSD: "London Zinc",
        hf_NID: "London Nickel",
        hf_PBD: "London lead",
        hf_SND: "London tin",
        hf_XAU: "London Gold (Spot Gold)",
        hf_XAG: "London Silver (Spot Silver)",
        hf_XPT: "Futures platine",
        hf_XPD: "Contrats à terme sur Palladium",
        hf_CT: "US Coton",
        hf_SM: "American Tourteau de soja américainsoybean meal",
        hf_BO: "U.S. Huile de soja",
        HX_SENSEX: "SENSEX, Mumbai, India",
        HX_KLSE: "FTSE Malaisie KLCI",
        HX_KSE100: "Karachi, Pakistan",
        HX_FCHI: "CAC40, France",
        HX_SX5E: "Européen snooker 50",
        HX_ICEXI: "ICEX, Islande",
        HX_ASE: "ASE Athens, Grèce",
        HX_OMXC20: "OMX Copenhague 20",
        HX_OSEBX: "OSEBX, Norvège",
        HX_OMXSPI: "OMXSPI, Suède",
        HX_AMD: "Chaowei Semi - conducteurs",
        HX_DIS: "Disney (nom)",
        HX_MELI: "Le marché libre Inc",
        HX_PSX: "Phillips 66",
        HX_TRUE: "TrueCar Inc",
        HX_SDRL: "Seadrill Ltd",
        HX_MA: "MasterCard (La marque)",
        HX_NVDA: "NVIDIA, Société de cartes graphiques informatiques",
        HX_INTC: "Intel",
        HX_GOGO: "Gogo Inc",
        HX_STX: "Technologie Seagate",
        usdcusdt: "USDC",
        filusdt: "FIL",
        maticusdt: "MATIC",
        linkusdt: "LINK",
        avaxusdt: "AVAX",
        atomusdt: "AstroCoin",
        icpusdt: "ICP",
        uniusdt: "UNI",
    },
    currenctType: {
        title: "Veuillez sélectionner une devise",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dollar',
        currenctType15: 'Hong Kong currency',
        currenctType16: 'Taiwan currency',
        currenctType17: 'Ringgit',
        currenctType18: 'Singapore dollar',
        currenctType19: 'Yen',
        currenctType20: 'Euro',
        currenctType21: 'Dollar A',
        currenctType22: 'Rupiah',
        currenctType23: 'Pound',
        currenctType24: 'Thai baht',
        currenctType25: 'Dollar',
        currenctType26: 'VND',
        currenctType27: 'Korean won',
        currenctType28: 'MOP',
    },
    addnew3: {
        direction: "Direction",
        sell_price: "Prix de vente",
        number: "Quantité",
        type: "Délai de livraison",
        buy_price: "Prix d'achat",
        profit: "P/L",
        sell_time: "Temps de vente",
    },
    addnew2: {
        address: {
            second_name: "Nom du film",
            account_type: "Type de compte",
            ifsc_code: "IFSC CODE",
            title: "Adresse du portefeuille",
            account_number: "Numéro de compte",
            add_btn: "Ajouter une adresse",
            label_type: "Choisissez le type",
            place_type: "Veuillez sélectionner le type",
            label_number: "Compte",
            place_number: "Veuillez entrer le numéro de compte",
            wallet_address: 'Adresse du portefeuille',
            bank_name: 'Nom de la Banque',
            bank_address: "Adresse de la Banque",
            name: "Nom et prénom",
            payee_address: 'Adresse du bénéficiaire',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nom de la succursale",
            branch_code: "Code de branche",
            bank_code: "Code de la Banque",
            phone: "Numéro de téléphone portable",
            iban: "IBAN",
            sort_code: "Code de tri",
            place_inner: "Veuillez entrer le contenu",
        },
    },
    addnew: {
        realFirst: "Veuillez d'abord vous authentifier",
        payFirst: "Veuillez d'abord définir un mot de passe de transaction",
        password1: "Modifier le mot de passe de connexion",
        password2: "Modifier le mot de passe de retrait",
        paypassTit1: "Veuillez définir votre mot de passe de retrait",
        oldPass: "Ancien mot de passe",
        allView: "Lire tout",
        confirmOrder: "Confirmer la commande",
        name: "Nom et prénom",
        fee: "Coût",
        orderCycle: "Horaire",
        loan: "Prêts",
        loanTit: "Prêts d'aide",
        loanHostory: "Enregistrement des prêts",
        labelProductTitle: "Le nom",
        immediateRepayment: "Remboursement immédiat",
        loadAmount: "Montant estimé du prêt",
        RepaymentCycle: "Cycle de remboursement des prêts",
        day: "Jour",
        dailyRate: "Prix par jour",
        repaymentMethod: "Modalités de remboursement",
        lendingCompany: "Sociétés de prêt",
        loanTip: "Prêt de crédit (s'il vous plaît assurez - vous que les images sont clairement visibles)",
        loanTip1: "Télécharger un certificat de propriété",
        loanTip2: "Preuve de revenu (relation de travail)",
        loanTip3: "Détails du relevé bancaire",
        loanTip4: "Télécharger une photo du recto de votre carte d'identité",
        interest: "Intérêt",
        repaid: "Remboursé",
        unpaid: "Non payé",
        tab1: "Crypto - monnaie",
        tab2: "Le contrat",
        tab3: "Options",
        available: "Restrictions disponibles",
        pending: "La serrure",
        equivalent: "Équivalent",
        fundTransfer: "Transfert de fonds",
        flashExchange: "Échange flash",
        select_breed: "Choisir une devise",
        from: "À partir de",
        to: "À",
        transferQuantity: "Nombre de transferts",
        repaymentMethod1: "Remboursement forfaitaire dû"
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Marchandises",
        product_number: "Index",
        product_stock: "Partager",
        product_coin: "Crypto - monnaie",
        billTypes: [
            { v: 101, name: 'Recharge' },
            { v: 102, name: 'Dépôt' },
            { v: 103, name: 'Congelé' },
            { v: 104, name: 'Décongeler' },
            { v: 105, name: 'Dépôt' },
            { v: 106, name: 'Dépôt' },
            { v: 201, name: 'En suspens' },
            { v: 202, name: 'Retrait' },
            { v: 203, name: 'Succès' },
            { v: 204, name: 'Le retrait a échoué' },
            { v: 205, name: 'Frais de traitement des retraits' },
            { v: 206, name: 'Transfer out' },
            { v: 207, name: 'Transfer in' },
            { v: 208, name: 'Transfert de pièces de monnaie' },
            { v: 209, name: 'Transfert de pièces de monnaie' },
            { v: 301, name: 'Frais de traitement du contrat' },
            { v: 302, name: 'Revenus des contrats' },
            { v: 303, name: 'Pertes liées aux contrats' },
            { v: 304, name: 'Dépôt de garantie du contrat' },
            { v: 305, name: 'Remboursement du dépôt de garantie contractuel' },
            { v: 311, name: 'Options dachat' },
            { v: 312, name: 'Retour sur Options' },
            { v: 313, name: 'Option remboursement' },
            { v: 314, name: 'Frais de traitement des options' },
            { v: 315, name: 'Gel des achats de devises' },
            { v: 316, name: 'Déduction pour achat de devises' },
            { v: 317, name: 'Coins acheter retours' },
            { v: 318, name: 'Acheter les pièces de monnaie reçues' },
            { v: 319, name: 'Acheter les pièces de monnaie reçues' },
            { v: 320, name: 'Gel des ventes de devises' },
            { v: 321, name: 'Déduction pour les ventes monétaires' },
            { v: 322, name: 'Retour de vente de pièces de monnaie' },
            { v: 323, name: 'Pièces vendues au compte' },
            { v: 324, name: 'Pièces vendues au compte' },
            { v: 325, name: 'Frais de traitement monétaire' },
            { v: 401, name: 'Machine Minière ajouter' },
            { v: 402, name: 'Retour de machines minières' },
            { v: 403, name: 'Revenus des machines minières' },
            { v: 404, name: 'Exportation de machines minières' },
            { v: 405, name: 'Frais de traitement à lexportation des machines minières' },
            { v: 411, name: 'Revenus demprunt' },
            { v: 412, name: 'Coûts de règlement' },
            { v: 413, name: 'Remboursement réussi' },
        ],
        transType0: "En stock",
        transType1: "Le contrat",
        directionType0: "Acheter",
        directionType1: "Vendre",
        lockStateType0: "Courir",
        lockStateType1: "C'est fini",
        lockStateType2: "已取消",
    },
    lockming: {
        profit: "Récent (revenu quotidien)",
        cycle: "Le cycle financier",
        limit: "Une seule restriction",
        dividend_time: "Temps de paiement des dividendes",
        every_day: "Quotidienne",
        funds: "Fonds gérés",
        back: "Retour dû",
        ransom: "Rachat anticipé",
        label_amount: "Montant du gage",
        place_amount: "Veuillez entrer,,,",
        available: "Disponible",
        expect_income: "Revenus attendus",
        title: "Verrouiller l'exploitation minière",
        tip: "Génération de revenus miniers",
        label_today_income: "Bénéfices estimés aujourd'hui",
        label_total_income: "Revenus cumulés",
        label_order: "Ordres gérés",
        label_number: "Montant unique",
        daily_return: "Retours quotidiens",
        subscribe: "S'abonner",
        position: "Position",
        label_state: "Statut",
        subscribe_time: "subscription_time",
        expiration_time: "Temps d'expiration",
        income: "Revenus",
        errorTip1: "Veuillez entrer le montant",
        tip_tit: "Êtes - vous sûr de vouloir quitter tôt",
    },
    footer: {
        nav1: "Page d'accueil",
        nav2: "Commerce",
        nav3: "Positions",
        nav4: "Hub",
        kline_set1: "Paramètres du marché",
        kline_set2: "Paramètres de transaction",
    },
    login: {
        login: "Se connecter",
        register: "Enregistrement",
        type1: "Numéro de téléphone",
        type2: "Email",
        login_btn: "Se connecter",
        register_btn: "Inscrivez - vous maintenant",
        psd_login: "Mot de passe login",
        verify_login: "CAPTCHA login",
        forget: "Mot de passe oublié?",
        no_account: "Pas de numéro de compte?",
        have_account: "Comptes existants",
    },
    home: {
        home: {
            nav1: "Classement quotidien",
            nav2: "La base",
            nav3: "À propos de nous",
            nav4: "Présentation",
            most_follow: "Populaire",
            news_type1: "Strates",
            news_type2: "Informations de presse",
            news_type3: "Calendrier",
            news_tab1: "Tous",
            news_tab2: "Forex",
            news_tab3: "Partager",
            news_tab4: "Marchandises",
            news_tab5: "Index",
            news_tab6: "Données",
            news_tab7: "Activités",
            news_tab8: "Vacances",
            label_previous: "Page précédente",
            label_predictive: "Prédictivité",
            label_announced: "Annoncé ",
        },
        kilne: {
            label_bp: "Fermé",
            label_sp: "Ouverture normale",
            label_height: "Hauteur",
            label_low: "Valeur minimale ",
            tip: "Bonjour, je suis CXMDirectsgpt Breed Assistant",
            row1_tit: "Stratégie",
            row1_rinei: "Dans la journée",
            row1_short: "Court terme",
            row1_middle: "Moyen terme",
            row1_time: "Heure de publication",
            row1_tit2: "Stratégie de Trading",
            data_sources: "Source des données",
            row2_tit1: "des données",
            row2_tit2: "Informations",
            row2_label_sell: "Vendeur",
            row2_label_buy: "Acheteur",
            tip2: "Pour information seulement, pas comme position de notre société",
            tip3: "Mise à jour toutes les 15 minutes",
            row2_tit3: "Fluctuations des prix",
            minute: "Minutes",
            today: "Aujourd'hui",
            right_tit7: "Gamme de changement de prix",
            now_price: "Prix actuel",
            right_low_price: "Faible",
            right_height_price: "Hauteur",
            details: "Détails",
            right_label1: "Nombre de transactions individuelles",
            right_label2: "Levier maximal",
            right_label3: "Taille du contrat",
            right_label4: "Maximum total des positions",
            right_label5: "Spread flottant",
            right_label6: "Frais de nuitée",
            hand: "Hand",
            right_sell: "Vendu",
            right_buy: "Acheter",
            right_collection_time: "Temps de collecte",
            right_tip3: "Collecter à partir de toute la taille de la transaction",
            right_label9: "Séance de Trading",
            right_label10: "Cette période",
            right_label11: "La prochaine période",
        },
        placeorder: {
            direction_buyPrice: "Taux d'achat",
            direction_sellPrice: "Taux de vente",
            direction_sell: "Vendu",
            direction_buy: "Acheter",
            market_price: "Ordre du marché",
            check_price: "Le Registre",
            sell_tip: "Vendre lorsque le prix est éligible",
            buy_tip: "Acheter lorsque le prix est éligible",
            trade_number: "Nombre de transactions",
            hand: "Les mains",
            select_lever: "Sélectionnez la barre",
            right_tip: "Dépôt requis",
            balance: "Disponible",
            label_stop_win_price: "Arrêter le surplus",
            label_stop_lose_price: "Stop perte",
            expected_profit_loss: "Gains et pertes attendus",
            trailing_stop: "Stop à la fin",
        },
        ranking: {
            tit: "Classement jour haut et bas",
            p: "Capturer les points chauds, saisir les opportunités",
            renew: "Mise à jour en",
            growth_chart: "Table de courbes de croissance",
            drop_list: "Liste déroulante",
            show_closed_market: "Afficher les marques hors site",
            filter1: "L'ensemble",
            filter2: "Les étrangers ",
            filter3: "Marchandises",
            filter4: "Certificat d'actions",
            filter5: "Index",
            filter6: "Crypto - monnaie",
            td1: "Classement",
            td2: "Les tendances",
            td3: "Augmentation quotidienne / prix de vente",
        },
        remind: {
            now_sell_price: "Prix de vente actuel",
            direction_buyPrice: "Taux d'achat",
            direction_sellPrice: "Taux de vente",
            direction_sell: "Vendu",
            direction_buy: "Acheter",
            equal: "Égalité",
            p: "Avertissez - moi lorsque les prix suivants sont atteints",
            btn1: "Définir un rappel",
            list_tit: "Liste de rappels",
        },
    },
    my: {
        report: {
            tit: "Rapport de compte",
            tab1: "Rapport",
            tab2: "Déclaration quotidienne",
            tab3: "Relevé mensuel",
            history_tit: "Rapport sur l'historique des transactions",
            account_tit: "Rapport de compte",
            filter: "L'écran",
            tip1: "Veuillez sélectionner la plage de dates appropriée pour exporter votre rapport d'historique de Trading.",
            tip2: "Temps personnalisé",
            email_send: "Envoyer à e - mail",
        },
        accountMsg: {
            tit: "Informations sur le compte",
            label1: "Informations personnelles",
        },
        bill: {
            tit: "Détails des flux de capitaux",
            all: "L'ensemble",
            time: "Le temps",
        },
        feedback: {
            tit: "Recommandations fonctionnelles",
        },
        identity: {
            tit: "Données personnelles complètes",
            type1: "Carte nationale d'identité",
            type2: "Permis de conduire",
            type3: "Passeport",
            tip: "Je confirme que toutes les informations et documents fournis sont complets, véridiques et exacts",
        },
        my: {
            is: "Arrêtez",
            no: "Non vérifié",
            verify: "Confirmé",
            id_verify: "Authentification",
            verify_tip: "Complétez l'authentification pour déposer et commencer à trader",
            no_verify: "Vérifier maintenant",
            balance: "Disponible",
            profit_loss: "Gains et pertes",
            marginLevel: "Niveau de marge",
            btn1: "Passer à un compte réel",
            deposit_withdraw_record: "Enregistrement des dépôts et retraits",
            capital_flow_details: "Détails des flux de capitaux",
            welfare_center: "Centre de bien - être",
            tip1: "Vous avez",
            tip2: "Recevoir un Pen Award",
            feedback: "Recommandations fonctionnelles",
            report: "Rapport de compte",
            setting: "Paramètres",
        },
        news: {
            tit: "Précautions",
        },
        record: {
            tit: "Enregistrement des dépôts et retraits",
            cycle: "Le cycle",
        },
        share: {
            tit: "Inviter un ami",
            invite_success: "Amis invités avec succès",
            tip1: "Récompenses en espèces",
            tip2: "Inviter un ami au plus haut",
            tip3: "Inviter un ami à recevoir de l'argent",
        },
        welfare: {
            tit: "Centre de bien - être",
            tab1: "Rouleau de cartes",
            tab2: "Activités",
            history_record: "Historique",
            period_validity: "Période de validité",
        },
        wallet: {
            tit: "Portefeuille",
            addNew: "Ajouter un nouveau portefeuille",
        },
        withdraw: {
            tit: "Retrait",
            addNew: "Ajouter un nouveau portefeuille",
        },
    },
    order: {
        demo_account: "Compte démo",
        real_account: "Compte réel",
        tip: "Après l'ouverture, l'or peut être déposé pour le commerce",
        tab1: "Positions",
        tab2: "Le Registre",
        tab3: "Historique",
        list_empty: "Aucune commande en attente pour le moment",
        start_trade: "Commencer à trader",
        hand_tip: "Gamme d'entrée",
        kline: {
            direction_sell: "Vendu",
            direction_buy: "Acheter",
            open_price: "Prix d'ouverture",
            now_price: "Prix actuel",
            trade_account: "Compte de Trading",
            demo_account: "Compte démo",
            order_id: "Id de commande",
            open_time: "Heures d'ouverture",
            stop_profit: "Arrêter le surplus",
            stop_loss: "Stop perte",
            trailing_stop: "Stop à la fin",
            float_profit_loss: "Flottant P/L",
            overnight_fee: "Frais de nuitée",
            margin: "Dépôt de garantie",
            maintainsMargin: "Maintenir les profits",
            open_value: "Prix d'ouverture",
            now_value: "Valeur actuelle",
            notset: "Non défini",
            close_position: "Position fermée",
            close_number: "Quantité normale",
            onclick_close: "Position de fermeture en un clic",
            btn: "Confirmer la position de fermeture",
            balance: "Disponible",
            profit_loss: "Gains et pertes",
            netValue: "Valeur nette",
            marginLevel: "Niveau de marge",
        },
        modify: {
            tit: "Modifier l'ordre des positions",
            expected_profit_loss: "Gains et pertes attendus",
        },
    },
    setting: {
        security: {
            modify_password_tit: "Définir un nouveau mot de passe",
            verify_email_tit: "Vérifier l'email",
            verify_password_tit: "Vérifiez votre mot de passe",
            verify_password_tip: "Entrez votre mot de passe CXMDirects login pour continuer",
            verify_phone_tit: "Vérifier le numéro de téléphone",
            tit: "Sécurité",
            tip: "Pour sécuriser votre compte et accepter nos notifications de service, il est recommandé d'activer au moins un type d'authentification.",
            label1: "Changer le mot de passe",
            label2: "Se désinscrire",
            label3: "Double validation",
            label4: "Gestion des équipements",
            tip2: "Vérification requise pour la connexion au nouvel appareil",
        },
        tradenews: {
            tit: "Guide de Trading",
            tab1: "Démarrage rapide",
            tab2: "Apprentissage avancé",
            tab3: "Aide",
            tab4: "À propos de CXMDirects",
            viewMore: "Voir plus",

            details: {
                tit: "Introduction au Forex",
                tip1: "Temps d'étude estimé",
                tip2: "Minutes",
            },
            tab1Row: {
                tit: "C'est une salle de classe pour apprendre rapidement à trader avec CXMDirects",
                tit1: "Comment trader sur CXMDirects?",
                tit2: "Comment ouvrir un compte réel？",
                tit3: "Comment ouvrir une position de Trading？",
                tit4: "Comment mettre en place des arrêts et des pertes？",
                tit5: "Comment fermer une position？",
                p1: "Vous pouvez Trader en achetant et en vendant des sous - jacents. Ouvrir une position chez CXMDirects signifie que vous êtes",
                p2: "Trading basé sur la volatilité des prix des produits ciblés",
                p3: "Par exemple, si vous vous attendez à ce que le prix de l'or augmente, achetez le produit; Au lieu de cela, si vous prévoyez une baisse du prix de l'or, vendez le produit. Si l'avenir répond aux attentes, vous pouvez",
                p4: "En tirer profit",
                p5: "En outre, CXMDirects propose également le « leveraged trading». Vous pouvez trader plus de cibles avec le même montant en utilisant l'effet de levier.",
                p6: "L'effet de levier peut amplifier les profits, mais aussi les pertes",
                p7: "C’est aussi ce qu’on appelle le « Trading sur marge ». Lorsque vous ouvrez une position, un certain solde est déduit de votre compte CXMDirects",
                p8: "Marge initiale",
                p9: "Choisissez le produit et la direction d'achat et de vente",
                p10: "Transactions",
                p11: "Cliquez sur 'acheter / vendre' Pour terminer votre commande",
                p12: "La mise en place d'un 'Stop - profit' ou d'un 'Stop - loss' peut vous aider",
                p13: "Verrouiller les profits à temps, limiter les pertes",
                p14: "Vous pouvez cliquer sur la page qui s'ouvre avec succès",
                p15: "Fin de gain / fin de perte",
                p16: "Configurez - le ou vous pouvez cliquer sur le bouton 'modifier' de l'ordre non équilibré pour arrêter les gains et les pertes",
                p17: "Configurer ou annuler",
                p18: "Vous pouvez voir les profits et pertes dans 'positions closes'",
            },
            tab3Row: {
                h1: "Bienvenue chez CXMDirects",
                h2: "Aide",
                tit0: "Toutes les questions",
                tit1: "Compte démo",
                tit2: "Ouvrir un compte",
                tit3: "Cash entrée et sortie",
                tit4: "Paramètres de la plateforme",
                tit5: "Opérations de Trading",
                tit6: "Frais et charges",
                tit7: "Sécurité financière et réglementation",
                tit1Tit1: "CXMDirects offre - t - il un compte démo",
                tit1Cont1: "<p>CXMDirects met à la disposition de ses clients un compte démo pour opérer, vous permettant de trader avec notre plateforme en ligne et notre application mobile et de vous familiariser avec les opérations de la plateforme sans aucun risque</p>",
                tit1Tit2: "Comment créer un compte de simulation?",
                tit1Cont2: "<p>Il vous suffit de cliquer ici pour remplir votre adresse e - mail ou votre numéro de téléphone et définir votre mot de passe personnel pour compléter votre inscription et obtenir un compte démo </p>",
                tit1Tit3: "À quoi sert un compte démo?",
                tit1Cont3: "<p>Un compte démo est fondamentalement identique à un compte réel en termes d'interface de Trading, de données et d'opérations. 50 000 $de fonds virtuels dans un compte de démonstration dans le but de familiariser les clients avec les différentes fonctionnalités de la plate - forme grâce à des opérations de simulation sans aucun risque financier</p>",
                tit1Tit4: "Comment injecter de l'argent dans un compte de démonstration?",
                tit1Cont4: "<p>Vous n'avez pas besoin d'injecter de l'argent dans votre compte de démonstration. Après avoir enregistré avec succès un compte démo, il y a un total de 50 000 USD ou AUD dans le compte < / p > < p > Lorsque la valeur nette du compte démo est inférieure à 200 USD ou AUD, le système réapprovisionne automatiquement les fonds de votre compte à 50 000 USD ou AUD.</p>",
                tit1Tit5: "Le compte de simulation a - t - il une période d'utilisation",
                tit1Cont5: "<p>Le compte démo est valable 90 jours. Si vous n'avez pas ouvert de compte réel après 90 jours, le compte démo sera bloqué, ne pourra pas être échangé et sera dégelé lorsque vous ouvrirez un compte réel.</p><p>Si vous ouvrez un compte réel dans les 90 jours, un compte démo sera valide pendant une longue période. Vous pouvez continuer à fonctionner sur un compte démo même après l'ouverture d'un compte réel</p>",
                tit2Tit1: "Combien de types de comptes CXMDirects propose - t - il?",
                tit2Cont1: "<p>Nous ne proposons que des comptes de Trading standard et professionnels pour les particuliers et ne prenons pas en charge l'ouverture de comptes corporatifs ou co - marqués.</p>",
                tit2Tit2: "Dans quelles devises puis - je ouvrir un compte de Trading?",
                tit2Cont3: "<p>Lorsque vous ouvrez un compte, le système détermine automatiquement votre devise de règlement en fonction de votre région et n'est pas modifiable</p>",
                tit2Tit4: "Comment ouvrir un compte réel?",
                tit2Cont4: "<p>Veuillez suivre les étapes ci - dessous pour ouvrir un compte réel</p><p>1. Accédez à la page d'inscription CXMDirects, suivez les instructions pour enregistrer votre numéro de téléphone e - mail, définissez un mot de passe de connexion et créez - le automatiquement après avoir soumis votre confirmation.</p><p></p><p></p>",
                tit3Tit1: "Comment puis - je faire un dépôt?",
                tit3Cont1: "<p>CXMDirects CXMDirects propose différentes méthodes de dépôt au choix du client, y compris, mais sans s'y limiter, les méthodes suivantes:</p><p>1.Visa / Mastercard - généralement reçu immédiatement</p><p>2.Les paiements bancaires en ligne sont généralement effectués 1 jour ouvré à l'avance</p><p>3.Portefeuilles électroniques (tels que Skrill, Momo, zalo.touch'n go, boost, etc.) - généralement pour une collecte instantanée</p><p>4. Carte ATM - généralement un reçu instantané</p><p>5.QR code mobile banque en ligne - généralement pour la collecte instantanée</p>",
                tit3Tit2: "Puis - je déposer ou retirer de l'argent en utilisant un compte bancaire / carte avec le nom d'un tiers?",
                tit3Cont2: "<p>CXMDirects n'accepte pas les dépôts de tiers. Veuillez utiliser un compte / carte bancaire personnel à votre nom pour effectuer des dépôts ou des retraits. Si vous effectuez un dépôt en utilisant le nom d'une autre personne ou un compte bancaire / carte d'entreprise, les fonds seront remboursés</p>",
                tit3Tit3: "Comment demander un retrait de votre compte CXMDirects?",
                tit3Cont3: "<p>Une fois connecté à la plateforme du site, vous pouvez cliquer sur 'Retrait', sélectionner un compte bancaire ou visa / Mastercard, renseigner le montant du retrait et appuyer sur 'envoyer'. Après avoir confirmé que les informations sont correctes, CXMDirects traitera votre demande dans un délai de 1 à 2 jours ouvrables.</p>",
                tit4Tit1: "Combien de leveraged Trading offre CXMDirects?",
                tit4Cont1: "<p>Les ratios de levier proposés par la plateforme CXMDirects sont fixés conformément aux réglementations des régulateurs et les leviers maximaux varient d’un produit à l’autre. Veuillez vous connecter à la plateforme de Trading pour plus de détails</p>",
                tit4Tit2: "Comment changer mon mot de passe?",
                tit4Cont2: "<p>Une fois connecté à la plate - forme, vous pouvez sélectionner [plus] - [paramètres de sécurité] - t changer le mot de passe] réinitialiser un nouveau mot de passe</p>",
                tit4Tit3: "Comment se connecter à la plateforme de Trading CXMDirects?",
                tit4Cont3: "<p>Application: Allez dans 'mon' et cliquez sur 'se connecter pour s'inscrire' en haut à gauche pour accéder à la page de connexion</p>Web: Cliquez sur 'se connecter' en haut à droite du site officiel de CXMDirects pour accéder à la page de connexion<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Quelles sont les méthodes de rappel de la plateforme?",
                tit4Cont4: "<p>CXMDirects alerte par e - mail, SMS et plateforme push.</p>",
                tit5Tit1: "Quelle différence de prix offre CXMDirects?",
                tit5Cont1: "<p>Nous facturons une différence de prix très faible et ne facturons aucune commission. Les Spreads sont flottants, les Spreads réellement facturés dépendront de la variété sur laquelle vous négociez et les informations spécifiques seront affichées en temps réel sur la plateforme de Trading.</p>",
                tit5Tit2: "Comment établir une liste de prix du marché?",
                tit5Cont2: "<p>En cliquant sur 'Trading', vous verrez tous les produits que la plate - forme peut offrir à la négociation. Vous pouvez effectuer une recherche en entrant un code de variété ou un nom dans la zone de saisie à droite, en sélectionnant un produit et en cliquant sur la fenêtre commerciale contextuelle acheter / vendre choisir / acheter. Dans la fenêtre de Trading, vous pouvez voir le prix actuel et la marge requise attendue. Vous pouvez ajuster manuellement le nombre de positions ouvertes et définir un stop loss et un stop loss pour contrôler le risque</p>",
                tit5Tit3: "Comment créer un registre?",
                tit5Cont3: "<p>Sélectionnez un produit dans [Trade] et cliquez sur [Sell / buy] une fenêtre de Trading apparaîtra</p>",
                tit5Tit4: "Comment fermer une position?",
                tit5Cont4: "<p>Pour fermer une position, dans positions sur la plateforme, cliquez sur Ouvrir, puis sélectionnez la position que vous souhaitez fermer, puis cliquez sur le bouton Fermer à droite.</p>",
                tit5Tit5: "Comment modifier ou supprimer une commande en attente?",
                tit5Cont5: "<p>Pour modifier ou supprimer une commande, cliquez sur 'commandes'dans la section 'lieux' de la plateforme View et sélectionnez la commande que vous souhaitez modifier ou supprimer.</p>",
                tit6Tit1: "Y a - t - il des frais de traitement pour l'utilisation de la plateforme de Trading?",
                tit6Cont1: "<p>CXMDirects est une plateforme de Trading totalement sans commission. Nous facturons une différence de prix inférieure et, en fonction de votre situation de Trading spécifique, la plateforme peut encourir des frais supplémentaires tels que des intérêts au jour le jour</p>",
                tit6Tit2: "Le dépôt est - il facturé?",
                tit6Cont2: "<p>Nous ne facturons pas de frais à nos clients en ce qui concerne les dépôts, mais des frais de traitement peuvent être facturés par votre banque de paiement ou intermédiaire. Nous vous recommandons de demander aux banques concernées si elles vous facturent des frais.</p>",
                tit6Tit3: "Les retraits sont - ils facturés?",
                tit6Cont3: "<p>CXMDirects ne facturera pas de frais aux clients liés aux retraits, sauf si le montant de votre retrait est inférieur au minimum requis ou dépasse le nombre maximal de retraits gratuits au cours du mois. Pour plus de détails, veuillez consulter notre politique de retrait.</p>",
                tit7Tit1: "Les fonds que j'ai déposés sur mon compte CXMDirects seront - ils utilisés à d'autres fins?",
                tit7Cont1: "<p>Non. Conformément aux dispositions réglementaires, les dépôts des clients de détail sont déposés séparément dans un compte en fiducie si nécessaire. CXMDirects se conforme strictement aux dispositions réglementaires concernant les paiements des clients lors de dépôts ou de retraits ou de paiements effectués sur le compte du client.</p>",
                tit7Tit2: "Mon argent sera - t - il également affecté si CXMDirects fait faillite ou est endetté?",
                tit7Cont2: "<p>Sous réserve des dispositions réglementaires, les dépôts des clients de détail sont déposés séparément dans un compte en fiducie, au besoin. Si CXMDirects fait faillite, se voit retirer sa licence ou est incapable de poursuivre ses activités, les fonds des clients peuvent toujours être mis en quarantaine et soumis à la surveillance prévue par les dispositions sur les fonds des clients dans les lois réglementaires.</p>",
                tit7Tit3: "CXMDirects respecte - t - elle les dispositions légales?",
                tit7Cont3: "<p>Notre société se conforme pleinement aux lois et règlements applicables en matière d'entreprise et de réglementation financière et est un fournisseur de services financiers autorisé et strictement réglementé par les autorités de réglementation compétentes. Notre société fournit une protection complète et adéquate pour la sécurité financière de nos clients conformément aux exigences réglementaires strictes.</p>",
                tit7Tit4: "Les informations personnelles du client sont - elles sécurisées?",
                tit7Cont4: "<p>CXMDirects respecte strictement les dispositions du Règlement sur la protection des données personnelles et les données personnelles des clients ne seront jamais divulguées. La société veillera à ce que ses employés respectent les normes strictes de sécurité et de confidentialité établies par les lois et règlements, en accordant une grande importance à la confidentialité et aux modalités d'utilisation des données des clients. Voir le document d'information juridique de notre société pour plus de détails.</p>",
            },
            tab4Row: {
                span: "Q méthode de vérification",
                p1: "CXMDirects est la réponse pour ceux qui recherchent une plate - forme de dérivés de crypto - monnaie de qualité professionnelle. Notre objectif est de créer un marché efficace et équitable qui relie les Traders de différents horizons et styles de Trading. Une capacité de moteur adaptée élevée, une faible latence, une gestion avancée des risques et une liquidité élevée font de CXMDirects un acteur unique sur le marché.",
                p2: "L’objectif initial de CXMDirects était de créer le premier échange d’options de crypto - monnaie au monde. Bien qu'il s'agisse d'une tâche extrêmement difficile, l'équipe a travaillé sur le développement du système en plus de deux ans. En juin 2016, CXMDirects est officiellement en ligne",
                p3: "CXMDirects est un échange de futures et d'options de crypto - monnaie basé à Panama City, Panama. CXMDirects a commencé comme une plate - forme de Trading BTC entièrement dédiée, mais depuis lors, des contrats ETH ont été ajoutés et d’autres contrats monétaires arriveront.",
                p4: "Actuellement, nos clients peuvent négocier des contrats permanents, des contrats à terme et des contrats d'options. CXMDirects a connu une croissance rapide et est l'un des principaux échanges offrant des contrats à terme sur crypto - monnaie et des contrats permanents. En outre, CXMDirects reste le principal échange offrant des options de crypto - monnaie de règlement en espèces de style européen et continue d'établir la norme pour le secteur.",
                p5: "CXMDirects Holding est autorisée et réglementée par la Cayman Islands Financial Authority (CIMA) sous le numéro de licence SIB 1612446. Pour plus de détails sur les plaques d'immatriculation Caïmans, visitez le site officiel de la CIMA à l'adresse www.cima.ky（",
                p6: ") Faites une enquête. CXMDirectsglobal détient une licence de services financiers (afsl 398528) autorisée par la Commission australienne des valeurs mobilières et des investissements (ASIC). Pour plus de détails sur les plaques d'immatriculation australiennes, visitez le site officiel de l'ASIC à l'adresse www.asic.gov.au (",
                p7: ") Faites une enquête. CXMDirects international est autorisée et réglementée par la Mauritius Financial Services Commission (FSC) sous le numéro de licence gb20025791. Pour plus d’informations sur les plaques d’immatriculation mauriciennes, visitez le site officiel du FSC à l’adresse www.fscmauricius.org(",
                p8: ")Faites une enquête. Toutes les opérations commerciales de CXMDirects sont menées sous surveillance stricte et dans le respect de toutes les réglementations",
                p9: "Contexte",
                p10: "CXMDirects est une équipe de haut niveau avec une expérience et des connaissances approfondies dans le secteur des transactions financières et des technologies financières, établie à Melbourne, en Australie.",
                p11: "La philosophie de CXMDirects est de rendre le trading plus facile et plus convivial. Notre stratégie globale et notre modèle d'affaires nous permettent d'acquérir une compréhension complète et approfondie des développements de l'industrie et des besoins du marché, ce qui nous permet d'allouer des ressources de manière plus ciblée, d'innover continuellement en matière de technologie, d'optimiser l'efficacité et d'offrir constamment à nos clients une expérience de Trading plus pratique et conviviale.",
                p12: "Pourquoi choisir",
                p13: "Conditions de Trading à seuil bas",
                p14: "Plateforme de Trading simple et intuitive",
                p15: "Coûts de transaction compétitifs",
                p16: "Supervision des autorités industrielles",
                p17: "Support en ligne avancé",
                p18: "Protection contre l'équilibre négatif",
                p19: "Prix et distinctions",
                p20: "CXMDirects est fier d’avoir remporté plusieurs prix, dont celui du meilleur courtier Multi - actifs aux World Finance Awards, de la meilleure plateforme de Trading mobile en Asie - Pacifique au Global Brand magazine et du courtier fintech à la croissance la plus rapide au monde, qui confirment tous les efforts et le dévouement continus de l’équipe.",
                p21: "Meilleur courtier Multi - actifs",
                p22: "Notre philosophie d'entreprise",
                p23: "Nos valeurs",
                p24: "Nous sommes l'une des plateformes de Trading de crypto - monnaie à la croissance la plus rapide au monde et nous croyons que nos valeurs fondamentales sont la force motrice de ce succès.",
                p25: "Honnêteté et intégrité",
                p26: "La confiance des utilisateurs est essentielle; Par conséquent, nous opérons avec les normes d'intégrité les plus élevées en fournissant un système de gestion des risques équitable, un traitement égal de tous les acteurs du marché et des solutions de sécurité avancées.",
                p27: "Qualité du Service",
                p28: "Le Trading de crypto - monnaie est un processus sans frontières, 24 / 7, dans lequel la qualité du service n'est pas compromise. Le client est notre valeur la plus importante; Notre objectif est donc de fournir une expérience de Trading fluide et ininterrompue. Nous visons à atteindre cet objectif en étendant et en améliorant continuellement l'architecture de nos systèmes, en minimisant les temps de maintenance et en fournissant un service client rapide et multilingue.",
                p29: "Création",
                p30: "Dans un environnement de crypto - monnaie au rythme rapide, l'innovation est nécessaire. CXMDirects est une société axée sur les solutions conçue pour offrir la meilleure expérience de Trading à des millions d'utilisateurs, enracinée dans notre architecture de système évolutive et performante.",
                p31: "Pourquoi choisir des dérivés？",
                p32: "Il y a de nombreux avantages à négocier des dérivés – des coûts de transaction plus bas, un effet de levier plus élevé, une liquidité plus élevée et une vente à découvert plus facile",
                p33: "Le Trading de dérivés permet également des stratégies de Trading plus variées. Les traders peuvent utiliser des dérivés pour couvrir le risque, arbitrer ou spéculer",
                p34: "Science et technologie",
                p35: "Pour assurer un système performant, il a été développé pendant plus de deux ans avant sa mise en ligne.",
                p36: "Le cadre développé pour cette plate - forme a été conçu pour garantir la possibilité de traiter un grand nombre de demandes avec une faible latence. Nous avons développé un moteur de Matching unique spécialement pour CXMDirects et toutes nos technologies sont propriétaires",
                p37: "Depuis son lancement, notre système de compensation incrémentielle et de gestion des risques garantit zéro perte socialisée",
                p38: "Chez CXMDirects, nous croyons en l’avenir des crypto - monnaies. Notre objectif est de rester à l'avant - garde du marché des dérivés de crypto - monnaie. Nous envisageons qu’à l’avenir, les crypto - monnaies seront utilisées par tout le monde et échangées par des millions de personnes. Nous sommes prêts pour l'avenir, tout comme nos systèmes",
            },
        },
        language: {
            tit: "Choisir une langue",
        },
        market: {
            tit: "Paramètres du marché",
            tit1: "Affichage des prix",
            tit2: "Couleurs ondulantes",
            buy_price: "Taux d'achat",
            buy_price_tip: "Dessiner une ligne K au prix d'achat",
            sell_price: "Taux de vente",
            sell_price_tip: "Dessiner K - line au prix de vente",
            color1: "Vert Rouge",
            color2: "Rouge et vert",
        },
        notice: {
            tit: "Paramètres de notification",
            tit1: "Ouverture des canaux de notification",
            p1: "Veuillez ouvrir / vérifier les canaux de notification suivants, les informations importantes ne seront pas manquées!",
            message: "Envoyer un SMS",
            push: "Pousser",
            verify: "Tests et validation",
            verified: "Confirmé",
            email: "E-mail",
            type1: "MARKETING",
            tit2: "Notification des activités de marketing",
            desc2: "Informations promotionnelles, activités opérationnelles et autres informations sur la plateforme",
            tit3: "Instructions de fonctionnement",
            desc3: "Matériel de connaissance riche et professionnel",
            tit4: "Rappel d'information",
            desc4: "Données financières récentes et informations de presse",
            tit5: "Rappel intelligent des fluctuations",
            desc5: "Comprend les fluctuations de prix des variétés importantes pour vous aider à saisir rapidement les opportunités",
            type2: "Catégorie de rapport",
            tit6: "Rapport de compte",
            desc6: "Les rapports de votre compte seront envoyés mensuellement par:",
            cycle: "Le cycle",
            month: "Une fois par mois",
        },
        protocol: {
            tit: "Déclaration et accord",
            label1: "Politique de confidentialité",
            label2: "Déclaration de divulgation du produit",
            label3: "Accord client",
            label4: "Déclaration de divulgation des risques",
        },
        setting: {
            tit: "Paramètres",
            label1: "Paramètres de sécurité",
            label2: "Paramètres du marché",
            label3: "Paramètres de transaction",
            label4: "Langue",
            label5: "Mode thème",
            label6: "Paramètres de notification",
            label7: "Guide de Trading",
            label8: "Déclaration et accord",
            label9: "Nettoyer le cache",
            label10: "Versions",
            logout: "Se déconnecter du login",
        },
        theme: {
            tit: "Mode thème",
            theme1: "Lumières",
            theme2: "L'obscurité",
        },
        trade: {
            tit: "Paramètres de transaction",
            onclick_close: "Position de fermeture en un clic",
        }
    },
    trade: {
        fliter_type1: "Facultatif",
        fliter_type2: "Le marché",
        place_search: "Entrez le nom / Code de la variété rechercher",
        fliter_type3: "Populaire",
        fliter_type4: "Les étrangers ",
        fliter_type5: "Marchandises",
        fliter_type6: "Partager ",
        fliter_type7: "Index",
        fliter_type8: "Crypto - monnaie",
        tip1: "Complétez l'authentification pour déposer et commencer à trader",
        sell: "Vendu",
        buy: "Acheter",
        add_optional: "Ajouter une auto - Sélection",
        optional_recommend: "Auto - sélectionné recommandé",
        change_batch: "Modifier un lot",
        edit_tit: "Modifier le contenu sélectionné",
        breed: "Variétés",
        topping: "élevé",
        sort: "Trier",
        all: "Tout sélectionner",
    },
    table: {
        label_breed: "Variétés",
        label_direction: "Direction",
        label_price: "Prix",
        label_operate: "Opérations",
        empty_tip1: "Aucun rappel pour le moment",
        empty_tip2: "Aucun enregistrement de dépôt",
        empty_tip3: "Aucun enregistrement de dépenses en espèces",
        label_amount: "Quantité",
        label_type: "Type ",
        label_time: "Le temps",
        label_name: "Nom et prénom",
        label_email: "Email",
        label_address: "Pays",
        label_account: "Numéro de compte de Trading",
        label_currency: "Monnaie de base",
        label_company: "Entités de service actuelles",
    },
    form: {
        to: "À",
        start_time: "Heure de début",
        end_time: "Heure de fin",
        week: "La semaine dernière",
        month: "Le mois dernier",
        threeMonth: "Les trois derniers mois",
        sixMonth: "Les six derniers mois",
        label_feedback: "Questions et suggestions",
        place_feedback: "Veuillez saisir votre question ou suggestion",
        label_img: "Photos",
        label_img_tip: "Facultatif, fournir une capture d'écran du problème",
        feedback_tip: "Si vous avez des questions urgentes, veuillez contacter",
        online: "Service à la clientèle en ligne",
        label_cardNumber: "Numéro de carte d'identité",
        label_realName: "Nom",
        place_realName: "Veuillez entrer votre prénom",
        message_realName: "Veuillez entrer votre prénom",
        label_firstName: "Nom de famille",
        place_firstName: "Veuillez entrer votre nom de famille",
        message_firstName: "Veuillez entrer votre nom de famille",
        label_lastName: "Prénom moyen",
        place_lastName: "Facultatif",
        message_lastName: "Veuillez entrer un nom",
        label_birthday: "Date de naissance:",
        place_birthday: "DD/MM/YYYY",
        message_birthday: "Veuillez entrer votre date de naissance",
        label_idType: "Type de vérification",
        tip_cardMain: "Prenez ou téléchargez une photo du recto de votre carte d'identité",
        tip_cardBack: "Prendre ou télécharger une photo au dos de votre carte d'identité",
        tip_cardMain1: "Prendre ou télécharger une photo du recto de votre permis de conduire",
        tip_cardBack1: "Prendre ou télécharger une photo au dos de votre permis de conduire",
        tip_cardMain2: "Prendre ou télécharger une photo de passeport",
        tip_cardBack2: "Prendre ou télécharger une photo de passeport",
        label_password: "Mot de passe",
        place_password: "Veuillez entrer votre mot de passe",
        message_password: "Veuillez entrer votre mot de passe",
        label_crmpsd: "Confirmer le mot de passe",
        place_crmpsd: "Veuillez saisir à nouveau votre mot de passe",
        message_crmpsd: "Veuillez saisir à nouveau votre mot de passe",
        label_email: "E-mail",
        place_email: "Adresse e - mail",
        message_email: "Veuillez entrer votre adresse e - mail",
        label_captcha: " Code de vérification",
        place_captcha: "Veuillez entrer le Code de vérification",
        message_captcha: "Veuillez entrer le Code de vérification",
        get_captcha: "Code de vérification",
        label_phone: "Téléphone",
        place_phone: "Veuillez entrer un numéro de téléphone",
        message_phone: "Veuillez entrer un numéro de téléphone",
        labelAmount: "Quantité",
        placeAmount: "Veuillez entrer le montant",
        messageAmount: "Veuillez entrer le montant",
        labelWalletName: "Nom du portefeuille",
        messageWalletName: "Veuillez entrer un nom de portefeuille",
        placeWalletName: "Veuillez entrer un nom de portefeuille",
        labelWalletAddress: "Adresse du portefeuille",
        messageWalletAddress: "Veuillez entrer l'adresse du portefeuille",
        placeWalletAddress: "Veuillez entrer l'adresse du portefeuille",
        labelWithdrawAccount: "Compte de retrait",
        placeWithdrawAccount: "Compte de retrait",
        messageWithdrawAccount: "Compte de retrait",
        placeTimeLimit: "Veuillez entrer une limite de temps",
        messageTimeLimit: "Veuillez entrer une limite de temps",
    },
    city: {
        albania: "Albanie",
        algeria: "Algérie",
        argentina: "Argentine",
        armenia: "Arménie",
        australia: "Australie",
        pakistan: "Pakistan",
        austria: "Autriche",
        bahrain: "Bahreïn",
        belgium: "Belgique",
        bosnia_and_Herzegovina: "Bosnie - Herzégovine",
        brazil: "Brésil",
        brunei: "Brunei",
        bulgaria: "Bulgarie",
        cambodia: "Cambodge",
        canada: "Canada",
        cameroon: "Cameroun",
        chile: "Chile",
        colombia: "Colombie",
        costa_Rica: "Costa Rica",
        croatia: "Croatie",
        cyprus: "Cyprus",
        czech_Republic: "République tchèque",
        denmark: "Danemark",
        dominican_Republic: "République dominicaine",
        egypt: "Égypte",
        estonia: "Estonie",
        ethiopia: "Éthiopie",
        finland: "Finlande",
        france: "France",
        georgia: "Géorgie",
        germany: "Allemagne",
        ghana: "Ghana",
        greece: "Grèce",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hongrie",
        iceland: "Islande",
        ireland: "Irlande",
        italy: "Italie",
        india: "India",
        indonesia: "Indonésie",
        israel: "Israël",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japon",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Corée du Sud",
        kuwait: "Koweït",
        kyrgyzstan: "Kirghizistan",
        laos: "Laos",
        latvia: "Lettonie",
        lithuania: "Lituanie",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macédoine",
        malaysia: "Malaisie",
        malta: "Malta",
        mexico: "Mexique",
        moldova: "Moldavie",
        monaco: "Monaco",
        mongolia: "Mongolie",
        montenegro: "Monténégro",
        morocco: "Maroc",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " Nouvelle - Zélande",
        nepal: "Népal",
        nigeria: "Nigéria",
        norway: "Norvège",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Pérou",
        philippines: "Philippines",
        poland: "Pologne",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Roumanie",
        russia: "Russie",
        republic_of_Trinidad_and_Tobago: "République de Trinité - et - Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Arabie saoudite",
        serbia: "Serbie",
        singapore: "Singapore",
        slovakia: "Slovaquie",
        slovenia: "Slovénie",
        south_Africa: "Afrique du Sud",
        spain: "Espagne",
        sri_Lanka: "Sri Lanka",
        sweden: "Suède",
        switzerland: "Suisse",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tadjikistan",
        tanzania: "Tanzania",
        thailand: "Thaïlande",
        turkey: "Dinde",
        turkmenistan: "Turkménistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Émirats arabes unis",
        united_Kingdom: "Royaume - Uni de Grande - Bretagne",
        united_States: " États - Unis",
        uzbekistan: "Ouzbékistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaïdjan",
        bangladesh: "Bangladesh",
        belarus: "Biélorussie",
        belize: "Belize",
        benin: "Bénin",
        bhutan: "Bhoutan",
        bolivia: "Bolivie",
        botswana: "Botswana",
        british_Virgin_Islands: "Îles Vierges britanniques",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Îles Caïmans",
        central_African_Republic: "République centrafricaine",
        chad: "Tchad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " La République du Congo",
        democratic_Republic_of_the_Congo: "Congo démocratique",
        djibouti: "Djibouti",
        ecuador: "Équateur",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinée équatoriale",
        eritrea: "Érythrée",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambie",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinée",
        haiti: "Haïti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaïque",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Libéria",
        libya: "Libye",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibie",
        nicaragua: "Nicaragua",
        republic_of_Niger: "République du Niger",
        north_Korea: " Corée du Nord",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Sénégal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalie",
        sudan: "Soudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syrie",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisie",
        united_States_Virgin_Islands: "Îles Vierges américaines",
        uganda: "Ouganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yémen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambie",
        zimbabwe: "Zimbabwe",
        china: "China",
    }
};
