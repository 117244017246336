export default {
    common: {
        expected: "Kembalian yang diharapkan",
        view: "cek",
        confirm: "konfirmasi",
        cancel: "batalkan",
        deposit: "Deposit tunai",
        withdraw: "Uang keluar",
        noData: "Tidak ada data yang tersedia",
        submit: "Kirim",
        modify: "ubah",
        hand: "tangan",
        confirm_verify: "Konfirmasi Validasi",
        next: "Langkah berikutnya",
        complete: "selesai",
        delete: "hapus",
		countryCode: "Kode negara",
        no_balance: "Tidak cukup bilangan rekening",
    },
    currency: {
		btcusdt: "Bitcoin",
		ethusdt: "Ethereum",
		ltcusdt: "Litecoin",
		xrpusdt: "Riak (mata uang)",
		adausdt: "Mata uang Ada (digunakan dalam pecahan mata uang)",
		eosusdt: "Koin Pomelo",
		dotusdt: "Koin Boca",
		trxusdt: "Koin medan gelombang",
		xmrusdt: "Koin Monroe (mis. Dolar AS)",
		dogeusdt: "Koin doggie",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "USD AUD",
		fx_sgbpusd: "GBP USD",
		fx_seurusd: "Euro USD",
		fx_snzdusd: "Dolar Selandia Baru (NZD) Dolar Amerika Serikat",
		fx_susdcad: "CAD USD",
		fx_susdchf: "USD CHF",
		fx_susdjpy: "USD JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD CHF",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "Dolar Australia Dolar Selandia Baru (NZD)",
		fx_scadchf: "Dolar Kanada franc Swiss",
		fx_scadjpy: "Dolar Kanada Yen Jepang",
		fx_schfjpy: "Yen franc Swiss",
		fx_seuraud: "Euro Dolar Australia",
		fx_seurcad: "Euro Dolar Kanada",
		fx_seurgbp: "Euro Poundsterling",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro Dolar Selandia Baru (NZD)",
		fx_sgbpaud: "Poundsterling Inggris Dolar Australia",
		fx_sgbpchf: "Poundsterling Franc Swiss",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "Pound Dolar Selandia Baru (NZD)",
		fx_scadnzd: "Dolar Kanada Dolar Selandia Baru",
		hf_CL: "Minyak Mentah WTI New York",
		hf_OIL: "Minyak mentah Brent",
		hf_GC: "Emas New York",
		hf_SI: "New York Silver",
		hf_HG: "Tembaga (kata pinjaman)",
		hf_NG: "Gas Alam Amerika Serikat",
		hf_CAD: "Tembaga (kata pinjaman)",
		hf_AHD: "Aluminium London",
		HX_AAPL: "Delima",
		HX_BABA: "Ali Baba, karakter dari The Arabian Nights",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (pengecer)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), penemu dan insinyur asal Serbia",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Jerman",
		HX_IBEX: "IBEX35, Spanyol",
		HX_SPX: "Indeks S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Korea",
		HX_NZ50: "Selandia Baru 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "London Zinc",
		hf_NID: "London Nikel",
		hf_PBD: "London memimpin",
		hf_SND: "Timah London",
		hf_XAU: "Emas London (Emas Spot)",
		hf_XAG: "London Silver (Spot Silver)",
		hf_XPT: "Platinum berjangka",
		hf_XPD: "Palladium Berjangka",
		hf_CT: "Kapas AS",
		hf_SM: "Bubuk Kacang Kedelai",
		hf_BO: "Minyak kedelai AS",
		HX_SENSEX: "SENSEX, Mumbai, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "CAC40, Prancis",
		HX_SX5E: "Snooker Eropa 50",
		HX_ICEXI: "ICEX, Islandia",
		HX_ASE: "ASE Athena, Yunani",
		HX_OMXC20: "OMX Copenhagen 20",
		HX_OSEBX: "OSEBX, Norwegia",
		HX_OMXSPI: "OMXSPI, Swedia",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney (nama)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (merek)",
		HX_NVDA: "NVIDIA, perusahaan kartu grafis komputer",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Teknologi Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
    currenctType: {
        title: "Silakan pilih uang",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dolar',
        currenctType15: 'Dolar Hong Kong',
        currenctType16: 'Dollar Taiwan',
        currenctType17: 'Uang Malaysia',
        currenctType18: 'Uang baru',
        currenctType19: 'Yen Jepang',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Indonesia Rupiah',
        currenctType23: 'GBP',
        currenctType24: 'Baht Thailand',
        currenctType25: 'Cad',
        currenctType26: 'Dong Vietnam',
        currenctType27: 'Korea menang',
        currenctType28: 'Macau Pataca',
    },
    addnew3: {
        sell_price: "Harga penjualan",
        number: "Jumlah",
        type: "Pengiriman",
        buy_price: "Harga membeli",
        profit: "P/L",
        sell_time: "Waktu menjual",
    },
    addnew2: {
        address: {
            second_name: "Katakana",
            account_type: "Jenis Akaun",
            ifsc_code: "Kode IFSC",
            title: "Alamat penarikan",
            account_number: "Nomor Akaun",
            add_btn: "Tambah Alamat",
            label_type: "Pilih Jenis",
            place_type: "Silakan pilih tipe",
            label_number: "Nomor Akaun",
            place_number: "Silakan masukkan nomor rekening",
            wallet_address: 'Alamat dompet',
            bank_name: 'Nama Bank',
            bank_address: "Alamat bank",
            name: "Nama",
            payee_address: 'Alamat Penerima',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nama Branch",
            branch_code: "Kode Branch",
            bank_code: "Kode bank",
            phone: "Nomor ponsel",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Silakan masukkan isi",
        },
    },
    addnew: {
        realFirst: "Silakan lakukan autentikasi identitas pertama",
        payFirst: "Silakan tetapkan sandi transaksi pertama",
        password1: "Ubah kata sandi daftar masuk",
        password2: "Ubah sandi penarikan",
        paypassTit1: "Silakan tetapkan sandi penarikan Anda",
        oldPass: "Kata sandi lama",
        allView: "Semua dibaca",
        confirmOrder: "Konfirmasi Perintah",
        name: "nama",
        fee: "Gaji pengurusan",
        orderCycle: "Gaji pengurusan",
        loan: "pinjaman",
        loanTit: "Pinjaman bantuan",
        loanHostory: "Catatan pinjaman",
        labelProductTitle: "Nama Produk",
        immediateRepayment: "Pembayaran segera",
        loadAmount: "Jumlah pinjaman yang dijangka",
        RepaymentCycle: "Siklus pembayaran pinjaman",
        day: "hari",
        dailyRate: "Rata bunga sehari",
        repaymentMethod: "Metode pembayaran",
        lendingCompany: "Perusahaan Pinjaman",
        loanTip: "Pinjaman kredit (pastikan gambar jelas dan terlihat)",
        loanTip1: "Memuat naik sertifikat properti",
        loanTip2: "Bukti pendapatan (hubungan pekerjaan)",
        loanTip3: "Rincian pernyataan bank",
        loanTip4: "Naikkan foto dari depan kartu ID Anda",
        interest: "bunga",
        repaid: "Balas",
        unpaid: "Tidak dibayar",
        tab1: "Coins",
        tab2: "kontrak",
        tab3: "pilihan",
        available: "Kredit tersedia",
        pending: "mengunci",
        equivalent: "Sama",
        fundTransfer: "Transfer dana",
        flashExchange: "Penukaran Flash",
        select_breed: "Pilih Uang",
        from: "dari",
        to: "ke",
        transferQuantity: "Beralih jumlah",
        repaymentMethod1:"Satu pembayaran setelah dewasa"
	},
    params: {
        product_foreign: "Forex",
        product_shop: "Komoditas",
        product_number: "Indeks",
        product_stock: "Saham",
        product_coin: "Coin",
        billTypes: [
            { v: 101, name: 'Isi ulang' },
            { v: 102, name: 'Tambah' },
            { v: 103, name: 'freeze' },
            { v: 104, name: 'meluap' },
            { v: 105, name: 'Deposit' },
            { v: 106, name: 'Deposit' },
            { v: 201, name: 'Pembeku tarik' },
            { v: 202, name: 'deduksi' },
            { v: 203, name: 'Pengunduran berhasil' },
            { v: 204, name: 'Pengunduran gagal' },
            { v: 205, name: 'Gaji penarikan' },
            {v: 206, name: 'Transfer keluar'},
            {v: 207, name: 'Transfer masuk'},
            {v: 208, name: 'Transfer uang keluar'},
            {v: 209, name: 'Transfer uang masuk'},
            { v: 301, name: 'Deduksi deposit' },
            { v: 302, name: 'Pengurangan biaya pengurusan' },
            { v: 303, name: 'Kembalian deposit' },
            { v: 304, name: 'keuntungan' },
            { v: 305, name: 'kehilangan' },
            {v: 311, name: 'Opsi Pembelian'},
            {v: 312, name: 'Opsi mengembalikan'},
            {v: 313, name: 'Pembalikan Opsi'},
            {v: 314, name: 'Gaji pengurusan opsi'},
            {v: 315, name: 'Pembelian uang membeku'},
            {v: 316, name: 'Deduksi pembelian uang'},
            {v: 317, name: 'Kembali Pembelian Coin'},
            {v: 318, name: 'Pembelian Coin Diterima'},
            {v: 319, name: 'Pembelian Coin Diterima'},
            {v: 320, name: 'Penjualan uang beku'},
            {v: 321, name: 'Pengurangan penjualan uang'},
            {v: 322, name: 'Penjualan koin kembali'},
            {v: 323, name: 'Coins dijual untuk akun'},
            {v: 324, name: 'Coins dijual untuk akun'},
            {v: 325, name: 'Gaji pengendalian uang'},
            {v: 401, name: 'Tambahan mesin penambangan'},
            {v: 402, name: 'Kembali mesin pengembara'},
            {v: 403, name: 'Penghasilan mesin penambangan'},
            {v: 404, name: 'Keluar mesin pengembara'},
            {v: 405, name: 'Gaji pengurusan keluar mesin penambangan'},
            {v: 411, name: 'Pinjaman pendapatan'},
            {v: 412, name: 'Gaji pengurusan pinjaman'},
            {v: 413, name: 'Pembayaran berhasil'},
        ],
    },
    lockming: {
        profit: "Baru-baru ini (hasil harian)",
        cycle: "Siklus keuangan",
        limit: "Batas transaksi tunggal",
        dividend_time: "Waktu pembayaran dividend",
        every_day: "setiap hari",
        funds: "Dana penjagaan",
        back: "Kembali kehausan",
        ransom: "dipanggil pergi",
        label_amount: "Jumlah janji",
        place_amount: "Silakan masukkan...",
        available: "tersedia",
        expect_income: "Daftar yang diharapkan",
        title: "Menombongan terkunci",
        tip: "Menyembah untuk keuntungan tanpa akhir",
        label_today_income: "Diduga Kembalian Hari ini",
        label_total_income: "Daftar terkumpul",
        label_order: "Perintah hos",
        label_number: "Jumlah transaksi tunggal",
        daily_return: "Rata kembalian sehari",
        subscribe: "Subskripsi",
        position: "Posisi",
        label_state: "keadaan",
        subscribe_time: "Waktu subskripsi",
        expiration_time: "Waktu habis",
        income: "pendapatan",
        errorTip1: "Silakan masukkan jumlah",
        tip_tit: "Apakah Anda yakin Anda ingin keluar lebih awal",
    },
    footer: {
        nav1: "halaman rumah",
        nav2: "transaksi",
        nav3: "Bin",
        nav4: "Aku",
        kline_set1: "Pengaturan pasar",
        kline_set2: "Pengaturan Transaksi",
    },
    login: {
        login: "Login",
        register: "Register",
        type1: "Nomor ponsel",
        type2: "Kotak surat",
        login_btn: "Daftar masuk aman",
        register_btn: "Daftar Sekarang",
        psd_login: "Login kata sandi",
        verify_login: "Login kode verifikasi",
        forget: "Lupakan kata sandi??",
        no_account: "Tidak ada rekening？",
        have_account: "Akaun yang ada",
    },
    home: {
        home: {
            nav1: "Tingkat naik dan turun",
            nav2: "Cepat mulai",
            nav3: "Tentang kita",
            nav4: "Hadiah undangan",
            most_follow: "Kebanyakan diikuti",
            news_type1: "strategi",
            news_type2: "berita",
            news_type3: "Kalendar Ekonomi",
            news_tab1: "Semua ",
            news_tab2: "pertukaran asing",
            news_tab3: "saham",
            news_tab4: "komoditas",
            news_tab5: "indeks",
            news_tab6: "data",
            news_tab7: "Event ",
            news_tab8: "liburan",
            label_previous: "Nilai sebelumnya",
            label_predictive: "Perkiraan",
            label_announced: "Nilai yang diumumkan",
        },
        kilne: {
            label_bp: "Diterima kemarin",
            label_sp: "Buka hari ini",
            label_height: "tertinggi",
            label_low: "minimum",
            tip: "Hai, aku asisten CXMDirectsGPT",
            row1_tit: "strategi",
            row1_rinei: "Dalam siang hari",
            row1_short: "jangka pendek",
            row1_middle: "pertengahan jangka",
            row1_time: "Waktu pembebasan",
            row1_tit2: "Strategi perdagangan",
            data_sources: "sumber data",
            row2_tit1: "data",
            row2_tit2: "Sentimen perdagangan",
            row2_label_sell: "penjual",
            row2_label_buy: "Pembeli",
            tip2: "Untuk referensi saja, bukan sebagai posisi perusahaan kita",
            tip3: "Kemaskini setiap 15 menit",
            row2_tit3: "Penukaran harga",
            minute: "menit",
            today: "hari ini",
            right_tit7: "Jarak variasi harga",
            now_price: "Harga saat ini",
            right_low_price: "Harga paling rendah",
            right_height_price: "Harga maksimum",
            details: "rincian",
            right_label1: "Jumlah transaksi tunggal",
            right_label2: "Maksimum leverage",
            right_label3: "Ukuran kontrak",
            right_label4: "Maksimum jumlah posisi total",
            right_label5: "Perbedaan harga yang mengambang",
            right_label6: "Hadiah sepanjang malam",
            hand: "tangan",
            right_sell: "Jual",
            right_buy: "Beli",
            right_collection_time: "Waktu koleksi",
            right_tip3: "Kumpulkan dari seluruh ukuran transaksi",
            right_label9: "Periode perdagangan",
            right_label10: "Periode saat ini",
            right_label11: "Period Berikutnya",
        },
        placeorder: {
            direction_buyPrice: "Harga membeli",
            direction_sellPrice: "Harga penjualan",
            direction_sell: "Jual",
            direction_buy: "Beli",
            market_price: "Daftar harga pasar",
            check_price: "Mendaftar",
            sell_tip: "Jual ketika harganya memenuhi syarat",
            buy_tip: "Beli ketika harganya memenuhi syarat",
            trade_number: "Kwantitas transaksi",
            hand: "tangan",
            select_lever: "Pilih lever",
            right_tip: "Deposit diperlukan",
            balance: "Kesimbangan yang tersedia",
            label_stop_win_price: "Hentikan surplus",
            label_stop_lose_price: "Hentikan kehilangan",
            expected_profit_loss: "Profit dan kehilangan yang diharapkan",
            trailing_stop: "Berhenti",
        },
        ranking: {
            tit: "Hari naik dan turun",
            p: "Tangkap titik panas dan mengambil kesempatan",
            renew: "Dikemaskini pada",
            growth_chart: "Graf pertumbuhan",
            drop_list: "Daftar Jatuh",
            show_closed_market: "Tampilkan Tanda Pasar",
            filter1: "seluruh",
            filter2: "pertukaran asing",
            filter3: "komoditas",
            filter4: "saham",
            filter5: "indeks",
            filter6: "enkripsi",
            td1: "peringkat",
            td2: "trend",
            td3: "Tingkat harian/harian penjualan",
        },
        remind: {
            now_sell_price: "Harga penjualan saat ini",
            direction_buyPrice: "Harga membeli",
            direction_sellPrice: "Harga penjualan",
            direction_sell: "Jual",
            direction_buy: "Beli",
            equal: "Ketika",
            p: "Ingatkan saya ketika harga berikutnya telah mencapai",
            btn1: "Tetapkan peringatan",
            list_tit: "Senarai Pengingatan",
        },
    },
    my: {
        report: {
            tit: "Laporan Akun",
            tab1: "laporan",
            tab2: "pernyataan harian",
            tab3: "pernyataan bulanan",
            history_tit: "Laporan Sejarah Transaksi",
            account_tit: "Laporan Akun",
            filter: "layar",
            tip1: "Silakan pilih jangkauan tanggal yang sesuai untuk mengeksport laporan sejarah transaksi Anda。",
            tip2: "Waktu tersendiri",
            email_send: "Kirim ke email",
        },
        accountMsg: {
            tit: "Informasi Akaun",
            label1: "informasi pribadi",
        },
        bill: {
            tit: "Rincian aliran kapital",
            all: "seluruh",
            time: "waktu",
        },
        feedback: {
            tit: "Rekomendasi fungsional",
        },
        identity: {
            tit: "Otentikasi",
            type1: "Kartu ID",
            type2: "Lesen pengemudi",
            type3: "pasport",
            tip: "Saya mengkonfirmasi bahwa semua informasi dan dokumen yang diberikan adalah lengkap, benar, dan akurat",
        },
        my: {
            is: "sudah",
            no: "Tidak Diverifikasi",
            verify: "Diverifikasi",
            id_verify: "Otentikasi",
            verify_tip: "Selesaikan verifikasi identitas untuk depositasi transaksi",
            no_verify: "Verify Now",
            balance: "Kesimbangan yang tersedia",
            profit_loss: "Profit dan kehilangan",
            marginLevel: "Tingkat Margin",
            btn1: "Beralih ke akun nyata",
            deposit_withdraw_record: "Catatan deposit dan penarikan",
            capital_flow_details: "Rincian aliran kapital",
            welfare_center: "Pusat Keselamatan",
            tip1: "Anda memiliki",
            tip2: "Hadiah Pen yang akan diklaim",
            feedback: "Rekomendasi fungsional",
            report: "Laporan Akun",
            setting: "mengatur",
        },
        news: {
            tit: "notis",
        },
        record: {
            tit: "Catatan deposit dan penarikan",
            cycle: "siklus",
        },
        share: {
            tit: "Invite friends",
            invite_success: "Teman yang diundang dengan sukses",
            tip1: "Hadiah tunai",
            tip2: "Invite friends to take the highest",
            tip3: "Suruh teman untuk mengumpulkan uang tunai",
        },
        welfare: {
            tit: "Pusat Keselamatan",
            tab1: "Rol kartu",
            tab2: "aktivitas",
            history_record: "Sejarah",
            period_validity: "Period kehendak",
        },
        wallet: {
            tit: "dompet",
            addNew: "Tambah dompet baru",
        },
        withdraw: {
            tit: "Uang keluar",
            addNew: "Tambah dompet baru",
        },
    },
    order: {
        demo_account: "Akaun Simulasi",
        real_account: "Akun nyata",
        tip: "Setelah dibuka, emas dapat dideposisi untuk transaksi",
        tab1: "Posisi memegang",
        tab2: "Mendaftar",
        tab3: "sejarah",
        list_empty: "Tidak ada perintah yang menunggu",
        start_trade: "Mulai perdagangan",
        hand_tip: "Jarak masukan",
        kline: {
            direction_sell: "Jual",
            direction_buy: "Beli",
            open_price: "Harga pembukaan",
            now_price: "Harga saat ini",
            trade_account: "Akaun perdagangan",
            demo_account: "Akaun Simulasi",
            order_id: "ID Order",
            open_time: "Waktu Buka",
            stop_profit: "Hentikan surplus",
            stop_loss: "Hentikan kehilangan",
            trailing_stop: "Berhenti",
            float_profit_loss: "P/L mengapung",
            overnight_fee: "Hadiah sepanjang malam",
            margin: "Margin",
            maintainsMargin: "Jaga margin",
            open_value: "Nilai pembukaan",
            now_value: "Nilai saat ini",
            notset: "Tidak ditetapkan",
            close_position: "Posisi menutup",
            close_number: "Kuntitas normal",
            onclick_close: "Satu klik menutup posisi",
            btn: "Konfirmasi posisi penutup",
            balance: "Kesimbangan yang tersedia",
            profit_loss: "Profit dan kehilangan",
            netValue: "nilai neto",
            marginLevel: "Tingkat Margin",
        },
        modify: {
            tit: "Ubah Order Posisi",
            expected_profit_loss: "Profit dan kehilangan yang diharapkan",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Tetapkan kata sandi baru",
            verify_email_tit: "Periksa email",
            verify_password_tit: "Periksa sandi anda",
            verify_password_tip: "Masukkan kata sandi daftar masuk CXMDirects Anda untuk terus",
            verify_phone_tit: "Periksa nomor telepon",
            tit: "Keamanan rekening",
            tip: "Untuk melindungi keamanan rekening Anda dan menerima pemberitahuan layanan kami, disarankan untuk memungkinkan setidaknya satu jenis autentikasi.",
            label1: "Ubah kata sandi",
            label2: "Pembatalan rekening",
            label3: "Verifikasi Dua",
            label4: "manajemen perangkat",
            tip2: "Login perangkat baru membutuhkan verifikasi",
        },
        tradenews: {
            tit: "Panduan Transaksi",
            tab1: "cepat mulai",
            tab2: "Belajar maju",
            tab3: "Pusat Bantuan",
            tab4: "Tentang CXMDirects",
            viewMore: "Lihat lebih",
            details: {
                tit: "Perkenalan ke Exchange Luar Negeri",
                tip1: "Perhitungan durasi belajar",
                tip2: "menit",
            },
            tab1Row: {
                tit: " Ini adalah ruang kelas untuk cepat belajar untuk bertukar dengan CXMDirects",
                tit1: "Bagaimana perdagangan di CXMDirects?",
                tit2: "Bagaimana membuka rekening nyata?",
                tit3: "Bagaimana membuka posisi untuk perdagangan?",
                tit4: "Bagaimana menghentikan kehilangan dan menghentikan kehilangan?",
                tit5: "Bagaimana menutup posisinya?",
                p1: "Anda dapat menukar subjek dengan membelinya atau menjualnya. Membuka posisi di CXMDirects berarti bahwa Anda tertarik pada",
                p2: "Penjualan berdasarkan fluktuasi harga produk sasaran",
                p3: "Contohnya, jika Anda mengharapkan harga emas meningkat, membeli produknya; Sebaliknya, jika Anda memprediksi bahwa harga emas akan menurun, maka menjual produk. Jika masa depan memenuhi harapan, Anda dapat",
                p4: "Memberi keuntungan darinya",
                p5: "Selain itu, CXMDirects menyediakan 'perdagangan yang berguna'. Anda dapat menggunakan leverage untuk perdagangan lebih banyak sasaran dengan jumlah dana yang sama.",
                p6: "Peningkatan dapat meningkatkan keuntungan dan juga meningkatkan kehilangan",
                p7: "Ini juga dikenal sebagai 'perdagangan margin'. Ketika Anda membuka posisi, akun CXMDirects Anda akan mengurangi keseimbangan tertentu sebagai",
                p8: "Margin awal",
                p9: "Pilih produk dan arah membeli dan menjual",
                p10: "transaksi",
                p11: "Klik 'Buy/Sell' untuk menyelesaikan lokasi perintah",
                p12: "Menetapkan 'berhenti keuntungan' atau 'berhenti kehilangan' dapat membantumu",
                p13: "Waktunya mengunci keuntungan dan membatasi kehilangan",
                p14: "Anda dapat klik pada halaman pembukaan yang sukses",
                p15: "Hentikan keuntungan/hentikan kehilangan",
                p16: "Tetapkan, atau Anda dapat klik pada tombol 'Ubah' untuk urutan tidak seimbang untuk menghentikan keuntungan dan kehilangan",
                p17: "Tetapkan atau batalkan",
                p18: "Anda dapat melihat keuntungan dan kerugian dalam 'posisi tertutup'",
            },
            tab3Row: {
                h1: "Selamat datang di CXMDirects",
                h2: "Pusat Bantuan",
                tit0: "Semua masalah",
                tit1: "Akaun Simulasi",
                tit2: "Membuka akun",
                tit3: "Uang masuk dan keluar",
                tit4: "Pengaturan Platform",
                tit5: "Operasi Transaksi",
                tit6: "Gaji dan biaya",
                tit7: "Keamanan Keuangan dan Peraturan",
                tit1Tit1: "CXMDirects Apakah ada akun simulasi yang disediakan",
                tit1Cont1: "<p>CXMDirects Memberikan akun simulasi untuk pelanggan untuk beroperasi, memungkinkan Anda menggunakan platform online kami dan aplikasi ponsel untuk transaksi dan memperkenalkan diri dengan operasi platform tanpa risiko apapun</p>",
                tit1Tit2: "Bagaimana menciptakan akun simulasi?",
                tit1Cont2: "<p>Anda hanya perlu klik di sini untuk mengisi alamat email atau nomor telepon Anda, dan menetapkan kata sandi pribadi Anda untuk menyelesaikan registrasi dan mendapatkan akun simulasi.</p>",
                tit1Tit3: "Apa gunanya akun simulasi?",
                tit1Cont3: "<p>Akun simulasi dan akun nyata kebanyakan sama dalam terma antarmuka transaksi, data, dan operasi. Akun simulasi mengandung $ 50000 dalam dana virtual, dengan tujuan untuk memperkenalkan pelanggan dengan berbagai fungsi platform melalui operasi simulasi tanpa risiko keuangan apapun.</p>",
                tit1Tit4: "Bagaimana menyuntik dana ke akun simulasi?",
                tit1Cont4: "<p>Anda tidak perlu menyuntik dana ke rekening simulasi. Setelah Anda berhasil mendaftar rekening simulasi, akan ada jumlah 50000 USD atau AUD dalam rekening</p><p>Ketika nilai neto dalam akun simulasi adalah kurang dari $200 USD atau AUD, sistem akan secara otomatis mengisi kembali dana akun Anda ke $50000 USD atau AUD</p>",
                tit1Tit5: "Apakah akun simulasi memiliki periode penggunaan?",
                tit1Cont5: "<p>Period validitas akun simulasi adalah 90 hari. Jika Anda tidak membuka akun nyata setelah 90 hari, akun simulasi akan dibekukan dan tidak dapat ditukar, dan akan dibekukan ketika Anda membuka akun nyata.</p><p>Jika Anda membuka rekening nyata dalam waktu 90 hari, rekening simulasi akan valid untuk waktu yang lama. Bahkan setelah membuka rekening nyata, Anda dapat terus beroperasi pada rekening simulasi.</p>",
                tit2Tit1: "CXMDirects Berapa banyak jenis rekening tersedia?  ",
                tit2Cont1: "<p>Kami hanya menyediakan rekening perdagangan standar dan profesional untuk individu, dan tidak mendukung pembukaan perusahaan atau rekening kongsi</p>",
                tit2Tit2: "Uang apa yang bisa kubuka akun perdagangan?",
                tit2Cont3: "<p>Ketika Anda membuka rekening, sistem akan secara otomatis menentukan uang penyelesaian Anda berdasarkan wilayah Anda dan tidak akan dapat diubah</p>",
                tit2Tit4: "Bagaimana membuka akun nyata?",
                tit2Cont4: "<p>Silakan ikuti langkah di bawah untuk membuka akun nyata</p><p>1. Masukkan halaman pendaftaran CXMDirects dan ikuti permintaan untuk mendaftar nomor telepon e-mail, menetapkan sandi daftar masuk, dan secara otomatis menciptakannya setelah mengirimkan konfirmasi</p><p></p><p></p>",
                tit3Tit1: "Bagaimana aku bisa membuat deposit?",
                tit3Cont1: "<p>CXMDirects Menyediakan metode deposit yang berbeda untuk pelanggan untuk memilih dari, termasuk tetapi tidak terbatas kepada berikut:</p><p>1.Visa/Mastercard - Biasanya diterima segera</p><p>2.Pembayaran bank online biasanya dilakukan dalam waktu 1 hari kerja di depan</p><p>3.Dompet elektronik (Contohnya: Skrill, Momo, Zalo. Touch'n GO, Boost, dll.) -Biasanya diterima segera</p><p>4.Kartu ATM - biasanya menerima instant</p><p>5.QR mobil banking online - biasanya menerima instant</p>",
                tit3Tit2: "Dapatkah saya menggunakan akun bank/kartu di bawah nama pihak ketiga untuk mendepositasi atau menarik dana",
                tit3Cont2: "<p>CXMDirectsJangan menerima deposit dari pihak ketiga. Silakan gunakan akun/kartu bank pribadi Anda di bawah nama Anda sendiri untuk mendepositasi atau menarik dana. Jika Anda menggunakan nama orang lain atau rekening bank perusahaan/kartu untuk mendeposisi dana, dana akan dikembalikan</p>",
                tit3Tit3: "Bagaimana untuk meminta pengunduran dari rekening CXMDirects?   ",
                tit3Cont3: "<p>Setelah log masuk ke platform website, Anda dapat klik pada 'Cash Out', pilih rekening bank atau Visa/Mastercard, isi jumlah uang tunai keluar, dan kemudian tekan 'Kirim'. Setelah mengkonfirmasi bahwa informasi benar, CXMDirects akan memproses aplikasi Anda dalam 1-2 hari kerja</p>",
                tit4Tit1: "Berapa kali CXMDirects menawarkan perdagangan yang berguna?",
                tit4Cont1: "<p>Rasio leverage yang disediakan oleh platform CXMDirects ditetapkan menurut peraturan pihak berwenang, dan leverage maksimum untuk produk berbeda juga berbeda. Silakan log masuk ke platform perdagangan untuk rincian.</p>",
                tit4Tit2: "Bagaimana mengubah kata sandiku?",
                tit4Cont2: "<p>Setelah log masuk ke platform, Anda dapat memilih [Lebih] - [Pengaturan Keselamatan] - T Ubah Sandi] untuk reset sandi baru</p>",
                tit4Tit3: "Bagaimana untuk log masuk ke platform perdagangan CXMDirects?",
                tit4Cont3: "<p>App:Pergi ke 'Saya' dan klik pada 'Login Registration' di atas kiri untuk memasukkan halaman log.</p>Web: Klik pada 'Login' di sudut kanan atas situs resmi CXMDirects untuk memasukkan halaman log.<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Apa metode pengingatan platform?",
                tit4Cont4: "<p>CXMDirects Ingatkan melalui email, SMS, dan platform push.</p>",
                tit5Tit1: "CXMDirects Apa jenis perbedaan harga yang diberikan?",
                tit5Cont1: "<p>Kami menuntut perbedaan harga rendah dan tidak menuntut komisi apapun. Perbedaan harga mengambang, dan perbedaan harga yang sebenarnya ditanggung akan tergantung pada varietas yang Anda perdagangan, dan informasi khusus akan dipaparkan di platform perdagangan dalam waktu nyata.</p>",
                tit5Tit2: "Bagaimana membuat daftar harga pasar?",
                tit5Cont2: "<p>Klik pada 'Perdagangan' akan menampilkan semua produk yang platform dapat menawarkan untuk perdagangan. Anda dapat memasukkan kode variasi atau nama dalam kotak masukan di sebelah kanan untuk mencari, memilih produk, dan klik 'Buy/Sell' SEL/BUY' untuk muncul jendela perdagangan. Di jendela perdagangan, Anda dapat melihat harga saat ini dan margin yang diperlukan. Anda dapat secara manual menyesuaikan jumlah posisi terbuka dan mengatur menghentikan keuntungan dan menghentikan kehilangan untuk pengendalian risiko</p>",
                tit5Tit3: "Bagaimana untuk mendirikan formulir pendaftaran?",
                tit5Cont3: "<p>Memilih produk dalam [Perdagangan] dan mengklik pada [Menjual/BUI] akan muncul jendela perdagangan</p>",
                tit5Tit4: "Bagaimana menutup posisi?",
                tit5Cont4: "<p>Untuk menutup posisi, klik tombol 'Buka' pada platform di bagian 'Posisi', pilih posisi yang ingin ditutup, lalu klik tombol 'Tutup' di sisi kanan。</p>",
                tit5Tit5: "Bagaimana mengubah atau menghapus perintah yang menunggu?",
                tit5Cont5: "<p>Untuk mengubah atau menghapus perintah, klik 'Perintah' pada platform pemandangan di bagian 'Posisi', dan kemudian pilih perintah yang ingin Anda ubah atau menghapus dalam proses</p>",
                tit6Tit1: "Apakah akan ada biaya pengurusan yang ditanggung untuk menggunakan platform perdagangan?",
                tit6Cont1: "<p>CXMDirects Untuk sepenuhnya komisi platform perdagangan bebas. Kami menuntut perbedaan harga rendah, dan tergantung pada situasi transaksi spesifik Anda, platform mungkin mengalami biaya tambahan, seperti bunga overnight.</p>",
                tit6Tit2: "Apakah ada biaya untuk deposit?",
                tit6Cont2: "<p>Kami tidak akan meminta biaya deposit terkait kepada pelanggan, tapi bayaran atau bank intermedier Anda dapat meminta biaya pengendalian. Kami sarankan kau bertanya pada bank-bank relevan apakah mereka telah meminta bayaran apapun.</p>",
                tit6Tit3: "Apakah ada biaya untuk penarikan diri?",
                tit6Cont3: "<p>CXMDirects Kami tidak akan menuntut pelanggan biaya apapun yang berhubungan dengan pembayaran dana kecuali jumlah yang telah Anda kontribusikan adalah di bawah permintaan minimum atau melebihi jumlah maksimum pembayaran gratis untuk bulan. Untuk informasi terperinci, silakan merujuk kebijakan deposit kami.</p>",
                tit7Tit1: "Apakah dana yang saya depositasikan di akun CXMDirects saya digunakan untuk tujuan lain?",
                tit7Cont1: "<p>Tidak, tidak, tidak. Deposit pelanggan retail terpisah didepositasi di akun kepercayaan ketika diperlukan sesuai dengan peraturan peraturan. Ketika mendeposisi dana ke akun pelanggan atau menarik dana dari akun pelanggan atau membuat pembayaran, CXMDirects secara ketat mematuhi peraturan peraturan mengenai pembayaran pelanggan.</p>",
                tit7Tit2: "Jika CXMDirects bangkrut atau berhutang, akan danaku juga terkena kesan?",
                tit7Cont2: "<p>Deposit pelanggan retail terpisah didepositasi di akun kepercayaan ketika diperlukan sesuai dengan peraturan peraturan. Jika CXMDirects bangkrut, memiliki lisensinya dibatalkan, atau tidak dapat terus beroperasi, dana pelanggan masih bisa dikrantina dan didalam pengawasan peraturan dana pelanggan dalam undang-undang regulasi</p>",
                tit7Tit3: "Apakah CXMDirects mematuhi peraturan hukum?",
                tit7Cont3: "<p>Perusahaan kita sepenuhnya mematuhi undang-undang perusahaan yang relevan dan undang-undang regulasi keuangan, dan adalah penyedia layanan keuangan yang diberi otorisasi dan ketat diatur oleh agensi regulasi relevan. Perusahaan kami menyediakan perlindungan komprensif dan cukup untuk keamanan keuangan pelanggan sesuai dengan keperluan regulasi yang ketat.</p>",
                tit7Tit4: "Apakah informasi pribadi pelanggan aman?",
                tit7Cont4: "<p>CXMDirects Tetap mengikuti aturan perlindungan data pribadi, dan jangan pernah mengungkapkan data pribadi pelanggan. Perusahaan akan memastikan bahwa karyawan memenuhi standar ketat keamanan dan rahasia yang ditetapkan oleh undang-undang dan peraturan, dan memperhatikan tinggi pengaturan untuk rahasia dan penggunaan data pelanggan. Untuk rincian, silakan merujuk dokumen penemuan hukum perusahaan kita.</p>",
            },
            tab4Row: {
                span: "Metode verifikasi Q",
                p1: "CXMDirects adalah jawaban bagi orang-orang yang mencari platform kriptovaluta tingkat profesional. Tujuan kita adalah menciptakan pasar yang efisien dan adil yang menghubungkan para pedagang dari berbagai latar belakang dan gaya perdagangan. Kapasitas mesin yang cocok tinggi, latensi rendah, manajemen risiko yang maju, dan likiditas yang tinggi membuat CXMDirects menjadi peserta unik di pasar。",
                p2: "Tujuan awal CXMDirects adalah menciptakan pertukaran cryptocurrency pertama di dunia. Meskipun menjadi tugas yang sangat menantang, tim menyelesaikan pekerjaan pembangunan sistem dalam hanya lebih dari dua tahun. Pada bulan Juni 2016, CXMDirects secara resmi dilancarkan。",
                p3: "CXMDirects adalah masa depan rahasia kripto dan pertukaran opsi yang memiliki markas pusat di Panama City, Panama. CXMDirects pada awalnya adalah platform perdagangan BTC yang sepenuhnya didedikasikan, bagaimanapun, sejak itu, kontrak ETH telah ditambah dan lebih banyak kontrak mata uang juga akan tiba。",
                p4: "Saat ini, klien kita dapat perdagangan kontrak kekal, kontrak masa depan, dan kontrak pilihan. CXMDirects telah berkembang dengan cepat dan adalah salah satu pertukaran terbaik yang menawarkan masa depan rahasia kripto dan perdagangan kontrak abadi. Selain itu, CXMDirects tetap merupakan pertukaran yang memimpin menawarkan pilihan penyelesaian uang tunai gaya Eropa dan terus menetapkan standar untuk industri。",
                p5: "CXMDirects Holding diijinkan dan diatur oleh Kepulauan Cayman Monetary Authority (CIMA) dengan nomor SIB 1612446. Untuk lebih rincian tentang lisensi Cayman, silakan kunjungi situs resmi CIMA di www.cima.ky",
                P6: ") Search. CXMDirectsGlobal memiliki License Layanan Finanzial (AFSL 398528) yang diijinkan oleh Australia Securities and Investment Commission (ASIC). Untuk lebih rincian tentang lisensi Australia, silakan kunjungi situs resmi ASIC di www.asic. gov.au ()",
                P7: ") Pencarian. CXMDirects International diijinkan dan diatur oleh Komisi Layanan Finanzial Mauritius (FSC) dengan nomor lisensi GB20025791. Untuk informasi lebih lanjut tentang lisensi Mauritius, silakan kunjungi situs resmi FSC di www.fscmauritius. org",
                P8: ") Menjalankan penyelidikan. Semua operasi bisnis CXMDirects dilakukan di bawah pengawasan ketat dan mematuhi semua peraturan",
                P9: 'Latar belakang',
                P10: "CXMDirects adalah tim senior dengan pengalaman dan kecerdasan yang kaya dalam perdagangan keuangan dan industri teknologi, yang didirikan di Melbourne, Australia",
                P11: "Konsep CXMDirects adalah untuk membuat transaksi lebih mudah dan lebih ramah. strategi holistik kami dan model bisnis memungkinkan kita memiliki pemahaman komprensif dan mendalam tentang perkembangan industri dan permintaan pasar, memungkinkan kita untuk mengalokasi sumber daya yang lebih ditujukan, terus-menerus inovasi teknologi dan optimisasi efisiensi untuk terus menerus membawa pelanggan pengalaman perdagangan yang lebih nyaman dan ramah",
                P12: "Mengapa memilih",
                P13: "Kondisi perdagangan batas rendah",
                P14: "Platform perdagangan sederhana dan intuitif",
                P15: "Harga transaksi kompetitif",
                P16: "Diregulasikan oleh pihak berwenang industri",
                P17: "Tingkat tinggi dukungan online",
                P18: "Perlindungan keseimbangan negatif",
                P19: "Hadiah dan Kehormatan",
                P20: "CXMDirects dihormati untuk menerima banyak hadiah, termasuk Pengawal Keuangan Dunia Best Multi Asset Broker, Global BrandsMagazine's Best Mobile Trading Platform di Asia Pasifik, dan Global Fast Growing Financial Technology Broker, yang semua mengkonfirmasi usaha dan usaha terus menerus tim",
                P21: "Best Multi Asset Broker",
                P22: "Filosofi operasi kita",
                p23: "Nilai kita",
                p24: "Kita adalah salah satu platform perdagangan rahasia kripto yang tumbuh paling cepat di dunia, dan kita percaya bahwa nilai utama kita adalah kekuatan mendorong keberhasilan ini。",
                p25: "Kejujuran dan integritas",
                p26: "Percayaan pengguna penting; Oleh karena itu, kami menjalankan bisnis kami dengan standar integritas tertinggi dengan menyediakan sistem manajemen risiko yang adil, memperlakukan semua peserta pasar secara sama, dan solusi keamanan maju。",
                p27: "Kualitas layanan",
                p28: "Transaksi kriptokorrensi adalah proses tanpa batas, semua cuaca, di mana kualitas layanan tidak dikompromikan. Pelanggan adalah nilai paling penting kita; Oleh karena itu, tujuan kita adalah untuk menyediakan pengalaman perdagangan yang tidak terganggu dan lancar. Tujuan kita adalah mencapai ini dengan terus menerus memperluas dan memperbaiki arsitektur sistem kita, mengurangi waktu pemeliharaan, dan menyediakan layanan klien yang tepat waktu dan berbilang bahasa。",
                p29: "menciptakan",
                p30: "Dalam lingkungan kriptovaluta cepat, inovasi diperlukan. CXMDirects adalah sebuah perusahaan yang mendorong solusi yang bertujuan untuk menyediakan pengalaman perdagangan terbaik untuk jutaan pengguna, yang berasal dari arsitektur sistem skala dan prestasi tinggi kita。",
                p31: "Mengapa memilih derivat？",
                p32: "Derivat perdagangan memiliki banyak keuntungan - biaya transaksi yang lebih rendah, leverage yang lebih tinggi, likiditas yang lebih tinggi, dan penjualan pendek yang lebih mudah。",
                p33: "Perdagangan derivat juga memungkinkan strategi perdagangan yang lebih berbeda. Penjual dapat menggunakan derivat untuk melindungi risiko, arbitrasi, atau perdagangan spekulatif。",
                p34: "ilmu pengetahuan dan teknologi",
                p35: "Untuk memastikan sistem prestasi tinggi, itu telah dikembangkan selama lebih dari dua tahun sebelum hidup。",
                p36: "Cadangan yang dikembangkan untuk platform ini adalah untuk memastikan kemampuan untuk memproses sejumlah besar permintaan dengan keterlaluan rendah. Kami telah mengembangkan mesin yang cocok unik spesifik untuk CXMDirects, dan semua teknologi kami adalah milik。",
                p37: "Sejak peluncurannya, sistem pembersihan dan pengurusan risiko kami telah memastikan nol kerugian sosialisasi。",
                p38: "Di CXMDirects, kita percaya pada masa depan kriptovaluta. Tujuan kita adalah untuk terus berada di depan pasar derivat rahasia kripto. Kami membayangkan bahwa di masa depan, kriptovaluta akan digunakan oleh semua orang dan ditukar oleh jutaan orang. Kita siap untuk masa depan, dan juga sistem kita。",
            },
        },
        language: {
            tit: "Pilih Bahasa",
        },
        market: {
            tit: "Pengaturan pasar",
            tit1: "Tampilan harga",
            tit2: "Warna naik dan turun",
            buy_price: "Harga membeli",
            buy_price_tip: "Lukis garis K dengan harga pembelian",
            sell_price: "Harga penjualan",
            sell_price_tip: "Lukis garis K dengan harga jualan",
            color1: "Green rise and red fall",
            color2: "Red rise and green fall",
        },
        notice: {
            tit: "Pengaturan Notifikasi",
            tit1: "Buka saluran pemberitahuan",
            p1: "Silakan buka/verifikasi saluran pemberitahuan berikut, pesan penting tidak terlepas!",
            message: "pesan",
            push: "Dorong",
            verify: "validate",
            verified: "Diverifikasi",
            email: "E-mail",
            type1: "Pemasaran",
            tit2: "Notifikasi Kejadian Pemasaran",
            desc2: "Informasi promosional platform, aktivitas operasional, dan pesan lainnya",
            tit3: "Instruksi operasi",
            desc3: "Material pengetahuan kaya dan profesional",
            tit4: "Peringatan informasi",
            desc4: "Data keuangan dan informasi berita baru-baru ini",
            tit5: "Pengingat fluktuasi cerdas",
            desc5: "Termasuk variasi harga berbagai jenis penting, membantu Anda cepat mengambil kesempatan",
            type2: "Kelas Laporan",
            tit6: "Laporan Akun",
            desc6: "Laporan akun Anda akan dikirim bulanan dengan cara berikutnya",
            cycle: "siklus",
            month: "setiap bulan",
        },
        protocol: {
            tit: "Deklarasi dan Perjanjian",
            label1: "Polisi Privasi",
            label2: "Penjelasan Produk",
            label3: "Perjanjian Pelanggan",
            label4: "Penjelasan Risiko",
        },
        setting: {
            tit: "mengatur",
            label1: "pengaturan keamanan",
            label2: "Pengaturan pasar",
            label3: "Pengaturan Transaksi",
            label4: "bahasa",
            label5: "Mod tema",
            label6: "Pengaturan Notifikasi",
            label7: "Panduan Transaksi",
            label8: "Deklarasi dan Perjanjian",
            label9: "Bersihkan cache",
            label10: "Nomor versi",
            logout: "Daftar keluar dari daftar masuk",
        },
        theme: {
            tit: "Mod tema",
            theme1: "Mod cahaya",
            theme2: "Mod Gelap",
        },
        trade: {
            tit: "Pengaturan Transaksi",
            onclick_close: "Satu klik menutup posisi",
        }
    },
    trade: {
        fliter_type1: "Pilihan sendiri",
        fliter_type2: "pasar",
        place_search: "Masukkan nama/pencarian kode variasi",
        fliter_type3: "Popular",
        fliter_type4: "pertukaran asing",
        fliter_type5: "komoditas",
        fliter_type6: "saham",
        fliter_type7: "indeks",
        fliter_type8: "enkripsi",
        tip1: "Complete identity verification to deposit transactions and invest globally",
        sell: "Jual",
        buy: "Beli",
        add_optional: "Tambah Pemilihan Sendiri",
        optional_recommend: "Rekomendasi pemilihan diri",
        change_batch: "Ubah batch",
        edit_tit: "Sunting Pemilihan",
        breed: "variasi",
        topping: "Atas",
        sort: "sort",
        all: "Pilih Semua",
    },
    table: {
        label_breed: "variasi",
        label_direction: "arah",
        label_price: "harga",
        label_operate: "operasi",
        empty_tip1: "Tidak ada peringatan saat ini",
        empty_tip2: "Tidak ada catatan deposit",
        empty_tip3: "Tidak ada catatan pembayaran tunai",
        label_amount: "uang",
        label_type: "tipe",
        label_time: "waktu",
        label_name: "nama",
        label_email: "E-mail",
        label_address: "Negara tinggal",
        label_account: "Akaun perdagangan",
        label_currency: "Uang dasar",
        label_company: "Perusahaan pembukaan rekening saat ini",
    },
    form: {
        to: "ke",
        start_time: "waktu awal",
        end_time: "Waktu akhir",
        week: "Minggu lalu",
        month: "Bulan lalu",
        threeMonth: "Dalam tiga bulan terakhir",
        sixMonth: "Dalam enam bulan terakhir",
        label_feedback: "Pertanyaan dan saran",
        place_feedback: "Silakan masukkan pertanyaan atau saran Anda",
        label_img: "gambar",
        label_img_tip: "Opsional, menyediakan foto layar dari masalah",
        feedback_tip: "Jika Anda memiliki pertanyaan mendesak, silakan hubungi",
        online: "Layanan Online",
        label_cardNumber: "Nomor Identifikasi",
        label_realName: "nama penuh",
        place_realName: "Silakan masukkan nama depanmu",
        message_realName: "Silakan masukkan nama penuh Anda",
        label_firstName: "nama keluarga",
        place_firstName: "Silakan masukkan nama belakangmu",
        message_firstName: "Silakan masukkan nama belakang",
        label_lastName: "nama",
        place_lastName: "Silakan masukkan nama tengah Anda",
        message_lastName: "Silakan masukkan nama",
        label_birthday: "Tanggal lahir",
        place_birthday: "Silakan masukkan tanggal kelahiranmu",
        message_birthday: "Silakan masukkan tanggal lahir",
        label_idType: "Jenis dokumen",
        tip_cardMain: "Ambil atau mengunggah foto FRONT dari kartu identitasmu",
        tip_cardBack: "Ambil atau mengunggah foto dari BAlik kartu identitasmu",
        tip_cardMain1: "Ambil atau mengunggah foto FRONT dari lisensi pengemudi Anda",
        tip_cardBack1: "Ambil atau mengunggah foto dari BAlik lisensi pengemudi Anda",
        tip_cardMain2: "Ambil atau mengunggah foto paspor Anda",
        tip_cardBack2: "Ambil atau mengunggah foto paspor Anda",
        label_password: "sandi",
        place_password: "Silakan masukkan sandi",
        message_password: "Silakan masukkan sandi",
        label_crmpsd: "Konfirmasi Sandi",
        place_crmpsd: "Silakan masukkan sandi lagi",
        message_crmpsd: "Silakan masukkan sandi lagi",
        label_email: "E-mail",
		place_email: "Alamat surel",
		message_email: "Silakan masukkan alamat email Anda",
        label_captcha: "Kode verifikasi",
		place_captcha: "Silakan masukkan kode verifikasi",
		message_captcha: "Silakan masukkan kode verifikasi",
		get_captcha: "Dapatkan kode verifikasi",
        label_phone: "telepon",
		place_phone: "Silakan masukkan nomor telepon",
		message_phone: "Silakan masukkan nomor telepon",
        labelAmount: "uang",
        placeAmount: "Silakan masukkan jumlah",
        messageAmount: "Silakan masukkan jumlah",
        labelWalletName: "Nama dompet",
		messageWalletName: "Silakan masukkan nama dompet",
		placeWalletName: "Silakan masukkan nama dompet",
		labelWalletAddress: "Alamat dompet",
		messageWalletAddress: "Silakan masukkan alamat dompet",
		placeWalletAddress: "Silakan masukkan alamat dompet",
        labelWithdrawAccount: "Akaun penarikan",
        placeWithdrawAccount: "Akaun penarikan",
        messageWithdrawAccount: "Akaun penarikan",
		placeTimeLimit: "Silakan masukkan batas waktu",
		messageTimeLimit: "Silakan masukkan batas waktu",
    },
	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgia",
		bosnia_and_Herzegovina: "Bosnia dan Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Kambodja",
		canada: "Kanada",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Kolombia",
		costa_Rica: "Costa Rica",
		croatia: "KroatiaName",
		cyprus: "Cyprus",
		czech_Republic: "Republik Ceko",
		denmark: "Denmark",
		dominican_Republic: "Republik DominikanName",
		egypt: "Mesir",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "Perancis",
		georgia: "Georgia",
		germany: "Jerman",
		ghana: "Ghana",
		greece: "Yunani",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Cina",
		hungary: "Hungary",
		iceland: "Islandia",
		ireland: "Irlandia",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Irak",
		japan: "Jepang",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgistan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxemburg",
		macao_China: "Macau, Cina",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Meksiko",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Maroko",
		myanmar: "Myanmar",
		netherlands: "Belanda",
		new_Zealand: "Seland Baru",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norwegia",
		oman: "Oman",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Filipina",
		poland: "poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Rumania",
		russia: "Rusia",
		republic_of_Trinidad_and_Tobago: "Trinidad dan Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arab Saudi",
		serbia: "Serbia",
		singapore: "Singapura",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "Afrika Selatan",
		spain: "Spanyol",
		sri_Lanka: "Sri Lanka",
		sweden: "Swedia",
		switzerland: "Swiss",
		taiwan_China: "Taiwan, Cina",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "T ürkiye",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraina",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "Inggris",
		united_States: "Amerika",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "pulau perawan Inggris",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Kepulauan Cayman",
		central_African_Republic: "Republik Afrika Tengah",
		chad: "Chad",
		comoros: "Komor",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongo (Kinshasa)",
		djibouti: "Djibouti",
		ecuador: "	Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Ekvatorial",
		eritrea: "Eritrea",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Pulau Man",
		cote_d_Ivoire: "Pantai Ivory",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Korea Utara",
		reunion: "Reunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Surinam",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Kepulauan Perawan, Amerika Serikat",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikan",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "zimbabwe",
		china: "Tiongkok",
	}
}
