<template>
  <router-view></router-view>
</template>
<script>
export default {
  mounted() {
    // if (!this._isMobile()) {
    //   window.location.href = "https://www.Deribit.com/";
    //   console.log('手机端')
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>
<style lang="less">
html {


  --border-theme-color: #EEEEEE;
  --text-theme-color: #000000;
}

body {

  .el-empty {
    .el-button--primary {
      background-color: var(--bg-color1);
      color: var(--color-theme-main);
      border: none;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--font-icon-t3)
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: var(--font-icon-t3)
  }

  ::-webkit-scrollbar-track {
    background: var(--el-color-white)
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-radius: 50%;
    background-color: var(--color-theme-main);
    border-color: var(--color-theme-main);
  }

  .el-checkbox__inner {
    border-radius: 50%;
  }

  --bg-white: #FFFFFF;
  --color-dark: #000000;
  --line-color-grey1: rgba(153, 153, 153, 0.1);
  --color-up: #149552;
  --color-down: #DB2F4A;
  --color-main: #036BF2;
  --color-theme-main: #036BF2;
  --color-b1: #006CF6;
  --color-b2: #0067EA;
  --color-b4: #006CF699;
  --color-b5: #006CF63D;

  --font-color1: #BDBDBD;
  --font-color2: #FC6924;
  --font-color3: #85868A;
  --font-color4: #4F8CCD;
  --bg-up: #EBF7F3;
  --bg-down: #FEBCCA;
  --bg-main: #EBF2FD;
  --bg-down3: rgba(254, 188, 202, .3);
  --bg-color1: #ECECEC;
  --bg-color2: #F6F6F6;
  --bg-color3: #ECF2FE;
  --bg-color4: #F5F8FF;
  --bg-color5: #E6EBFE;
  --bg-color6: #F6F6F6;
  --bg-color7: #BEBEBE;
  --bg-color8: #FC2421;
  --bg-color9: #FBAD14;
  --bg-color10: #ECAE77;


  --theme-bg-color: #F2F3F7;
  --color-theme-green: #149552;
  --color-theme-red: #DB2F4A;
  --color-theme-grey: #999999;
  --color-theme-orange: #F56B2E;
  --color-theme-warning: #E6A23C;
  --color-theme-success: #67C23A;
  --bg-theme-green: #eaf7f0;
  --bg-theme-red: #fdedee;
  --bg-theme-grey: rgba(153, 153, 153, 0.1);
  // --bg-img-card: url(/static/image/card-bg.png);
  --bg-card-green: #F1FFFA;
  // --bg-theme: #FFF;

  .el-select-dropdown {

    .el-select-dropdown__item {
      // font-size: 24px !important;
      // height: 60px;
      // line-height: 60px;
    }

    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      color: var(--color-theme-main) !important;
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

  }

  .el-form-item {
    margin-bottom: 40px;
  }

  // .el-button {
  //   background-color: var(--bg-theme-main);
  //   height: 45px;
  //   color: #FFF;
  //   width: 100%;
  //   border-radius: 10px;
  //   font-size: 18px;
  // }
  .el-message-box {
    width: 80%;
  }

}
.service {
  position: fixed;
  right: 25px;
  bottom: 30vh;
  z-index: 10;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  background-color: #1f2a47;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 18px;
  }
}
</style>
