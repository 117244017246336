export default {
    common: {
        expected: "अपेक्षित परिणाम",
        view: "जाँच",
        confirm: "पुष्टि करें",
        cancel: "रद्द करें",
        deposit: "पैसा डोसिट",
        withdraw: "पैसा बाहर",
        noData: "वर्तमान में कोई डाटा उपलब्ध नहीं है",
        submit: "भेजें",
        modify: "परिवर्धित करें",
        hand: "हाथ",
        confirm_verify: "वैधीकरण पुष्टि करें",
        next: "अगला चरण",
        complete: "पूर्ण",
        delete: "मिटाएँ",
		countryCode: "देश कोड",
        no_balance: "अपर्याप्त खाता संतुलन",
    },
    currency: {
		btcusdt: "बिटकोइन",
		ethusdt: "इथेरियम",
		ltcusdt: "लिटेकिन",
		xrpusdt: "श्वेबोमुद्रा",
		adausdt: "एडा कोइन",
		eosusdt: "पोमेलो कोन",
		dotusdt: "बोकामुद्रा",
		trxusdt: "Wave field coin",
		xmrusdt: "मोन्रो",
		dogeusdt: "कुत्रामुद्रा",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "AUD USD",
		fx_sgbpusd: "GBP USD",
		fx_seurusd: "यूरो यूएसडी",
		fx_snzdusd: "न्यूजीलैंड डॉलरडॉलर",
		fx_susdcad: "USD CAD",
		fx_susdchf: "USD CHF",
		fx_susdjpy: "USD JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD CHF",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "AUD न्यूजीलैंड डॉलर (NZD)",
		fx_scadchf: "केडी स्विस फ्रांक",
		fx_scadjpy: "कैडजापानी येन",
		fx_schfjpy: "स्विस फ्रैंक येन",
		fx_seuraud: "Euro AUD",
		fx_seurcad: "EUR CAD",
		fx_seurgbp: "EUR GBP",
		fx_seurjpy: "EUR JPY",
		fx_seurnzd: "यूरो न्यूजीलैंड डॉलर (एनजीडी)",
		fx_sgbpaud: "जीबीपी AUD",
		fx_sgbpchf: "GBP CHF",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "GBP NZD",
		fx_scadnzd: "कैनेडियन और न्यूजीलैंड डॉलर",
		hf_CL: "WTI न्यूयॉर्क क्रूड तेल",
		hf_OIL: "ब्रेंट क्रूड तेल",
		hf_GC: "न्यू यॉर्क गोल्ड",
		hf_SI: "न्यू यॉर्क सिल्भर",
		hf_HG: "अमेरिकन कोप्पर",
		hf_NG: "युनाइटेड स्टेट्स में स्वाभाविक गैस",
		hf_CAD: "लंडनCity name (optional, probably does not need a translation)",
		hf_AHD: "लंडन अलुमिनियम",
		HX_AAPL: "सप्पेल",
		HX_BABA: "अलिबाबाCity name (optional, probably does not need a translation)",
		HX_GOOG: "गूगल",
		HX_WMT: "वाल मार्ट",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Tesla",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "जर्मनीName",
		HX_IBEX: "IBEX35, स्पेन",
		HX_SPX: "S&P 500 सूची",
		HX_FTSE: "FTSE 100",
		HX_KS11: "कोरियाKOSPI",
		HX_NZ50: "New Zealand 50",
		HX_PSI20: "पोर्टुगाल PSI20",
		hf_ZSD: "लंडनCity name (optional, probably does not need a translation)",
		hf_NID: "लंडन निकेल",
		hf_PBD: "लंडनCity name (optional, probably does not need a translation)",
		hf_SND: "लंडन टिन",
		hf_XAU: "लंडन गोल्ड (स्पोट गोल्ड)",
		hf_XAG: "लंडन चांदी (स्पॉट चांदी)",
		hf_XPT: "Platinum futures",
		hf_XPD: "पालाडियम भविष्य",
		hf_CT: "American cotton",
		hf_SM: "Meihuang Bean Powder",
		hf_BO: "अमेरिकन सोयबियन तेल",
		HX_SENSEX: "Constellation name (optional)",
		HX_KLSE: "एफटीसी मलेशिया केलसी",
		HX_KSE100: "काराची, पाकिस्तानName",
		HX_FCHI: "फ्रान्स CAC40",
		HX_SX5E: "यूरोपेली बर्फबारी ५०",
		HX_ICEXI: "आईएक्स आइसलैंड",
		HX_ASE: "Athens, Greece ASE",
		HX_OMXC20: "एमएक्स कोपेनहेगन 20",
		HX_OSEBX: "नार्वे",
		HX_OMXSPI: "स्वीडन OMXSPI",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "डिस्नीCity name (optional, probably does not need a translation)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "Mastercard",
		HX_NVDA: "Constellation name (optional)",
		HX_INTC: "इन्टील",
		HX_GOGO: "Gogo Inc",
		HX_STX: "सिगेट टेकनोलाजिकी",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "आस्ट्रो वूड कोन",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
    currenctType: {
        title: "चुनें",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'डॉलर',
        currenctType15: 'हांग कांग मुद्रा',
        currenctType16: 'ताइवान मुद्रा',
        currenctType17: 'रिंगगिट',
        currenctType18: 'सिंगापुर',
        currenctType19: 'येन',
        currenctType20: 'यूरो',
        currenctType21: 'डॉलर A',
        currenctType22: 'रूपिया',
        currenctType23: 'पाउन्ड',
        currenctType24: 'थाई बाथ',
        currenctType25: 'डॉलर',
        currenctType26: 'VND',
        currenctType27: 'कोरियाई जीत',
        currenctType28: 'MOP',
    },
    addnew3: {
        direction: "दिशा",
        sell_price: "मूल्य बेच रहा है",
        number: "मात्रा",
        type: "प्रदान समय",
        buy_price: "मूल्य खरीद करें",
        profit: "P/L",
        sell_time: "विक्रेत समय",
    },
    addnew2: {
        address: {
            second_name: "Katakana",
            account_type: "खाता प्रकार",
            ifsc_code: "IFSC कोड",
            title: "निकालें",
            account_number: "खाता संख्या",
            add_btn: "Add Address",
            label_type: "प्रकार चुनें",
            place_type: "कृपया एक प्रकार चुनें",
            label_number: "खाता संख्या",
            place_number: "कृपया खाता संख्या भरें",
            wallet_address: 'वालेट पता',
            bank_name: 'बैंक नाम',
            bank_address: "Bank address",
            name: "नाम",
            payee_address: 'प्रापक पता',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "ब्रैंच कोड",
            bank_code: "बैंक कोड",
            phone: "मोबाइल फोन संख्या",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "कृपया सामग्री भरें",
        },
    },
    addnew: {
        realFirst: "कृपया पहिले परिचय प्रमाणीकरण करें",
        payFirst: "कृपया पासवर्ड पहले सेट करें",
        password1: "लॉगइन पासवर्ड बदलें",
        password2: "पासवर्ड बदलें",
        oldPass: "पुराना पासवर्ड",
        allView: "सभी पढ़ें",
        confirmOrder: "क्रम पुष्टि करें",
        name: "नाम",
        fee: "फीज हैंडलिंग",
        orderCycle: "समय चुनें",
        loan: "कर्ज",
        loanTit: "मदद कर्ज",
        loanHostory: "लॉन रेकॉर्ड",
        labelProductTitle: "उत्पादन नाम",
        immediateRepayment: "तुरंत प्रतिक्रिया",
        loadAmount: "अपेक्षित लान मात्रा",
        RepaymentCycle: "लॉन पुनरावृत्ति साइकल",
        day: "दिन",
        dailyRate: "दिनांक व्याज दर",
        repaymentMethod: "पुनरावृत्ति विधि",
        lendingCompany: "लॉन कंपनी",
        loanTip: "क्रेडिट लॉन (कृपया निश्चित करें कि छवि स्पष्ट और दृश्यात्मक है)",
        loanTip1: "गुण प्रमाणपत्र अपलोड करें",
        loanTip2: "आगत का प्रमाण (काम संबंध)",
        loanTip3: "बैंक स्टेटेंशन विवरण",
        loanTip4: "अपने आईडी कार्ड के आगे की फोटो अपलोड करें",
        interest: "प्राथमिकता",
        repaid: "पुनरावृत्ति",
        unpaid: "बिना पैसा",
        tab1: "कोइन",
        tab2: "कंट्रांट",
        tab3: "विकल्प",
        available: "विकल्प",
        pending: "ताला लगाता है",
        equivalent: "बराबर",
        fundTransfer: "फ़ंड स्थानान्तरण",
        flashExchange: "फ्लैश बदलें",
        select_breed: "मुद्रा चुनें",
        from: "से",
        to: "में",
        transferQuantity: "स्थानान्तरण मात्रा",
        repaymentMethod1:"पुराने के बारे में एक बदला है"
    },
    params: {
        product_foreign: "विदेशी बदलें",
        product_shop: "कमोडिटी",
        product_number: "सूची",
        product_stock: "साझेदार",
        product_coin: "एनक्रिप्शन",
        billTypes: [
            { v: 101, name: 'फिर चार्ज करें' },
            { v: 102, name: 'जोड़ें' },
            { v: 103, name: 'जल्दी' },
            { v: 104, name: 'टाविंग' },
            { v: 105, name: 'डिपोस्ट' },
            { v: 106, name: 'डिपोस्ट' },
            { v: 201, name: 'हटाएँ' },
            { v: 202, name: 'डिडुक्शन' },
            { v: 203, name: 'विडडवाल सफलता' },
            { v: 204, name: 'वाटडवाल असफल' },
            { v: 205, name: 'हटावाल फी' },
            {v: 206, name: 'स्थानान्तरण'},
            {v: 207, name: 'स्थानान्तरण'},
            {v: 208, name: 'मुद्रा स्थानान्तरण बाहर'},
            {v: 209, name: 'मुद्रा स्थानान्तरण'},
            { v: 301, name: 'डोपोसिट का निकाल' },
            { v: 302, name: 'हैंडलिंग फीज़ का डिड्यूक्शन' },
            { v: 303, name: 'डोपोसिट का पुनरावृत्ति' },
            { v: 304, name: 'लाभ' },
            { v: 305, name: 'गुमान' },
            {v: 311, name: 'विकल्प'},
            {v: 312, name: 'विकल्प वापस होता है'},
            {v: 313, name: 'विकल्प पुनरावृत्ति'},
            {v: 314, name: 'विकल्प हैंडलिंग फी'},
            {v: 315, name: 'मुद्रा'},
            {v: 316, name: 'मुद्रा'},
            {v: 317, name: 'कोइन खरीद लौटाना'},
            {v: 318, name: 'प्राप्त कोइन्स का खरीदान'},
            {v: 319, name: 'प्राप्त कोइन्स का खरीदान'},
            {v: 320, name: 'मुद्रा विक्रेता फ्रीज़'},
            {v: 321, name: 'मुद्रा'},
            {v: 322, name: 'कोइन विक्रेता परित्याग'},
            {v: 323, name: 'खाता में बेचा गया कोइन्स'},
            {v: 324, name: 'खाता में बेचा गया कोइन्स'},
            {v: 325, name: 'मुद्रा हैंडलिंग फी'},
            {v: 401, name: 'मिनिंग मशीन जोड़ें'},
            {v: 402, name: 'मिनिंग मशीन वापस'},
            {v: 403, name: 'मिनिंग मशीनरी आगत'},
            {v: 404, name: 'मिनिंग मशीन बाहर होना'},
            {v: 405, name: 'मिनिंग मशीन हैंडलिंग फी'},
            {v: 411, name: 'आयाम लेना है'},
            {v: 412, name: 'लॉन हैंडलिंग फीज़'},
            {v: 413, name: 'सफलतापूर्वक बदला'},
        ],
    },
    lockming: {
        profit: "हाल में (रोज़ी कमाना)",
        cycle: "आर्थिक सायकल",
        limit: "एक स्ट्रोकसीमा",
        dividend_time: "विभाजित पैसा समय",
        every_day: "हर दिन",
        funds: "विश्वासित्वराजधानी",
        back: "मियाद परित्याग",
        ransom: "दूर बुलाया गया",
        label_amount: "डेस्पोज़िट",
        place_amount: "कृपया प्रविष्ट करें...",
        available: "उपलब्ध",
        expect_income: "अपेक्षित आगत",
        title: "तालाबन्द मिनिंग",
        tip: "अन्तिम लाभ के लिए मिनिट",
        label_today_income: "अपेक्षित आज की उत्पन्न",
        label_total_income: "सम्पूर्ण आगत",
        label_order: "होस्ट आदेश",
        label_number: "एकल ट्रांसेक्शन मात्रा",
        daily_return: "हर दिनउत्पन्न",
        subscribe: "सदस्यता",
        position: "स्थिति",
        label_state: "स्थिति",
        subscribe_time: "सदस्यता समय",
        expiration_time: "मियाद समय",
        income: "आयात",
        errorTip1: "कृपया मात्रा भरें",
        tip_tit: "क्या आप सुनिश्चित है कि आप प्रारंभ से बाहर निकलना चाहते हैं",
    },
    footer: {
        nav1: "घर पृष्ठ",
        nav2: "transaction",
        nav3: "बिन",
        nav4: "मेरा",
        kline_set1: "मार्केट सेटिंग",
        kline_set2: "ट्रांसेक्शन विन्यास",
    },
    login: {
        login: "लॉगइन",
        register: "रेजिस्टर",
        type1: "मोबाइल फोन संख्या",
        type2: "मेलबाक्स",
        login_btn: "सुरक्षित लॉगइन",
        register_btn: "अब रेजिस्टर करें",
        psd_login: "पासवर्ड लागइन",
        verify_login: "प्रमाणीकरण कोड लागइन",
        forget: "पासवर्ड भूल गया?",
        no_account: "कोई खाता नहीं?？",
        have_account: "मौजूदा खाता",
    },
    home: {
        home: {
            nav1: "ऊपर और नीचे रेज़िंग",
            nav2: "तेज प्रारंभ",
            nav3: "हमारे बारेमें",
            nav4: "निमन्त्रणा बदला देता है",
            most_follow: "अधिकतम अनुसरण",
            news_type1: "रणनीति",
            news_type2: "समाचार",
            news_type3: "आर्थिक कैलेन्डर",
            news_tab1: "पूर्ण",
            news_tab2: "विदेशी बदलें",
            news_tab3: "साझेदार",
            news_tab4: "कमोडिटी",
            news_tab5: "सूची",
            news_tab6: "डाटा",
            news_tab7: "घटना",
            news_tab8: "छुट्टी",
            label_previous: "पिछला मान",
            label_predictive: "आनुमान",
            label_announced: "निर्देशित मूल्य",
        },
        kilne: {
            label_bp: "कल प्राप्त",
            label_sp: "आज खोल रहा है",
            label_height: "उच्च",
            label_low: "न्यूनतम",
            tip: "हाय, मैं मिट्रेड जीपीटी विविध सहायक हूँ",
            row1_tit: "रणनीति",
            row1_rinei: "दिन में,",
            row1_short: "छोटा समय",
            row1_middle: "मध्यम",
            row1_time: "प्राप्त समय",
            row1_tit2: "ट्रेडिंग रणनीति",
            data_sources: "डाटा स्रोत",
            row2_tit1: "डाटा",
            row2_tit2: "ट्रेडिंग संवेदन",
            row2_label_sell: "विक्रेता",
            row2_label_buy: "ब्यूयर",
            tip2: "सिर्फ संदर्भ के लिए, हमारी कंपनी की स्थिति के रूप में नहीं",
            tip3: "प्रत्येक 15 मिनट अद्यतन करें",
            row2_tit3: "गुणगुणगुण",
            minute: "मिनट",
            today: "आज",
            right_tit7: "गुणधर्म वेरिफेशन सीमा",
            now_price: "मौजूदा मूल्य",
            right_low_price: "निम्न मूल्य",
            right_height_price: "अधिकतम मूल्य",
            details: "विवरण",
            right_label1: "एकल ट्रांसेक्शन की संख्या",
            right_label2: "अधिकतम स्तर",
            right_label3: "कंट्राक्ट आकार",
            right_label4: "अधिकतम कुल स्थिति मात्रा",
            right_label5: "फ्लोटिंग मूल्य फरक",
            right_label6: "रात का फीड",
            hand: "हाथ",
            right_sell: "बेचें",
            right_buy: "खरीद करें",
            right_collection_time: "संग्रह समय",
            right_tip3: "पूरा ट्रांसेक्शन आकार से जमा करें",
            right_label9: "ट्रेडिंग अवधि",
            right_label10: "मौजूदा अवधि",
            right_label11: "अगला आयात",
        },
        placeorder: {
            direction_buyPrice: "मूल्य किया जा रहा है",
            direction_sellPrice: "मूल्य बेच रहा है",
            direction_sell: "बेचें",
            direction_buy: "खरीद करें",
            market_price: "मार्केट मूल्य सूची",
            check_price: "सूची",
            sell_tip: "जब मूल्य की परिस्थिति मिलेगी तो बेचें",
            buy_tip: "जबकि मूल्य की परिस्थिति मिले",
            trade_number: "ट्रांसेक्शन मात्रा",
            hand: "हाथ",
            select_lever: "लेवर चुनें",
            right_tip: "डिपोस्ट आवश्यक",
            balance: "उपलब्ध",
            label_stop_win_price: "उत्पन्न रोकें",
            label_stop_lose_price: "गुमान रोकें",
            expected_profit_loss: "अपेक्षित लाभ और घाट",
            trailing_stop: "ट्रेलिंग रोकें",
        },
        ranking: {
            tit: "दिन ऊपर और नीचे दर्जे हैं",
            p: "हॉटस्पोट्स कैप्चर करें और संभावना",
            renew: "अद्यतन",
            growth_chart: "चार्ट बढ़ाएँ",
            drop_list: "सूची छोड़ें",
            show_closed_market: "ऑफ मार्केट ब्रैंड्स प्रदर्शन किया जा रहा है",
            filter1: "पूर्ण",
            filter2: "विदेशी बदलें",
            filter3: "कमोडिटी",
            filter4: "साझेदार",
            filter5: "सूची",
            filter6: "एनक्रिप्शन",
            td1: "रेजिंग",
            td2: "ट्रेंड",
            td3: "दिनांक वृद्धि/विक्रिया मूल्य",
        },
        remind: {
            now_sell_price: "मौजूदा विक्रेत क्रम",
            direction_buyPrice: "मूल्य किया जा रहा है",
            direction_sellPrice: "मूल्य बेच रहा है",
            direction_sell: "बेचें",
            direction_buy: "खरीद करें",
            equal: "जब",
            p: "मुझे याद करो जब निम्न मूल्य पहुँच जाएँगे",
            btn1: "सचेतक सेट करें",
            list_tit: "स्मार्क सूची",
        },
    },
    my: {
        report: {
            tit: "खाता रिपोर्ट",
            tab1: "रिपोर्ट@ info: tooltip",
            tab2: "दिनांक विवरण",
            tab3: "महिनावारी संदेश",
            history_tit: "ट्रांसेक्शन इतिहास रिपोर्ट",
            account_tit: "खाता रिपोर्ट",
            filter: "स्क्रीन",
            tip1: "कृपया अपने ट्रांसेक्शन इतिहास रिपोर्ट निर्यात करने के लिए उचित तिथि सीमा चुनें.。",
            tip2: "पसंदीदा समय",
            email_send: "इमेल में भेजें",
        },
        accountMsg: {
            tit: "खाता जानकारी",
            label1: "व्यक्तिगत जानकारी",
        },
        bill: {
            tit: "राजधानी फ्लोव विवरण",
            all: "पूर्ण",
            time: "समय",
        },
        feedback: {
            tit: "फंक्शनल सिफारिसेंस",
        },
        identity: {
            tit: "सत्यापन",
            type1: "आईडी कार्ड",
            type2: "ड्राइवर की लाइसेंस",
            type3: "पासपोर्ट",
            tip: "मैं पुष्टि करता हूँ कि प्रदान किया गया है सभी जानकारी और दस्तावेज़ पूरी हैं, सच्चा है और सच्चा",
        },
        my: {
            is: "पहिले से",
            no: "सत्यापित नहीं",
            verify: "सत्यापित",
            id_verify: "सत्यापन",
            verify_tip: "ट्रांसेक्शन को डेस्पोजिट करने के लिए पहिचान परीक्षण पूरा करें",
            no_verify: "अब सत्यापित करें",
            balance: "उपलब्ध",
            profit_loss: "प्रोफाइट और हराम",
            marginLevel: "सीमा स्तर",
            btn1: "वास्तविक खाता में स्विच करें",
            deposit_withdraw_record: "डिपोज़िट और छोड़ने के रेकॉर्ड",
            capital_flow_details: "राजधानी फ्लोव विवरण",
            welfare_center: "वेलफेर केंद्र",
            tip1: "आपके पास है",
            tip2: "प्रतिज्ञा का प्रतिज्ञा",
            feedback: "फंक्शनल सिफारिसेंस",
            report: "खाता रिपोर्ट",
            setting: "सेटअप",
        },
        news: {
            tit: "सूचना",
        },
        record: {
            tit: "डिपोज़िट और छोड़ने के रेकॉर्ड",
            cycle: "सायकल",
        },
        share: {
            tit: "मित्रों को आमन्त्रित करें",
            invite_success: "सफलतापूर्वक मित्रों को आमन्त्रित करें",
            tip1: "पैसा बदलाव",
            tip2: "दोस्तों को सबसे उच्च लेने के लिए आमन्त्रित करें",
            tip3: "मित्रों को पैसा भेजने के लिए आमन्त्रित करें",
        },
        welfare: {
            tit: "वेलफेर केंद्र",
            tab1: "कार्ड रोल",
            tab2: "सक्रिया",
            history_record: "इतिहास",
            period_validity: "वैध अवधि",
        },
        wallet: {
            tit: "वालेट",
            addNew: "नया वालेट जोड़ें",
        },
        withdraw: {
            tit: "पैसा बाहर",
            addNew: "नया वालेट जोड़ें",
        },
    },
    order: {
        demo_account: "सिमुलेटेड खाता",
        real_account: "वास्तविक खाता",
        tip: "खोलने के बाद सोने को ट्रांसेक्शन्स के लिए डेस्टेड कर सकता है",
        tab1: "स्थिति",
        tab2: "सूची",
        tab3: "इतिहास",
        list_empty: "वर्तमान में कोई प्रतीक्षित आदेश नहीं है",
        start_trade: "ट्रेडिंग प्रारंभ करें",
        hand_tip: "इनपुट सीमा",
        kline: {
            direction_sell: "बेचें",
            direction_buy: "खरीद करें",
            open_price: "मूल्य खोल रहा है",
            now_price: "मौजूदा मूल्य",
            trade_account: "खाता ट्रेडिंग",
            demo_account: "सिमुलेटेड खाता",
            order_id: "क्रम आईडी",
            open_time: "समय खोलें",
            stop_profit: "उत्पन्न रोकें",
            stop_loss: "गुमान रोकें",
            trailing_stop: "ट्रेलिंग रोकें",
            float_profit_loss: "फ्लोटिंग P/L",
            overnight_fee: "रात का फीड",
            margin: "सीमा",
            maintainsMargin: "मेंटेन्ट सीमा",
            open_value: "मूल्य खोल रहा है",
            now_value: "मौजूदा मान",
            notset: "सेट नहीं",
            close_position: "स्थिति बन्द कर रहा है",
            close_number: "सामान्य मात्रा",
            onclick_close: "एक क्लिक बन्द स्थिति",
            btn: "बन्द स्थिति को यकीन करें",
            balance: "उपलब्ध",
            profit_loss: "प्रोफाइट और हराम",
            netValue: "नेट मूल्य",
            marginLevel: "सीमा स्तर",
        },
        modify: {
            tit: "स्थिति क्रम परिवर्धित करें",
            expected_profit_loss: "अपेक्षित लाभ और घाट",
        },

    },
    setting: {
        security: {
            modify_password_tit: "नया पासवर्ड सेट करें",
            verify_email_tit: "इमेल परीक्षा करें",
            verify_password_tit: "अपना पासवर्ड सत्यापित करें",
            verify_password_tip: "जारी रखने के लिए अपना मिट्रेड लॉगइन पासवर्ड भरें",
            verify_phone_tit: "फोन संख्या जाँचें",
            tit: "खाता सुरक्षा",
            tip: "आपके खाते की सुरक्षा और हमारी सेवा सूचना स्वीकार करने के लिए, कम से कम एक प्रकार प्रमाणीकरण सक्षम करने का सिफारिस है.",
            label1: "पासवर्ड बदलें",
            label2: "खाता रद्द करें",
            label3: "द्वितीय परीक्षण",
            label4: "युक्ति प्रबंधन",
            tip2: "नया युक्ति लागइन की जरूरी है",
        },
        tradenews: {
            tit: "ट्रांसेक्शन मार्गदर्शकName",
            tab1: "तेज प्रारंभ",
            tab2: "उन्नत शिक्षण",
            tab3: "मदद केंद्र",
            tab4: "मिट्रेड के बारे में",
            viewMore: "अधिक देखें",
            details: {
                tit: "विदेशी एक्सचेंज के परिचय",
                tip1: "अनुमानित शिक्षण अवधि",
                tip2: "मिनट",
            },
            tab1Row: {
                tit: "  यह मिट्रेड से व्यापार करने के लिए तेज सीखने के लिए एक वर्ग कमरा है",
                tit1: "मिट्रेड पर व्यापार कैसे है",
                tit2: "एक वास्तविक खाता कैसे खोलेंगे",
                tit3: "व्यापार के लिए स्थिति कैसे खोलेंगे",
                tit4: "कैसे रोका गया और घाटा बन्द करना है",
                tit5: "एक स्थिति कैसे बन्द करना",
                p1: "आप लक्ष्य को खरीद कर सकते हैं या विक्रिय कर सकते हैं. मिट्रेड में एक स्थिति खोल सकते हैं, अर्थ है कि आप इसके लिए रिसाव हैं",
                p2: "लक्ष्य प्रोडेक्ट के मूल्य फ्लुक्यूटेशन पर आधारित ट्रेडिंग",
                p3: "उदाहरण के लिए, यदि आप सोने की क्रममें बढ़ाने की आशा रखते हैं, तो उत्पादन को खरीद करें; यदि आप सोने क्रममें घटाने की आशा रखते हैं, तो उत्प",
                p4: "इस से प्रोफिटिंग",
                p5: "इससे भी मिट्रेड 'लेव्रेज़ड व्यापार' प्रदान करता है. आप एक हाथों के अधिक व्यापार करने के लिए लेव्रेज़ का उपयोग",
                p6: "लेवेरेजिंग लाभ बढ़ाएगा और खोटा भी बढ़ाएगा",
                p7: "यह भी 'मार्जिन्ट व्यापारिंग' के रूप में पहचाना है. जब आप एक स्थिति खोलेंगे, तो आपके मिट्रेड खाते से एक निश्चित बैलेंस",
                p8: "आरंभिक मार्जिन",
                p9: "उत्पादन और खरीद और विक्री दिशा चुनें",
                p10: "्रेडिंग",
                p11: "क्रम पूरा करने के लिए 'खरीदी/बेची' को क्लिक करें",
                P12: "सेटिंग लाभ बन्द करें या गुमान बन्द करें  तुम्हारी मदद कर सकता है",
                P13: "लाभों में समय के लिए तालीम लगाए और हरामों में सीमा करें",
                P14: "आप सफल खोलने के पृष्ठ पर क्लिक कर सकते हैं",
                P15: "प्रोफाइट रोको/हराम को रोको",
                P16: "विन्यास बनाया जा सकता है, या आप इस पर क्लिक कर सकते हैं बटन को संपादित करेंगे कि लाभ और हराम को रोकने के लिए बिना संबंधि",
                P17: "सेट या रद्द करें",
                P18: "आप लाभ और घाटों को 'बन्द स्थितियों में' देख सकते हैं",
            },
            tab3Row: {
                h1: "मित्रेड में स्वागत है",
                h2: "मदद केंद्र",
                tit0: "सभी प्रकार",
                tit1: "सिमुलेटेड खाता",
                tit2: "एक खाता खोल रहा है",
                tit3: "सोने में और बाहर है",
                tit4: "प्लेटफॉर्म सेटिंग",
                tit5: "ट्रांसकेशन ऑपरेशन",
                tit6: "फीज और चार्ज",
                tit7: "आर्थिक सुरक्षा और नियम",
                tit1Tit1: "CXMDirects क्या मिट्रेड सिमुलेटेड खाता प्रदान करता है",
                tit1Cont1: "<p>CXMDirects ग्राहकों के लिए कार्य करने के लिए सिमुलेटेड खाते प्रदान करें, जो आपको हमारा ऑनलाइन प्लेटफ़ॉर्म और मोबाइल अनुप्रयोग के लिए अनुमत</p>",
                tit1Tit2: "सिमुलेटेड खाता कैसे बनाना है?",
                tit1Cont2: "<p>आपको इमेल पता या फोन संख्या भरने के लिए यहाँ क्लिक करने की जरूरत है, और रिजिस्ट्रेशन को पूरा करने के लिए अपने व्यक्तिगत पासवर्ड</p>",
                tit1Tit3: "सिमुलेटेड खाता का उपयोग क्या है?",
                tit1Cont3: "<p>सिमुलेटेड खाता और वास्तविक खाता के लिए प्लेटफ़ॉर्म के विभिन्न फंक्शन, डाटा तथा कार्यों के अनुसार में बहुत से समान हैं. सिमुलेटेड खाता में आभासी फंड</p>",
                tit1Tit4: "सिमुलेटेड खाता में फ़ंड कैसे प्रविष्ट करना है?",
                tit1Cont4: "<p>आपको सिमुलेशन खाता में फ़ंड इन्जेक्ट करने की जरूरत नहीं है. जब आप सफलतापूर्वक सिमुलेशन खाता को रेजिस्टार करें</p><p>जब सिमुलेटेड खाता में नेट मूल्य 200 USD या AUD से कम है, तो तंत्र स्वचालित रूप से अपने खाता फ़ॉन्ड को $50000 USD या AUD में पूरा करेगा</p>",
                tit1Tit5: "क्या सिमुलेटेड खाता के पास एक उपयोग अवधि है?",
                tit1Cont5: "<p>सिमुलेटेड खाता का वैध अवधि 90 दिन है. यदि आप 90 दिनों के बाद कोई वास्तविक खाता खोलें नहीं, सिमुलेटेड खाता जल्द होगा और व्यापार नहीं किया जाएगा,</p><p>यदि आप 90 दिनों में वास्तविक खाता खोलेंगे, सिमुलेटेड खाता लंबा समय के लिए वैध होगा. एक वास्तविक खाता खोलने के बाद भी आप सिमुलेटेड खाता पर कार्य करने के लिए जारी रख सकते हैं.</p>",
                tit2Tit1: "CXMDirects कितने खाता प्रकार उपलब्ध हैं?  ",
                tit2Cont1: "<p>हम सिर्फ व्यक्तियों के लिए मानक व्यवसायिक ट्रेडिंग खाता प्रदान करते हैं, और कंपनी खोलने के लिए समर्थन नहीं करते</p>",
                tit2Tit2: "मैं क्या मुद्रा में एक ट्रेडिंग खाता खोल सकता हूँ?",
                tit2Cont3: "<p>जब आप एक खाता खोलेंगे, तंत्र स्वचालित रूप से अपने क्षेत्र पर आधारित निर्धारित करेगा और यह परिवर्धित नहीं होगा</p>",
                tit2Tit4: "वास्तविक खाता कैसे खोलें?",
                tit2Cont4: "<p>एक वास्तविक खाता खोलने के लिए नीचे की चरणों को अनुसरण करें</p><p>1. मिट्रेड रिजिस्ट्रेज़िशेशन पृष्ठ भरें और इमेल फोन नंबर रिजिस्ट्रेज़र करने के लिए प्रोम्प्ट को अनुसरण करें, लॉगइन पा</p><p></p><p></p>",
                tit3Tit1: "मैं कैसे डोपोसिट बना सकता हूँ?",
                tit3Cont1: "<p>CXMDirects ग्राहकों से चुने के लिए भिन्न डोपोसिट पद्धति प्रदान करें, लेकिन निम्न के लिए सीमित नहीं है:</p><p>1.Visa/Mastercard - सामान्य तुरंत प्राप्त</p><p>2.ऑनलाइन बैंकिंग पैसा सामान्य 1 कार्य दिन में प्रारंभ किया जाता है</p><p>3.एलेक्ट्रोनिक वालेट (उदाहरण के लिए: स्क्रिल, मोमो, जालो, टॉच न गो, बूस्ट, इत्यादि. - सामान्य तुरन्त रिसिप्ट</p><p>4.एटीएम कार्ड-सामान्य तुरंत प्राप्त</p><p>5.क्यूआर मोबाइल ऑनलाइन बैंकिंग -सामान्य तुरंत प्राप्त</p>",
                tit3Tit2: "मैं एक तीसरे पार्टी नाम के नीचे बैंक खाता इस्तेमाल कर सकता हूँ/क्या आप सोने में या बाहर कार्ड करें",
                tit3Cont2: "<p>मिट्रेड तीसरी पार्टी डोपोजिट स्वीकार नहीं करता. कृपया अपने नाम के नीचे अपने व्यक्तिगत बैंक खाता को इस्तेमाल करें/डोपोसिट या हटाने के लिए पत्ता. यदि आप किसी का नाम या कंपनी बैंक खाता इस्तेमाल करें/फ़ंड में कार्ड, फ़ंड पुनः प्राप्त किया जाएगा</p>",
                tit3Tit3: "मिट्रेड खाता से हटाने के लिए कैसे लागू करना है?   ",
                tit3Cont3: "<p>वेबसाइट प्लेटफ़ॉर्म में लॉग करने के बाद, आप 'कैश आउट' पर क्लिक कर सकते हैं, बैंक खाता या विसा/मास्टरकार्ड चुन सकते हैं, कैश आउट मात्रा में भरें, और फिर 'प्रेषित करें</p>",
                tit4Tit1: "मिट्रेड कितने बार प्रदान करता है?",
                tit4Cont1: "<p>मिट्रेड प्लेटफ़ॉर्म द्वारा दिया गया लेवेरेज अनुपात नियंत्रण अधिकारियों के नियमों के अनुसार सेट किया गया है, और विभिन्न प्लेटफ़ॉर</p>",
                tit4Tit2: "मेरा पासवर्ड कैसे बदलना है?",
                tit4Cont2: "<p>प्लेटफ़ॉर्म में लॉगिंग करने के बाद, आप नया पासवर्ड पुनर्सेट करने के लिए [अधिक] - [सुरक्षा सेटिंग] - T कूटशब्द बदल</p>",
                tit4Tit3: "मिट्रेड ट्रेडिंग प्लेटफ़ॉर्म पर कैसे लगइन करना है?",
                tit4Cont3: "<p>एप्लिकेशन: मेरे पर जाओ और लॉगइन पृष्ठ भरने के लिए उप्पर बायाँ कोने में 'लॉगइन l रिजिस्टरेशन' पर क्लिक करें。</p>वेब: मिट्रेड के आधिकारिक वेबसाइट के ऊपर दायाँ कोने में ‘लॉगइन’ पर क्लिक करें लॉगइन पृष्ठ भरें。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "प्लेटफ़ॉर्म याद देने के लिए क्या पद्धति हैं?",
                tit4Cont4: "<p>CXMDirects इमेल से, एसएमएस, प्लेटफ़ॉर्म प्रोस्ट याद देने के लिए.</p>",
                tit5Tit1: "CXMDirects क्या प्रकार मूल्य फरक प्रदान किया जाता है?",
                tit5Cont1: "<p>हम निम्न मूल्य अलग चर्ज करते हैं और किसी कमिशियन को चार्ज नहीं करते. मूल्य भिन्न फ्लोटिंग है, और वास्तविक मूल्य भिन्न भिन्न चार्ज होगा कि आप व्यापार करते हैं विभिन्न पर आधारित होगा, और विशिष्ट जा</p>",
                tit5Tit2: "बाजार क्लिस सूची कैसे स्थापित करना है?",
                tit5Cont2: "<p>'व्यापार' पर क्लिक करने के लिए सब उत्पादकों को दिखाया जाएगा जो प्लेटफ़ॉर्म व्यापार करने के लिए प्रदर्शित कर सकता है. आप ढूंढने के लिए दहिन्ने इनपुट बाक्स में विविध कोड या नाम भर सकते हैं,  आप खुले स्थितियों की संख्या को हाथ से समायोजित कर सकते हैं और लाभ सेटअप कर सकते हैं और जोखिल नियंत्रण के लिए खो</p>",
                tit5Tit3: "रिजिस्ट्रेशन फॉर्म कैसे स्थापित करना है?",
                tit5Cont3: "<p>[व्यापार] में एक उत्पादक चुनें और [व्यापार/बूई] पर क्लिक करने के लिए एक व्यापार विंडो पॉपअप करेगा</p>",
                tit5Tit4: "स्थिति कैसे बन्द करना है?",
                tit5Cont4: "<p>स्थिति बन्द करने के लिए, ‘खोलें’ पर क्लिक करें ‘स्थिति में’ दृश्य प्लेटफ़ॉर्म में, फिर जिस स्थिति को आप बन्द करना चाहते हैं चुनें</p>",
                tit5Tit5: "प्रतीक्षित क्रम को कैसे परिवर्धित करना या मिटाना है?",
                tit5Cont5: "<p>आदेश परिवर्धित करने या मिटाने के लिए, ‘स्थिति’ खण्ड में दृश्य प्लेटफ़ॉर्म पर ‘क्रमबद्ध’ को क्लिक करें, तब तुम प्रगति में परिवर्</p>",
                tit6Tit1: "क्या ट्रेडिंग प्लेटफ़ॉर्म के प्रयोग के लिए हैंडलिंग फीड चार्ज होगा?",
                tit6Cont1: "<p>CXMDirects एक पूर्णतः कमिशियन मुक्त ट्रेडिंग प्लेटफर्म के लिए। हम निम्न मूल्य भिन्न चार्ज करते हैं, और आपके विशिष्ट ट्रांसेक्शन स्थिति पर आधारित हैं, प्लेटफ़रेम के लिए अतिरिक</p>",
                tit6Tit2: "क्या उत्पन्न के लिए कोई प्रतिदान है?",
                tit6Cont2: "<p>हम ग्राहकों के साथ किसी डोपोसिट संबंधित फीज़ को चार्ज नहीं करेंगे, लेकिन तुम्हारे पैसा या मध्यमिक बैंक को हैंडल हम सुझाते हैं कि तुम संबंधित बैंकों से पूछो कि क्या उन्होंने तुम्हारे लिए कोई फीज चार्ज किया है?</p>",
                tit6Tit3: "क्या हटाने के लिए कोई प्रतिदान है?",
                tit6Cont3: "<p>CXMDirects हमने ग्राहकों को कोई फ़र्स नहीं चार्ज नहीं करेंगे जिसमें तुम सहयोग किया है जब तक कि तुम सहयोग किया है तो न्यूनतम आवश्यकता के नीचे है या महीने क अधिक विस्तृत जानकारी के लिए, कृपया हमारी डोसिट पालिसी को संदर्भ करें.</p>",
                tit7Tit1: "क्या मैं अपने मिट्रेड खाते में पैसा रखा हूँ?",
                tit7Cont1: "<p>नहीं. नियमित नियमों के अनुसार आवश्यक होते हैं जब विश्वास खाताओं में रिटेल ग्राहक डोपोज़िट अलग रिटेल किया जाता ह जब एक ग्राहक के खाते में फंड डोपोजिट किया जाता है या ग्राहक के खाते में से फंड किया जाता है या पैसा किया जाता है, मिट्रेड ग्राहक के पैसे क</p>",
                tit7Tit2: "यदि मिट्रेड बैंक्रॉप्ट होगा या कर्ज में होगा, तो क्या मेरे पैसे भी प्रभावित होगे?",
                tit7Cont2: "<p>नियमित नियमों के अनुसार आवश्यक होते हैं जब विश्वास खाताओं में रिटेल ग्राहक डोपोज़िट अलग रिटेल किया जाता ह यदि मिट्रेड बैंक्रॉप्ट हो जाता है, तो उसकी लाइसेंस हटाया जाता है या कार्यान्वयन करने के लिए अक्षम होता है, ग्राहक फंड अभी भी कारांटिन हो सकता</p>",
                tit7Tit3: "क्या मिट्रेड नियमों के अनुसरण करता है?",
                tit7Cont3: "<p>हमारी कंपनी संबंधित कंपनी नियमशास्त्र और आर्थिक नियमशास्त्र और नियमशास्त्रों के साथ पूरा पूरा पालन करती है, और एक आर्थिक सेवा  हमारी कंपनी ग्राहकों के आर्थिक सुरक्षा के लिए विशेष और पर्याप्त सुरक्षा प्रदान करती है।</p>",
                tit7Tit4: "क्या ग्राहक की व्यक्तिगत जानकारी सुरक्षित है?",
                tit7Cont4: "<p>CXMDirects व्यक्तिगत डाटा सुरक्षा नियमों के अनुसारों के द्वारा विशेष रूप से पालन करें, और कभी ग्राहक व्यक्तिगत डाटा को प् कंपनी सुरक्षित होगी कि कार्याधिकारियों ने व्यवस्था और नियमों द्वारा निश्चित किया हुआ सुरक्षा और गुप्तिगार के संस्थापित के संस्था विवरणों के लिए, कृपया हमारे कंपनी के लिए व्यवस्था प्रदर्शन दस्तावेज़ों का संदर्भ करें.</p>",
            },
            tab4Row: {
                span: "Q verification method",
                p1: "व्यवसायिक स्तर क्रिप्टोवेरेंसी डेरिवेटिव प्लेटफ़ॉर्म के लिए ढूंढने वालों के लिए डेरिब्ट है उ हमारा लक्ष्य यह है कि एक प्रभावी और सारा बाजार बनाना है जो विविध पृष्ठभूमि और व्यापार शैली से व्यापारियों को जो उच्च मेलिंग इंजिन क्षमता, कम लेटेन्सी, उन्नत जीवित प्रबंधन, और उच्च लाइक्विडिटी बाजार में एक अद्वितिक सहभागी बन。",
                p2: "डेरिब्ट का प्रारंभिक लक्ष्य यह है कि दुनिया के पहले क्रिप्टोवेरेंसी विकल्प बदलने के लिए बनाना था. हालाँकि एक बहुत आश्चर्य कार्य होने के बाद, टीम ने तंत्र विकास काम को बस दो सालों से अधिक पूरा किया। जून 2016 में, डेरिब्ट आधिकारिक रूप से चलाया गया。",
                p3: "डेरिब्ट एक क्रिप्टोवेन्सी भविष्य है और विकल्प पानामा सिटी, पानामा में हेडक्वार्ट हैं. डिरिब्ट प्रारंभ में एक पूर्णतः स्वीकारित बीटीसी ट्रेडिंग प्लेटफ़ॉर्म है, हालाँकि इसके बाद से ETH कंट्रांक जोड़े गए हैं, 。",
                p4: "मौजूदा हमारे ग्राहकों ने सदैव कंट्रांट, भविष्य कंट्रांट और विकल्प कंट्रांट व्यापार कर सकते हैं. डेरिब्ट तेज से विकास किया गया है और क्रिप्टोवेलेंसी भविष्यों और सदैव कंट्रांट व्यापार करने के लिए सबसे उच्च बदल इससे भी डेरिब्ट एक प्रमुख बदला है जो यूरोपियाई शैली कैसे सेलेटिंग क्रिप्टोवेलेंसी विकल्पों के द्वारा उपलब्ध करत。",
                p5: "CXMDirects Holdingकैमन द्वीपों के द्वारा अनुमोदन और नियंत्रण किया गया है, सीबी लाइसेंस का संख्या 1612446 है. कैमन लाइसेंस प्लेट पर अधिक विस्तृत विवरण के लिए, कृपया www.cima.ky पर सीआईमा के आधिकारिक वेबसाइट को भेजें (",
                p6: ")क्वेरी का निर्णय करें. मिट्रेड ग्लोबल्यूबल एक आर्थिक सेवा लाइसेंस (एसएसएसएल 398528) के पास ऑस्ट्रेलिया सुरक्षित और निवेश कमिसिया (एसएसीक ऑस्ट्रेलियन लाइसेंस प्लेट पर अधिक विस्तृत विवरण के लिए, कृपया www.asic.gov.au पर ASIC आधिकारिक वेबसाइट को भेजें (",
                p7: ")क्वेरी का निर्णय करें. मारीशियास आर्थात्मक सेवा कमिसियन (एफसीसी) के द्वारा मार्किशस आर्थात्मक संस्था जीबी20025791 की लाइसेंस संख्या क मारीशियास लाइसेंस प्लेट पर अधिक जानकारी के लिए, कृपया www.fscmauricius.org पर एफसीसी आधिकारिक वेबसाइट को भेजें(",
                p8: ")क्वेरी का निर्णय करें. मिट्रेड की सभी व्यवसाय कार्य कार्यान्वयं संकट संदर्भ के अनुसार किया जाता है",
                p9: "पृष्ठभूमि",
                p10: "मिट्रेड है आर्थिक व्यापार और फिनटेक्स्ट्रेड के संस्थापित मेलबर्न, ऑस्ट्रेलिया में स्थापित",
                p11: "मिट्रेड की तत्वज्ञान है कि अनुवादों को आसान और अधिक मित्र बनाना है। हमारे होलिस्टिस्टिक तथा व्यवसाय मॉडल हमें उद्योगिक विकास और बाजार की अनुरोध के सम्पूर्ण और गहिरे समझने के लिए शक्ति देती है, जो हमें अधिक लक्ष्य, निर्धारित नयाँ तकनीकी",
                p12: "क्यों चुनें",
                p13: "कम थ्रेशोल्ड ट्रेडिंग परिस्थिति",
                p14: "इन्ट्यूटिवट्रेडिंग प्लेटफ़ॉर्म",
                p15: "कंपेटिव ट्रांसेक्शन लागत",
                p16: "उद्योगिक अधिकारी द्वारा नियंत्रण",
                p17: "उच्च स्तर ऑनलाइन समर्थन",
                p18: "नकारात्मक संतुलन सुरक्षा",
                p19: "प्रतिज्ञा और सम्मान",
                p20: "मिट्रेड को बहुत से प्रतिज्ञा प्राप्त करने के लिए सम्मानित है, जिसमें विश्वव्यापी फैन्स प्रतिज्ञा के सबसे उत्तम माल्टी आसेट ब्रांडस मैग्लोब्रांडस मोबाइल ट्रेड。",
                p21: "सर्वोत्तम मल्टी एसेट ब्रॉकर",
                p22: "हमारी कार्यान्वयन तत्वज्ञान",
                p23: "हमारे मूल्य",
                p24: "हम दुनिया में सबसे तेज से बढ़ने वाले क्रिप्टोवेरेंसी व्यापार प्लेटफ़ॉर्म हैं, और हम विश्वास करते हैं कि हमारे मुख्य म。",
                p25: "सत्य और सत्यापन",
                p26: "प्रयोक्ता विश्वास क्रियाशील है; इसलिए, हम अपने व्यवसाय को सबसे उच्च स्थानित्व के साथ प्रबंधित करते हैं, सारे बाजार सहभागियों को बराबर रूप से व्यवसाय कर रहे हैं, और उन。",
                p27: "सेवा गुणवत्ता",
                p28: "क्रिप्टोकुरेंसिटी ट्रांसेक्शन्स एक सीमा बिना है, सभी मौसम प्रक्रिया है, जिसमें सेवा क्वालिटी कम्पोरिस नहीं ह ग्राहकारी हमारी सबसे महत्वपूर्ण मूल्य हैं; इसलिए, हमारा लक्ष्य है कि एक अनिर्धारित और सुन्दर व्यापार अनुभव प्रदान करना। हमारा लक्ष्य यह प्रगति है कि हमारे सिस्टम आर्किटेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक्टेक。",
                p29: "बनाएँ",
                p30: "तेज से क्रिप्टोवेलेंसी वातावरण में, नवीनता आवश्यक है. डेरिब्ट एक समाधान ड्राइव्ड कंपनी है जिसे लक्ष्यों प्रयोक्ताओं के लिए सबसे अच्छा व्यापार अनुभव प्रदान करने के लिए, हमा。",
                p31: "डेरिवेटिव क्यों चुनें？",
                p32: "ट्रेडिंग डेरिविटेविट्स के लिए बहुत से फायदे हैं - निम्न ट्रांसेक्शन क़िस्म, उच्च लेविट्रेज, उच्च लाइविट。",
                p33: "डिरिवेटिव व्यापार भी अधिक विविध व्यापार व्यापार व्यापार व्यापार के लिए अनुमति देती है ट्रेडर्स में डेरिवेटिव प्रयोग कर सकते हैं जोखिल, आर्बिट्रेज, या विशिष्ट व्यापार के लिए。",
                p34: "विज्ञान और तकनीक",
                p35: "एक उच्च कार्य सिस्टम सुनिश्चित करने के लिए, यह जीवित होने से पहले दो साल के लिए विकास किया गया。",
                p36: "इस प्लेटफ़ॉर्म के लिए विकास किया गया है कि कम लेटनेसी के साथ बड़ी संख्या निवेदनों को प्रक्रिया करने की  हमने डेरिब्ट के लिए एक अद्वितिय मेलिंग इंजिन विकास किया है, और हमारे सभी तकनीकों स्वामी हैं。",
                p37: "अपने प्रारंभ के बाद, हमारी अतिरिक्त स्वचालित स्वचालित और जोखिया प्रबंधन सिस्टम ने शून्य सामाज。",
                p38: "डेरिब्ट में, हम क्रिप्टोवेरेंसियों के भविष्य में विश्वास करते हैं। हमारा लक्ष्य यह है कि क्रिप्टोवेरेंसी डेरिवेटिव बाजार के आधार पर रहना चाहता है. हम अभिवादन करते हैं कि भविष्य में, क्रिप्टोवेलेंसिस्टोवेलेंसिस्टोवेलेंसिस्टोवेलेंसिस्टोवेलेंसिस्टो हम भविष्य के लिए तैयार हैं, और हमारा तंत्र है。",
            },
        },
        language: {
            tit: "भाषा चुनें",
        },
        market: {
            tit: "मार्केट सेटिंग",
            tit1: "मूल्य प्रदर्शन",
            tit2: "ऊपर और नीचे का रंग",
            buy_price: "मूल्य किया जा रहा है",
            buy_price_tip: "क्रिया मूल्य पर के- लाइन बनाएँ",
            sell_price: "मूल्य बेच रहा है",
            sell_price_tip: "के- लाइन क्रीस विक्रिया में ड्राइव करें",
            color1: "हरी उठाई और लाल गिराई",
            color2: "लाल उठाया और हरा गिराई",
        },
        notice: {
            tit: "सूचना विन्यास",
            tit1: "सूचना चैनल खोलें",
            p1: "कृपया निम्न सूचना चैनल खोलें/सत्यापित करें, महत्वपूर्ण संदेश गुम नहीं है!",
            message: "संदेश",
            push: "पुश करें",
            verify: "वैध",
            verified: "सत्यापित",
            email: "इमेल",
            type1: "मार्केटिंग",
            tit2: "मार्किंग घटना सूचना",
            desc2: "प्लेटफॉर्म की प्रगति सूचना, कार्यालय क्रियाओं और अन्य संदेश",
            tit3: "ऑपरेटिंग सूचना",
            desc3: "श्रीमंत और प्रेशिक ज्ञान सामग्री",
            tit4: "जानकारी रिमाइन्डर",
            desc4: "हाल में आर्थिक डाटा और समाचार जानकारी",
            tit5: "बुद्धिमान फ्लुक्यूटेशन स्मरणकर्ता",
            desc5: "महत्वपूर्ण विविधियों के मूल्य फ्लुक्यूटेक्शन्स समाविष्ट है, तुम्हारी शीघ्र संभावना लेने की म",
            type2: "रिपोर्ट वर्ग",
            tit6: "खाता रिपोर्ट",
            desc6: "आपका खाता रिपोर्ट निम्न रीति में महीने में भेजा जाएगा",
            cycle: "सायकल",
            month: "महिना",
        },
        protocol: {
            tit: "विवरण तथा करार",
            label1: "निजी नीति",
            label2: "उत्पाद खुलासा विवरण।",
            label3: "अनुकूलन करार",
            label4: "रिस्क डिस्क्लोज़र स्टेटमेंट",
        },
        setting: {
            tit: "सेटअप",
            label1: "सुरक्षा सेटिंग",
            label2: "मार्केट सेटिंग",
            label3: "ट्रांसेक्शन विन्यास",
            label4: "भाषा",
            label5: "प्रसंग मोड",
            label6: "सूचना विन्यास",
            label7: "ट्रांसेक्शन मार्गदर्शकName",
            label8: "विवरण तथा करार",
            label9: "कैश साफ करें",
            label10: "संस्करण संख्या",
            logout: "लागइन से लॉग आउट",
        },
        theme: {
            tit: "प्रसंग मोड",
            theme1: "हल्का मोड",
            theme2: "गहरा मोड",
        },
        trade: {
            tit: "ट्रांसेक्शन विन्यास",
            onclick_close: "एक क्लिक बन्द स्थिति",
        }
    },
    trade: {
        fliter_type1: "स्वयं चयन",
        fliter_type2: "मार्केट",
        place_search: "विविध नाम/कोड खोज भरें",
        fliter_type3: "पोपलियर",
        fliter_type4: "विदेशी बदलें",
        fliter_type5: "कमोडिटी",
        fliter_type6: "साझेदार",
        fliter_type7: "सूची",
        fliter_type8: "एनक्रिप्शन",
        tip1: "विश्वव्यापी रूप से निवेश करने के लिए पहिचान परीक्षण पूरा करें",
        sell: "बेचें",
        buy: "खरीद करें",
        add_optional: "स्वयं चयन जोड़ें",
        optional_recommend: "स्वयं चयन सिफारिसिंग",
        change_batch: "बैच बदलें",
        edit_tit: "चयन संपादित करें",
        breed: "विविध",
        topping: "टॉपिंग",
        sort: "छाँटें",
        all: "सभी चुनें",
    },
    table: {
        label_breed: "विविध",
        label_direction: "दिशा",
        label_price: "मूल्य",
        label_operate: "प्रक्रिया",
        empty_tip1: "यह समय कोई याद रिमाइन्डर नहीं",
        empty_tip2: "कोई डेस्पोसिट रेकॉर्ड नहीं",
        empty_tip3: "कोई पैसा डिबरजेंट रेकॉर्ड नहीं",
        label_amount: "पैसा",
        label_type: "प्रकार",
        label_time: "समय",
        label_name: "नाम",
        label_email: "इमेल",
        label_address: "राज्य का देश",
        label_account: "खाता ट्रेडिंग",
        label_currency: "आधार मुद्रा",
        label_company: "मौजूदा खाता खोलने कंपनी",
    },
    form: {
        to: "में",
        start_time: "प्रारंभ समय",
        end_time: "अंतिम समय",
        week: "पिछले सप्ताह",
        month: "पिछला महीना",
        threeMonth: "पिछले तीन महीने में",
        sixMonth: "पिछले छह महीने में",
        label_feedback: "प्रश्न और सुझाव",
        place_feedback: "कृपया अपना प्रश्न या सुझाव भरें",
        label_img: "छवि",
        label_img_tip: "वैकल्पिक, समस्या के स्क्रीनशॉट प्रदान करें",
        feedback_tip: "यदि आपके पास अत्यन्त प्रश्न है, कृपया संपर्क करें",
        online: "ऑनलाइन सेवा",
        label_cardNumber: "पहचान संख्या",
        label_realName: "पूरा नाम",
        place_realName: "कृपया अपना पहला नाम भरें",
        message_realName: "कृपया अपना पूरा नाम भरें",
        label_firstName: "उपनाम",
        place_firstName: "कृपया अपना अंतिम नाम भरें",
        message_firstName: "कृपया अंतिम नाम भरें",
        label_lastName: "नाम",
        place_lastName: "कृपया अपना मध्य नाम भरें",
        message_lastName: "कृपया एक नाम भरें",
        label_birthday: "जन्म का तारीख",
        place_birthday: "कृपया अपने जन्म का तिथि भरें",
        message_birthday: "कृपया जन्म का तारीख भरें",
        label_idType: "दस्तावेज़ प्रकार",
        tip_cardMain: "अपने परिचय कार्ड के फ्रॉन्ट का फोटो ले या अपलोड करें",
        tip_cardBack: "अपने परिचय कार्ड के BEHIND के फोटो ले या अपलोड करें",
        tip_cardMain1: "अपने ड्राइवर की लाइसेंस के फ्रॉन्ट की फोटो ले या अपलोड करें",
        tip_cardBack1: "अपने ड्राइवर की लाइसेंस के BEHIND की फोटो ले या अपलोड करें",
        tip_cardMain2: "अपने पासपोर्ट के एक फोटो ले या अपलोड करें",
        tip_cardBack2: "अपने पासपोर्ट के एक फोटो ले या अपलोड करें",
        label_password: "पासवर्ड",
        place_password: "कृपया पासवर्ड भरें",
        message_password: "कृपया पासवर्ड भरें",
        label_crmpsd: "पासवर्ड पुष्टि करें",
        place_crmpsd: "कृपया पासवर्ड फिर भरें",
        message_crmpsd: "कृपया पासवर्ड फिर भरें",
        label_email: "इमेल",
        place_email: "इमेल पता",
        message_email: "कृपया अपना इमेल पता भरें",
        label_captcha: "परीक्षण कोड",
        place_captcha: "कृपया सत्यापन कोड भरें",
        message_captcha: "कृपया सत्यापन कोड भरें",
        get_captcha: "सत्यापन कोड प्राप्त करें",
        label_phone: "फोन",
        place_phone: "कृपया फोन संख्या भरें",
        message_phone: "कृपया फोन संख्या भरें",
        labelAmount: "पैसा",
        placeAmount: "कृपया मात्रा भरें",
        messageAmount: "कृपया मात्रा भरें",
        labelWalletName: "वालेट नाम",
        messageWalletName: "कृपया वालेट नाम भरें",
        placeWalletName: "कृपया वालेट नाम भरें",
        labelWalletAddress: "वालेट पता",
        messageWalletAddress: "कृपया वालेट पता भरें",
        placeWalletAddress: "कृपया वालेट पता भरें",
        labelWithdrawAccount: "खाता हटाएँ",
        placeWithdrawAccount: "खाता हटाएँ",
        messageWithdrawAccount: "खाता हटाएँ",
		placeTimeLimit: "कृपया एक समय सीमा भरें",
		messageTimeLimit: "कृपया एक समय सीमा भरें",
    },
    city: {
        albania: "अलबानिया",
        algeria: "अल्जीरिया",
        argentina: "अर्जेन्टिना",
        armenia: "आर्मेनिया",
        australia: "ऑस्ट्रेलिया",
        pakistan: "पाकिस्तान",
        austria: "आस्ट्रिया",
        bahrain: "बहरेन",
        belgium: "बेल्जियम",
        bosnia_and_Herzegovina: "बोस्निया और हर्जेगोविना",
        brazil: "ब्राजील",
        brunei: "ब्रुनेई",
        bulgaria: "बुल्गेरिया",
        cambodia: "कंबोडिया",
        canada: "कनाडा",
        cameroon: "कैमेरून",
        chile: "पता पुस्तिका",
        colombia: "कोलंबिया",
        costa_Rica: "कोस्टा रिका",
        croatia: "क्रोएशिया",
        cyprus: "साइप्रस",
        czech_Republic: "चेक गणतंत्र",
        denmark: "डेनमार्क",
        dominican_Republic: "डोमिनिकन गणतंत्र",
        egypt: "मिश्र",
        estonia: "एस्टोनिया",
        ethiopia: "इथोपिया",
        finland: "फिनलैंड",
        france: "फ्रांस",
        georgia: "जार्जिया",
        germany: "जर्मनी",
        ghana: "घाना",
        greece: "ग्रीस",
        guyana: "गुयाना",
        honduras: "होंडुरास",
        hong_Kong_China: "हांग कांग, चीन",
        hungary: "हंगरी",
        iceland: "आइसलैंड",
        ireland: "आयरलैंड",
        italy: "इटली",
        india: "भारत",
        indonesia: "इंडोनेशिया",
        israel: "इस्राएल",
        iran: "इरान",
        iraq: "इराक",
        japan: "जापान",
        kazakstan: "कजाखस्तान",
        kenya: "केनिया",
        korea: "कोरिया",
        kuwait: "कुवेत",
        kyrgyzstan: "किर्गिस्तान",
        laos: "लाओस",
        latvia: "लात्विया",
        lithuania: "लिथुआनिया",
        luxembourg: "लक्समबर्ग",
        macao_China: "मकाउ",
        macedonia: "मेसिडोनिया",
        malaysia: "मलेशिया",
        malta: "माल्टा",
        mexico: "मेक्सिको",
        moldova: "मोल्डोवा",
        monaco: "मोनाको",
        mongolia: "मंगोलिया",
        montenegro: "मोंटेनेग्रो",
        morocco: "मोरोक्को",
        myanmar: "म्यानमार",
        netherlands: "नीदरलैंड",
        new_Zealand: "न्यूजीलैंड",
        nepal: "नेपाल",
        nigeria: "नाइजेरिया",
        norway: "नार्वे",
        oman: "ओमान",
        palestine: "पैलेस्टीन",
        panama: "पानामा",
        paraguay: "पैराग्वे",
        peru: "पेरू",
        philippines: "फिलिपीन्स",
        poland: "पोलेंड",
        portugal: "पोर्टुगाल",
        puerto_Rico: "प्यूरिटो रिको",
        qatar: "कतार",
        romania: "रोमानिया",
        russia: "रूसिया",
        republic_of_Trinidad_and_Tobago: "ट्रिनिदाद तथा टोबागो",
        rwanda: "रुवान्डा",
        saudi_Arabia: "साउदी अरबिया",
        serbia: "सर्बिया",
        singapore: "सिंगापुर",
        slovakia: "स्लोवाकिया",
        slovenia: "स्लोवेनिया",
        south_Africa: "दक्षिण अफ्रीका",
        spain: "स्पेन",
        sri_Lanka: "श्रीलंका",
        sweden: "स्वीडन",
        switzerland: "स्विट्जरलैंड",
        taiwan_China: "ताइवान, चीन",
        tajikistan: "ताजिकिस्तान",
        tanzania: "तान्जानिया",
        thailand: "थाइलैंड",
        turkey: "turkey. kgm",
        turkmenistan: "तुर्कमेनिस्तान",
        ukraine: "तुर्कमेनिस्तान",
        united_Arab_Emirates: "UAE",
        united_Kingdom: "ब्रिटन",
        united_States: "युनाइटेड स्टेट्स",
        uzbekistan: "उज्बेकिस्तान",
        venezuela: "वेनेजुएला",
        vietnam: "विएतनाम",
        afghanistan: "अफगानिस्तान",
        angola: "अंगोला",
        azerbaijan: "अजरबैजान",
        bangladesh: "बांग्लादेश",
        belarus: "बेलारूस",
        belize: "बेलिज",
        benin: "बेनिन",
        bhutan: "भूतान",
        bolivia: "बोलिविया",
        botswana: "बोटस्वाना",
        british_Virgin_Islands: "ब्रिटिश कुमारी द्वीप",
        burkina_Faso: "बर्किना फासो",
        burundi: "बुरुन्डी",
        cape_Verde: "केप वर्डे",
        cayman_Islands: "कैमन द्वीप",
        central_African_Republic: "केंद्रीय अफ्रीकी गणतंत्र",
        chad: "चाड",
        comoros: "कोमोरोस",
        the_Republic_of_the_Congo: "कांगो (ब्राजाविल)",
        democratic_Republic_of_the_Congo: "कांगो",
        djibouti: "जिबुती",
        ecuador: "इक्वेडोर",
        el_Salvador: "एल सल्वाडोर",
        equatorial_Guinea: "इक्वेटोरियल गिनी",
        eritrea: "इरिट्रिया",
        fiji: "फिजी",
        gabon: "गैबन",
        gambia: "गैम्बिया",
        greenland: "ग्रीनलैंड",
        guatemala: "गुआटेमाला",
        guinea: "गिनी",
        haiti: "हैती",
        isle_of_Man: "आइस्ल ऑफ मैन",
        cote_d_Ivoire: "आइवोरी कोस्ट",
        jamaica: "जमैका",
        jordan: "जॉर्डन",
        lebanon: "लेबनान",
        lesotho: "लेसोथो",
        liberia: "लाइबेरिया",
        libya: "लीबिया",
        madagascar: "मैडागास्कर",
        malawi: "मैडागास्कर",
        maldives: "माल्दीव्स",
        mali: "माली",
        mauritania: "मारीटानिया",
        mauritius: "मारीशियस",
        mozambique: "मोजाम्बिक",
        namibia: "नामीबिया",
        nicaragua: "निकारागुआ",
        republic_of_Niger: "नाइजर",
        north_Korea: "उत्तर कोरिया",
        reunion: "रियूनियन",
        san_Marino: "सेन मारिनो",
        senegal: "सेनेगाल",
        sierra_Leone: "सियरा लिओन",
        somalia: "सोमालिया",
        sudan: "सुडान",
        suriname: "सुरीनाम",
        eswatini: "एस्वाटिनी",
        syria: "सिरिया",
        togo: "टोगो",
        tonga: "टोङ्गा",
        tunisia: "ट्यूनीसिया",
        united_States_Virgin_Islands: "वर्जिन द्वीप, यूनाइटेड स्टेट्स",
        uganda: "यूगांडा",
        uruguay: "उरुगुआई",
        vatican: "वाटिकान",
        yemen: "येमेन",
        yugoslavia: "यूगोस्लाविया",
        zambia: "जांबिया",
        zimbabwe: "zimbabwe",
        china: "चीन",
    }
}
