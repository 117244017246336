export default {
  common: {
    expected: "予想収益",
    view: "表示＃ヒョウジ＃",
    confirm: "確認",
    cancel: "キャンセル",
    deposit: "敷金",
    withdraw: "金を引き出す",
    noData: "現在使用可能なデータはありません",
    submit: "送信",
    modify: "変更",
    hand: "手",
    confirm_verify: "検証の確認",
    next: "次のステップ",
    complete: "ドーン",
    delete: "削除＃サクジョ＃",
    countryCode: "国コード",
    no_balance: "アカウント残高が不足しています",
  },
  currency: {
    btcusdt: "ビットコイン",
    ethusdt: "エーテル",
    ltcusdt: "ライト貨幣",
    xrpusdt: "リップル",
    adausdt: "アーダ通貨",
    eosusdt: "ポメロ硬貨",
    dotusdt: "ボカコイン",
    trxusdt: "波場硬貨",
    xmrusdt: "モンロードル",
    dogeusdt: "ドッグ？コイン",
    bnbusdt: "BNB",
    solusdt: "SOL",
    aptusdt: "アプトゥスト",
    fx_saudusd: "AUD USD",
    fx_sgbpusd: "GBPドル",
    fx_seurusd: "ユーロ",
    fx_snzdusd: "ニュージーランドドル（NZD）USD",
    fx_susdcad: "米国のコンピュータ支援設計",
    fx_susdchf: "ドルCHF",
    fx_susdjpy: "ドル対円",
    fx_saudcad: "AUD-CAD",
    fx_saudchf: "AUD-CHF",
    fx_saudjpy: "AUD JPY",
    fx_saudnzd: "AUDニュージーランドドル（NZD）",
    fx_scadchf: "CADスイスフラン",
    fx_scadjpy: "かさん",
    fx_schfjpy: "CHF JPY",
    fx_seuraud: "ユーロ",
    fx_seurcad: "EUR CAD",
    fx_seurgbp: "ユーロポンド",
    fx_seurjpy: "ユーロ円",
    fx_seurnzd: "ユーロニュージーランドドル（NZD）",
    fx_sgbpaud: "GBP AUD",
    fx_sgbpchf: "GBP CHF",
    fx_sgbpjpy: "GBP JPY",
    fx_sgbpnzd: "GBP NZD",
    fx_scadnzd: "カナダドルとニュージーランドドル",
    hf_CL: "WTIニューヨーク原油",
    hf_OIL: "ブレント原油",
    hf_GC: "ニューヨーク金",
    hf_SI: "ニューヨーク銀",
    hf_HG: "銅（米国）",
    hf_NG: "アメリカ天然ガス",
    hf_CAD: "どう",
    hf_AHD: "アルミニウムロンドン",
    HX_AAPL: "ザクロ",
    HX_BABA: "中国電子商取引会社アリババ",
    HX_GOOG: "グーグル",
    HX_WMT: "ウォルマート、ウォルマート（小売業者）",
    HX_AMZN: "HX_AMZN",
    HX_TSLA: "テスラ.",
    HX_N225: "日経225種指数",
    HX_GDAXI: "DAX 30、ドイツ",
    HX_IBEX: "IBEX 35、スペイン",
    HX_SPX: "S＆P 500指数",
    HX_FTSE: "富時100",
    HX_KS11: "KOSPIコリア",
    HX_NZ50: "ニュージーランド50",
    HX_PSI20: "ポルトガルPSI 20",
    hf_ZSD: "ロンドン亜鉛",
    hf_NID: "ロンドンニッケル株式会社",
    hf_PBD: "ロンドンがリード",
    hf_SND: "ロンドンすず",
    hf_XAU: "ロンドン金（現物金）",
    hf_XAG: "ロンドン銀（現物銀）",
    hf_XPT: "プラチナ先物",
    hf_XPD: "パラジウム先物",
    hf_CT: "アメリカ綿",
    hf_SM: "アメリカ豆粕",
    hf_BO: "アメリカ大豆油",
    HX_SENSEX: "SENSX、ムンバイ、インド",
    HX_KLSE: "富時マレーシアKLCI指数",
    HX_KSE100: "パキスタンカラチ",
    HX_FCHI: "CAC 40、フランス",
    HX_SX5E: "ヨーロッシュノーケル50",
    HX_ICEXI: "ICEX、アイスランド",
    HX_ASE: "ギリシャ・アテネのASEホテル",
    HX_OMXC20: "OMXコペンハーゲン20",
    HX_OSEBX: "OSEBX、ノルウェー",
    HX_OMXSPI: "OMXSPI、スウェーデン",
    HX_AMD: "超偉半導体",
    HX_DIS: "ディズニー（名称）",
    HX_MELI: "MercadoLibre社",
    HX_PSX: "フィリップス66",
    HX_TRUE: "TrueCar社",
    HX_SDRL: "Seadrill株式会社",
    HX_MA: "マスターカード（ブランド）",
    HX_NVDA: "NVIDIA、コンピュータグラフィックスカード会社",
    HX_INTC: "インテル",
    HX_GOGO: "Gogo社",
    HX_STX: "希捷科技",
    usdcusdt: "USDC",
    filusdt: "FIL",
    maticusdt: "MATIC",
    linkusdt: "リンク＃リンク＃",
    avaxusdt: "AVAX",
    atomusdt: "AstroCoin",
    icpusdt: "ICP",
    uniusdt: "UNI",
  },
  currenctType: {
    title: "通貨を選択してください",
    currenctType6: "USDT-ERC20",
    currenctType7: "USDT-TRC20",
    currenctType8: "BTC",
    currenctType13: "ETH",
    currenctType14: "ドル",
    currenctType15: "香港通貨",
    currenctType16: "台湾通貨",
    currenctType17: "リンギット",
    currenctType18: "シンガポール・ドル",
    currenctType19: "円",
    currenctType20: "ユーロ",
    currenctType21: "ドルA",
    currenctType22: "ルピー",
    currenctType23: "ポンド",
    currenctType24: "バーツ",
    currenctType25: "ドル",
    currenctType26: "VND",
    currenctType27: "韓円",
    currenctType28: "モップ本",
  },
  addnew3: {
    direction: "方向",
    sell_price: "売出価格",
    number: "数量",
    type: "搬送時間",
    buy_price: "購入価格",
    profit: "損益計算書",
    sell_time: "売り出し時間",
  },
  addnew2: {
    address: {
      second_name: "片假名",
      account_type: "アカウントタイプ",
      ifsc_code: "IFSCコード",
      title: "ウォレットアドレス",
      account_number: "口座番号（普通",
      add_btn: "アドレスを追加",
      label_type: "タイプの選択",
      place_type: "タイプを選択してください",
      label_number: "アカウント",
      place_number: "アカウントを入力してください",
      wallet_address: "ウォレットアドレス",
      bank_name: "金融機関名",
      bank_address: "店番",
      name: "お名前",
      payee_address: "受取人住所",
      swift_code: "Swift/SWiftコード/BIC",
      aba: "ABA",
      branch_number: "支店名",
      branch_code: "ブランチコード",
      bank_code: "銀行コード",
      phone: "携帯番号",
      iban: "イバン",
      sort_code: "ソートコード",
      place_inner: "内容を入力してください",
    },
  },
  addnew: {
    realFirst: "まず認証を行ってください",
    payFirst: "まず取引パスワードを設定してください",
    password1: "ログインパスワードの変更",
    password2: "キャッシュパスワードの変更",
    paypassTit1: "引き出しパスワードを設定してください",
    oldPass: "古いパスワード",
    allView: "すべてを読む",
    confirmOrder: "注文の確認",
    name: "名前",
    fee: "コスト",
    orderCycle: "スケジュール",
    loan: "ローン",
    loanTit: "援助貸付",
    loanHostory: "貸付記録",
    labelProductTitle: "品名",
    immediateRepayment: "即時返済",
    loadAmount: "予定借款金額",
    RepaymentCycle: "ローン返済サイクル",
    day: "日",
    dailyRate: "日次価格",
    repaymentMethod: "返済方法",
    lendingCompany: "貸金会社",
    loanTip: "クレジットローン（画像がはっきり見えるようにしてください）",
    loanTip1: "不動産証明書をアップロードする",
    loanTip2: "収入証明（雇用関係）",
    loanTip3: "銀行取引明細書詳細",
    loanTip4: "身分証明書の正面の写真をアップロード",
    interest: "興味",
    repaid: "返済済",
    unpaid: "未払い",
    tab1: "暗号化通貨",
    tab2: "契約書",
    tab3: "オプション",
    available: "使用可能な制限",
    pending: "ロック",
    equivalent: "とうか",
    fundTransfer: "資金移転",
    flashExchange: "Flash交換",
    select_breed: "通貨の選択",
    from: "開始",
    to: "へ",
    transferQuantity: "転送数量",
    repaymentMethod1: "満期一括返済",
  },
  params: {
    product_foreign: "外国為替",
    product_shop: "商品",
    product_number: "しすう",
    product_stock: "分かち合う",
    product_coin: "暗号化通貨",
    billTypes: [
      { v: 101, name: '充電' },
      { v: 102, name: '預金' },
      { v: 103, name: 'フリーズ' },
      { v: 104, name: '解凍' },
      { v: 105, name: '預金' },
      { v: 106, name: '預金' },
      { v: 201, name: '未定' },
      { v: 202, name: '撤回' },
      { v: 203, name: '成功' },
      { v: 204, name: '引き出し失敗' },
      { v: 205, name: '引き出し手数料' },
      { v: 206, name: '転出' },
      { v: 207, name: '転入' },
      { v: 208, name: '硬貨転出' },
      { v: 209, name: '硬貨転入' },
      { v: 301, name: '契約手数料' },
      { v: 302, name: '契約収益' },
      { v: 303, name: '契約損失' },
      { v: 304, name: '契約保証金' },
      { v: 305, name: '契約保証金返金' },
      { v: 311, name: '購入オプション' },
      { v: 312, name: 'オプションは' },
      { v: 313, name: 'オプション返金' },
      { v: 314, name: 'オプション手数料' },
      { v: 315, name: '通貨購入凍結' },
      { v: 316, name: '通貨購入控除' },
      { v: 317, name: 'コイン購入返品' },
      { v: 318, name: '受け取ったコインを購入する' },
      { v: 319, name: '受け取ったコインを購入する' },
      { v: 320, name: '通貨売上凍結' },
      { v: 321, name: '通貨売上控除' },
      { v: 322, name: '硬貨販売返品' },
      { v: 323, name: 'コインを口座に販売する' },
      { v: 324, name: 'コインを口座に販売する' },
      { v: 325, name: '通貨手数料' },
      { v: 401, name: '鉱機追加' },
      { v: 402, name: '鉱機帰還' },
      { v: 403, name: '採鉱機械収益' },
      { v: 404, name: '鉱機退出' },
      { v: 405, name: '鉱機輸出手数料' },
      { v: 411, name: '借入収入' },
      { v: 412, name: '決済コスト' },
      { v: 413, name: '成功返済' },
    ],
    transType0: "現物",
    transType1: "契約書",
    directionType0: "購入する",
    directionType1: "セールス",
    lockStateType0: "ランニング",
    lockStateType1: "終わった",
    lockStateType2: "已取消",
  },
  lockming: {
    profit: "最近（日次収益）",
    cycle: "金融サイクル",
    limit: "単一の制限",
    dividend_time: "配当金支払時間",
    every_day: "毎日の",
    funds: "供託資金",
    back: "期限付き返還",
    ransom: "繰り上げ償還",
    label_amount: "質権抵当金額",
    place_amount: "入力してください、、、",
    available: "入手可能",
    expect_income: "予想収益",
    title: "ロック掘削",
    tip: "採鉱による収入創出",
    label_today_income: "今日の予想収益",
    label_total_income: "累計収益",
    label_order: "管理対象受注",
    label_number: "単一金額",
    daily_return: "毎日返品",
    subscribe: "購読",
    position: "ポジション",
    label_state: "ステータス",
    subscribe_time: "subscription_time",
    expiration_time: "有効期限",
    income: "収入",
    errorTip1: "金額を入力してください",
    tip_tit: "本当に早期に終了しますか？",
  },
  footer: {
    nav1: "ホーム・ページ",
    nav2: "貿易",
    nav3: "位置",
    nav4: "Hub",
    kline_set1: "市場設定",
    kline_set2: "取引設定",
  },
  login: {
    login: "ログイン＃ログイン＃",
    register: "登録",
    type1: "電話番号",
    type2: "Eメール",
    login_btn: "ログイン＃ログイン＃",
    register_btn: "今すぐ登録",
    psd_login: "パスワードログイン",
    verify_login: "認証コードログイン",
    forget: "パスワードを忘れる？",
    no_account: "アカウントはありませんか？",
    have_account: "既存のアカウント",
  },
  home: {
    home: {
      nav1: "毎日ランキング",
      nav2: "きそ",
      nav3: "私たちについて",
      nav4: "紹介",
      most_follow: "人気がある",
      news_type1: "地層",
      news_type2: "ニュース情報",
      news_type3: "カレンダ＃カレンダ＃",
      news_tab1: "すべて",
      news_tab2: "外国為替",
      news_tab3: "分かち合う",
      news_tab4: "商品",
      news_tab5: "しすう",
      news_tab6: "データ＃データ＃",
      news_tab7: "アクティビティ",
      news_tab8: "に暇を取る",
      label_previous: "前のページ",
      label_predictive: "予測性",
      label_announced: "発表する",
    },
    kilne: {
      label_bp: "閉じる",
      label_sp: "通常オープン",
      label_height: "身長",
      label_low: "最小値",
      tip: "こんにちは、私はCXMDirectsGPT品種アシスタントです",
      row1_tit: "戦略",
      row1_rinei: "日内",
      row1_short: "短期",
      row1_middle: "中間",
      row1_time: "リリース時間",
      row1_tit2: "取引戦略",
      data_sources: "データソース",
      row2_tit1: "データ＃データ＃",
      row2_tit2: "情報",
      row2_label_sell: "売り手",
      row2_label_buy: "買い手",
      tip2: "ご参考までに、当社の立場としません",
      tip3: "15分ごとに更新",
      row2_tit3: "価格変動",
      minute: "分",
      today: "今日",
      right_tit7: "価格変動範囲",
      now_price: "現在の価格",
      right_low_price: "低い",
      right_height_price: "身長",
      details: "詳細",
      right_label1: "単一取引数量",
      right_label2: "さいだいてこ",
      right_label3: "契約の規模",
      right_label4: "最大総持倉量",
      right_label5: "変動価格差",
      right_label6: "宿泊費",
      hand: "手",
      right_sell: "売り出す",
      right_buy: "購入する",
      right_collection_time: "収集時間",
      right_tip3: "取引規模全体から収集",
      right_label9: "取引期間",
      right_label10: "当期",
      right_label11: "次号",
    },
    placeorder: {
      direction_buyPrice: "購入率",
      direction_sellPrice: "売上高",
      direction_sell: "売り出す",
      direction_buy: "購入する",
      market_price: "市場秩序",
      check_price: "登記所",
      sell_tip: "価格が条件に合致したら売る",
      buy_tip: "価格が条件に合っている場合に購入する",
      trade_number: "取引数量",
      hand: "手",
      select_lever: "せんたくぼう",
      right_tip: "敷金がかかる",
      balance: "使用可能",
      label_stop_win_price: "余剰金の停止",
      label_stop_lose_price: "そんしつ",
      expected_profit_loss: "予想損益",
      trailing_stop: "追尾停止",
    },
    ranking: {
      tit: "日上下ランキング",
      p: "ホットスポットをキャッチし、チャンスをつかむ",
      renew: "更新＃コウシン＃",
      growth_chart: "せいちょうきょくせんけい",
      drop_list: "ドロップダウンリスト",
      show_closed_market: "場外ブランドの展示",
      filter1: "全体",
      filter2: "外国人",
      filter3: "商品",
      filter4: "株式証書",
      filter5: "インデックス",
      filter6: "暗号化通貨",
      td1: "ランキング",
      td2: "傾向",
      td3: "1日当たりの上昇幅/販売価格",
    },
    remind: {
      now_sell_price: "現在の販売価格",
      direction_buyPrice: "購入率",
      direction_sellPrice: "売上高",
      direction_sell: "売り出す",
      direction_buy: "購入する",
      equal: "平等",
      p: "以下の価格に達したら注意してください",
      btn1: "リマインダの設定",
      list_tit: "リマインダリスト",
    },
  },
  my: {
    report: {
      tit: "アカウントレポート",
      tab1: "レポート作成",
      tab2: "日次レポート",
      tab3: "月次決算書",
      history_tit: "取引履歴レポート",
      account_tit: "アカウントレポート",
      filter: "スクリーン",
      tip1: "適切な日付範囲を選択して、取引履歴レポートをエクスポートしてください。",
      tip2: "カスタム時間",
      email_send: "電子メールに送信",
    },
    accountMsg: {
      tit: "アカウント情報",
      label1: "個人情報"
    },
    bill: {
      tit: "資本フロー詳細",
      all: "全体",
      time: "時間"
    },
    feedback: {
      tit: "機能の推奨事項"
    },
    identity: {
      tit: "完全な個人情報",
      type1: "国民身分証明書",
      type2: "運転免許証",
      type3: "パスポート",
      tip: "提供されているすべての情報とファイルが完全で真実で正確であることを確認します",
    },
    my: {
      is: "ストップ",
      no: "未経験証",
      verify: "実証済み",
      id_verify: "認証",
      verify_tip: "預金して取引を開始するための認証の完了",
      no_verify: "今すぐ検証",
      balance: "使用可能",
      profit_loss: "損益",
      marginLevel: "保証金レベル",
      btn1: "実際のアカウントに切り替え",
      deposit_withdraw_record: "預金と出金記録",
      capital_flow_details: "資本フロー詳細",
      welfare_center: "福祉センター",
      tip1: "あなたは",
      tip2: "筆賞をもらう",
      feedback: "機能の推奨事項",
      report: "アカウントレポート",
      setting: "設定＃セッテイ＃",
    },
    news: {
      tit: "注意事項"
    },
    record: {
      tit: "預金と出金記録",
      cycle: "じゅんかん"
    },
    share: {
      tit: "友人を招く",
      invite_success: "友達を招待しました",
      tip1: "現金インセンティブ",
      tip2: "友達を最高に招待する",
      tip3: "友人を誘って現金を受け取る",
    },
    welfare: {
      tit: "福祉センター",
      tab1: "カードボリューム",
      tab2: "アクティビティ",
      history_record: "履歴",
      period_validity: "有効期間",
    },
    wallet: {
      tit: "財布",
      addNew: "新しいウォレットの追加"
    },
    withdraw: {
      tit: "金を引き出す",
      addNew: "新しいウォレットの追加"
    },
  },
  order: {
    demo_account: "アナログアカウント",
    real_account: "リアルアカウント",
    tip: "開場後、金は預け入れて取引することができます",
    tab1: "ポジション",
    tab2: "登記所",
    tab3: "履歴",
    list_empty: "現在処理対象の注文はありません",
    start_trade: "取引開始",
    hand_tip: "入力範囲",
    kline: {
      direction_sell: "売り出す",
      direction_buy: "購入する",
      open_price: "寄り付き相場",
      now_price: "現在の価格",
      trade_account: "取引口座",
      demo_account: "アナログアカウント",
      order_id: "オーダーID",
      open_time: "オープン時間",
      stop_profit: "余剰金の停止",
      stop_loss: "そんしつ",
      trailing_stop: "追尾停止",
      float_profit_loss: "フローティングP/L",
      overnight_fee: "宿泊費",
      margin: "デポジット",
      maintainsMargin: "利益を維持する",
      open_value: "寄り付き相場",
      now_value: "現在の値",
      notset: "未設定",
      close_position: "オフ位置",
      close_number: "通常の数量",
      onclick_close: "ワンタッチクローズ位置",
      btn: "クローズ位置の確認",
      balance: "使用可能",
      profit_loss: "損益",
      netValue: "ネット",
      marginLevel: "保証金レベル",
    },
    modify: {
      tit: "職階順序の変更",
      expected_profit_loss: "予想損益"
    },
  },
  setting: {
    security: {
      modify_password_tit: "新しいパスワードの設定",
      verify_email_tit: "Eメールの検証",
      verify_password_tit: "パスワードの確認",
      verify_password_tip: "続行するためにCXMDirectsログインパスワードを入力してください",
      verify_phone_tit: "電話番号の確認",
      tit: "セキュリティ",
      tip: "アカウントを安全に保護し、サービス通知を受け取るためには、少なくとも1つのタイプの認証を有効にすることをお勧めします。",
      label1: "パスワードの変更",
      label2: "キャンセル",
      label3: "二重認証",
      label4: "設備管理",
      tip2: "新しいデバイスのログインには認証が必要です",
    },
    tradenews: {
      tit: "取引ガイド",
      tab1: "クイックスタート",
      tab2: "高度なラーニング",
      tab3: "ヘルプ",
      tab4: "CXMDirectsについて",
      viewMore: "詳細を表示",
      details: {
        tit: "外国為替概論",
        tip1: "予定学習時間",
        tip2: "分"
      },
      tab1Row: {
        tit: "CXMDirectsとの取引を素早く学べる教室です",
        tit1: "どのようにしてCXMDirectsで取引を行いますか。",
        tit2: "実際のアカウントを開設する方法",
        tit3: "どのように取引の頭金を開設するか",
        tit4: "締め付けと切り欠きの設定方法",
        tit5: "どのように平倉にするか",
        p1: "ターゲットを売買して取引することができます。CXMDirectsにポジションを置くことは、",
        p2: "対象製品の価格変動に基づく取引",
        p3: "例えば、金価格の上昇が予想される場合は、その製品を購入します。逆に、金価格が下落すると予測すれば、その製品を販売します。将来が予想通りになれば、",
        p4: "そこから利益を得る",
        p5: "また、CXMDirectsは「レバレッジ取引」を提供しています。レバレッジを使用して、同じ金額の資金取引をより多くの目標にすることができます。",
        p6: "レバレッジは利益を拡大することもできるし、損失を拡大することもできる",
        p7: "これは「保証金取引」とも呼ばれています。口座を開設すると、CXMDirectsアカウントから一定の残高が差し引かれます",
        p8: "初期保証金",
        p9: "製品と売買の方向を選ぶ",
        p10: "取り引き",
        p11: "「購入/販売」をクリックして注文を完了します",
        p12: "[締まり]または[停止]を設定すると、ヘルプが表示されます。",
        p13: "利益を適時にロックし、損失を制限する",
        p14: "正常に開いたページをクリックすることができます",
        p15: "差止/差止",
        p16: "それを設定するか、貸借一致しない受注の「変更」ボタンをクリックして損益を停止できます。",
        p17: "設定またはキャンセル",
        p18: "損益は「在庫処分」で表示できます",
      },
      tab3Row: {
        h1: "ようこそCXMDirectsへ",
        h2: "ヘルプ",
        tit0: "すべての問題",
        tit1: "アナログアカウント",
        tit2: "口座を開く",
        tit3: "現金入退場",
        tit4: "プラットフォームの設定",
        tit5: "取引操作",
        tit6: "料金と料金",
        tit7: "財務セキュリティと規制",
        tit1Tit1: "CXMDirectsがアナログアカウントを提供しているか",
        tit1Cont1: "<p>CXMDirectsは、お客様にアナログアカウントを提供し、リスクを伴うことなく、デルのオンラインプラットフォームとモバイルアプリケーションを使用して取引を行うことができます。</p>",
        tit1Tit2: "シミュレーションアカウントを作成する方法",
        tit1Cont2: "<p>ここをクリックしてメールアドレスや電話番号を記入し、個人用パスワードを設定するだけで登録が完了し、シミュレーションアカウントを取得できます</p>",
        tit1Tit3: "アナログアカウントは何に使いますか。",
        tit1Cont3: "<p>シミュレーションアカウントは、取引インタフェース、データ、操作の面では実際のアカウントと基本的に同じです。<p>シミュレーションアカウントには50,000ドルの仮想資金があり、シミュレーション操作を通じて財務リスクを生じることなくプラットフォームのさまざまな機能を顧客に熟知させることを目的としている</p>",
        tit1Tit4: "どのように資金を模擬口座に注入しますか。",
        tit1Cont4: "<p>シミュレーションアカウントに資金を注入する必要はありません。<p>シミュレーションアカウントの登録に成功すると、アカウントには合計50,000ドルまたは豪ドルがあります。</p><p>シミュレーションアカウントの正味価値が200ドルまたは豪ドル未満の場合、システムは自動的にアカウント資金を50,000ドルまたは豪ドルに補充します。</p>",
        tit1Tit5: "シミュレーション勘定科目に使用期間があるかどうか",
        tit1Cont5: "<p>アナログアカウントの有効期間は90日です。90日後にリアルアカウントを開設しないと、シミュレーションアカウントは凍結され、取引できなくなり、リアルアカウントを開設すると解凍されます</p><br/>90日以内にリアルアカウントを開設すると、シミュレーションアカウントは長期的に有効になります。実際のアカウントを開設した後でも、シミュレーションアカウントで操作を続けることができます</p",
        tit2Tit1: "CXMDirectsはどのようなアカウントタイプを提供していますか。",
        tit2Cont1: "<p>私たちは個人のために標準的で専門的な取引口座を提供しているだけで、会社や連名口座の開設はサポートしていません</p>",
        tit2Tit2: "私はどんな通貨で取引口座を開設できますか。",
        tit2Cont3: "<p>口座を開設すると、地域に応じて自動的に決済通貨が決定され、変更できません。</p>",
        tit2Tit4: "どうやってリアルアカウントを開設しますか？",
        tit2Cont4: "<p>次の手順に従ってリアルアカウントを開設してください</p><p>1。<p>CXMDirects登録ページに進み、プロンプトに従って電子メール電話番号を登録し、ログインパスワードを設定し、コミット確認後に自動的に作成する</p>",
        tit3Tit1: "私はどのように貯金しますか。",
        tit3Cont1: "<p>CXMDirects CXMDirectsは、次のようなさまざまな預金方法をお客様に提供します。</p><p>1.Visa/Mastercard-通常はすぐに受け取ります。</p><p>2.インターネットバンキングの支払いは通常1営業日前に完了します。</p><p>3.電子マネー（Skrill、Momo、Zalo.Touch'n GO、Boostなど）-通常は即時受け取ります。</p><p>4。<p>ATMカード-通常即時入金</p><p>5.QRモバイル・ネットバンキング-通常即時レシート</p>",
        tit3Tit2: "私は第三者名の銀行口座/カード預金または引き出しを使用することができますか？",
        tit3Cont2: "<p>CXMDirectsは第三者預金を受け付けていない。ご自分の名義の個人銀行口座/カード預金または引き出しを使用してください。<p>他人の名前や会社の銀行口座/カード預金を使用した場合、資金は返金されます</p>",
        tit3Tit3: "どのようにしてCXMDirects口座からの引き出しを申請しますか？",
        tit3Cont3: "<p>ウェブサイトにログインしたら、「引き出し」をクリックして、銀行口座またはVisa/Mastercardを選択し、引き出し金額を記入して、「提出」を押すことができます。<p>情報が正しいことを確認すると、CXMDirectsは1～2営業日以内に申請を処理します。</p>",
        tit4Tit1: "CXMDirectsは何回レバレッジ取引を提供しますか。",
        tit4Cont1: "<p>CXMDirectsプラットフォームが提供するレバレッジ比率は、規制当局の規定に基づいて設定されており、製品によって最大レバレッジが異なります。詳細については、取引プラットフォームにログインしてください</p",
        tit4Tit2: "パスワードを変更する方法",
        tit4Cont2: "<p>プラットフォームにログインしたら、[その他]-[セキュリティ設定]-Tパスワード変更を選択して新しいパスワードをリセットできます</p>",
        tit4Tit3: "どのようにしてCXMDirects取引プラットフォームにログインしますか？",
        tit4Cont3: "<p>アプリ：「マイ」に移動し、左上の「ログイン登録」をクリックしてログインページに進みます</p>ネットワーク：CXMDirects公式サイト右上の「ログイン」をクリックしてログインページに進みます<p> </p><p></p><p><p></p></p>",
        tit4Tit4: "プラットフォームのリマインダの方法はどれらがありますか。",
        tit4Cont4: "<p>CXMDirectsは、電子メール、メール、プラットフォームプッシュでアラートを送信します</p>p",
        tit5Tit1: "CXMDirectsはどのような価格差を提供しますか。",
        tit5Cont1: "<p>私たちは手数料を一切取らずに、低い差額を受け取ります。差額は変動し、実際に受け取る差額は取引の品目に依存し、具体的な情報はリアルタイムで取引プラットフォームに表示されます</p",
        tit5Tit2: "市場価格表をどのように作成しますか。",
        tit5Cont2: "<p>「取引」をクリックすると、取引するすべての製品を提供するプラットフォームが表示されます。右側の入力ボックスに品目コードまたは名前を入力して検索し、製品を選択して、「購入/販売」「選択/購入」をクリックして取引ウィンドウをポップアップ表示できます。取引ウィンドウには、現在の価格と必要な保証金が表示されます。<p>オープンポジションの数を手動で調整し、リスクを制御するためにストップロスとストップロスを設定することができます</p>",
        tit5Tit3: "レジストリを作成する方法",
        tit5Cont3: "<p>[取引]で製品を選択し、[販売/購入]をクリックすると取引ウィンドウがポップアップ表示されます</p>",
        tit5Tit4: "どうやって平倉にしますか。",
        tit5Cont4: "<p>ポジションを閉じるには、プラットフォームの「ポジション」で「開く」をクリックし、閉じるポジションを選択し、右側の「閉じる」ボタンをクリックします</p>",
        tit5Tit5: "処理対象の注文を変更または削除する方法は？",
        tit5Cont5: "<p>注文を変更または削除するには、プラットフォームの「場所」セクションの「注文」をクリックして表示し、変更または削除する注文を選択します</p>",
        tit6Tit1: "取引プラットフォームを利用すると手数料がかかりますか？",
        tit6Cont1: "<p>CXMDirectsは完全にコミッションフリーの取引プラットフォームです。<p>私たちは低い差額を受け取ります。お客様の具体的な取引状況に応じて、プラットフォームには夜間利息などの追加料金が発生する可能性があります。</p>",
        tit6Tit2: "敷金は有料ですか。",
        tit6Cont2: "<p>お客様には預金に関する費用は一切請求しませんが、お支払いや仲介銀行には手数料がかかる可能性があります。私たちはあなたに料金を請求したかどうかを関係銀行に問い合わせることをお勧めします</p",
        tit6Tit3: "引き出しは有料ですか。",
        tit6Cont3: "<p>CXMDirectsは、お客様の引き出し金額が最低要件を下回ったり、当月の無料引き出しの最大数を超えたりしない限り、お客様に引き出しに関連する費用を請求しません。詳細については、デルのキャッシュポリシー</p",
        tit7Tit1: "私がCXMDirects口座に振り込んだ資金は他の目的に使われますか？",
        tit7Cont1: "<p>いいえ。規制規定により、必要に応じて小売顧客の預金が信託口座に個別に入金されます。顧客口座に預金したり、顧客口座から引き出したり、支払いをしたりする場合、CXMDirectsは顧客の支払いに関する監督管理規定を厳格に遵守しています</p",
        tit7Tit2: "もしCXMDirectsが倒産したり負債を抱えたりしたら、私の資金も影響を受けますか？",
        tit7Cont2: "<p>規制規定に基づき、必要に応じて小売顧客の預金を信託口座に個別に預ける。<p>CXMDirectsが倒産したり、ライセンスが取り消されたり、運営を継続できなかったりすると、顧客資金は依然として隔離され、規制法律における顧客資金規定の監督を受けることができる。</p>",
        tit7Tit3: "CXMDirectsは法律の規定に合っていますか。",
        tit7Cont3: "<p>当社は関連会社の法律と金融監督管理の法律法規を完全に遵守し、関連監督管理機関の許可を得て厳格に監督管理する金融サービスプロバイダである。当社は厳格な規制要件に従って、お客様の財務安全を全面的に十分に保護しています</p",
        tit7Tit4: "お客様の個人情報は安全ですか。",
        tit7Cont4: "<p>CXMDirectsは「個人データ保護条例」の規定を厳格に遵守し、顧客の個人データは決して公開されない。会社は従業員が法律法規に規定された厳格な安全と秘密保持基準を遵守することを確保し、顧客データの秘密保持と使用手配を高度に重視する。詳細は、弊社の法的開示文書を参照してください</p",
      },

      tab4Row: {
        span: "Q検証方法",
        p1: "CXMDirectsは、専門的な暗号化通貨派生物プラットフォームを探している人の答えです。私たちの目標は、異なる背景や取引スタイルからのトレーダーを結びつける効率的で公平な市場を作ることです。高照合エンジン容量、低遅延、先進的なリスク管理、高流動性により、CXMDirectsは市場で唯一無二の参加者となる",
        p2: "CXMDirectsの最初の目標は、世界初の暗号化通貨オプション取引所の創設です。極めて挑戦的な任務ではあるが、チームは2年以上の間にシステム開発を完了した。2016年6月、CXMDirectsが正式にラインアップ",
        p3: "CXMDirectsはパナマのパナマ・パナマシティに本部を置く暗号通貨先物とオプション取引所です。CXMDirectsは最初は完全に専用のBTC取引プラットフォームだったが、その時からETH契約が追加され、より多くの通貨契約が到来する",
        p4: "現在、デルのお客様は、永続契約、先物契約、オプション契約を取引することができます。CXMDirectsは急速に発展し、暗号化された通貨先物と永久契約取引を提供するトップクラスの取引所の一つである。また、CXMDirectsは依然として欧州式現金決済の暗号化通貨オプションを提供するリーディング取引所であり、業界のための基準作りを続けている",
        p5: "CXMDirects Holdingはケイマン諸島金融管理局（CIMA）によって認可され、規制され、SIBライセンス番号は1612446である。ケイマンのナンバープレートの詳細については、CIMA公式サイトwww.CIMA.ky（",
        p6: ")を検索します。CXMDirectsGlobalは、オーストラリア証券投資委員会（ASIC）が認可した金融サービスライセンス（AFSL 398528）を保有している。オーストラリアのナンバープレートの詳細については、ASIC公式サイトwww.ASIC.gov.au（",
        p7: ")を検索します。CXMDirects Internationalはモーリシャス金融サービス委員会（FSC）によって授権され、規制され、ライセンス番号はGB 20025791である。モーリシャスナンバープレートの詳細については、FSC公式サイトwww.fscmauricius.org（",
        p8: ")を検索します。CXMDirectsのすべての業務運営は厳格な監督の下で行われ、すべての規定を遵守している",
        p9: "背景",
        p10: "CXMDirectsは、オーストラリアのメルボルンに設立された金融取引と金融テクノロジー業界で豊富な経験と知識を持つシニアチームです",
        p11: "CXMDirectsの理念は、取引をより容易に、より友好的にすることです。デルの全体的な戦略とビジネスモデルにより、業界の発展と市場のニーズを全面的に深く理解することができ、リソースをより的確に配分し、技術を革新し、効率を最適化し、お客様により便利で友好的な取引体験をもたらし続けることができます。",
        p12: "なぜ",
        p13: "低敷居取引条件",
        p14: "シンプルで直感的な取引プラットフォーム",
        p15: "競争力のある取引コスト",
        p16: "業界主管部門の監督",
        p17: "高度なオンラインサポート",
        p18: "ふへいこうほご",
        p19: "賞と栄誉",
        p20: "CXMDirectsは、世界金融賞最優秀多資産ブローカー、グローバルブランド誌アジア太平洋地域最優秀モバイル取引プラットフォーム、世界最速の成長を遂げた金融技術ブローカーなど、多くの賞を受賞して光栄に思っており、これらすべてがチームの継続的な努力と献身精神を実証している。",
        p21: "最適な多資産ブローカー",
        p22: "デルの経営理念",
        p23: "デルの価値観",
        p24: "私たちは世界で最も急速に成長している暗号化通貨取引プラットフォームの1つであり、私たちのコア価値観はこの成功の背後にある駆動力であると信じています。",
        p25: "誠実で正直である.",
        p26: "ユーザーの信頼は極めて重要である。そのため、デルは、すべての市場参加者と先進的なセキュリティソリューションを平等に扱う公平なリスク管理システムを提供することで、最高の信頼性基準でビジネスを展開しています",
        p27: "サービス品質",
        p28: "暗号化された通貨取引は、サービスの質が損なわれない国境のない24時間365日のプロセスです。お客様は私たちの最も重要な価値です。そのため、継続的でスムーズな取引体験を提供することを目指しています。デルの目標は、システムアーキテクチャを継続的に拡張し、改善し、メンテナンス時間を最大限に削減し、タイムリーで多言語なカスタマーサービスを提供することで実現することです",
        p29: "作成",
        p30: "テンポの速い暗号化通貨環境では、革新が必要です。CXMDirectsは、拡張性とパフォーマンスの高いシステムアーキテクチャに根ざした、数百万人のユーザーに最適なトランザクション体験を提供するソリューション指向の企業です",
        p31: "なぜ派生品を選んだのか",
        p32: "デリバティブの取引には多くのメリットがあります。取引コストが低く、レバレッジ率が高く、流動性が高く、空売りがしやすい",
        p33: "派生品取引はまた、より多様な取引戦略を許可する。トレーダーはデリバティブを使用してリスクヘッジ、利ざや投機取引を行うことができます",
        p34: "科学技術",
        p35: "高性能システムを確保するために、オンラインになる前に2年以上開発されています",
        p36: "このプラットフォームのために開発されたフレームワークは、大量の要求を低遅延で処理できるようにするためです。私たちはCXMDirectsのために独自のマッチングエンジンを開発しました。私たちのすべての技術は独自のものです",
        p37: "リリース以来、デルのインクリメンタル清算とリスク管理システムにより、社会的損失ゼロを確保",
        p38: "CXMDirectsでは、暗号化された通貨の未来を信じています。私たちの目標は、暗号化された通貨派生物市場の最前線にあり続けることです。将来的には、暗号化された通貨が1人1人に使用され、数百万人が取引されることを想定しています。私たちは未来のために準備をしています。私たちのシステムもそうです。",
      },
    },
    language: {
      tit: "言語の選択"
    },
    market: {
      tit: "市場設定",
      tit1: "価格表示",
      tit2: "うねりの色",
      buy_price: "購入率",
      buy_price_tip: "購入価格にK線を引く",
      sell_price: "売上高",
      sell_price_tip: "販売価格でK線を引く",
      color1: "緑が赤くなって落ちる",
      color2: "赤や緑が落ちてくる",
    },
    notice: {
      tit: "通知設定",
      tit1: "オープン通知チャネル",
      p1: "次の通知チャネルをオン/検証してください。重要なメッセージは見逃さないでください！",
      message: "メールを送る",
      push: "プッシュ",
      verify: "テストと検証",
      verified: "実証済み",
      email: "Eメール",
      type1: "マーケティング",
      tit2: "マーケティング活動のお知らせ",
      desc2: "プラットフォームのプロモーション情報、運営活動、その他の情報",
      tit3: "操作の説明",
      desc3: "専門的な知識材料を豊富にする",
      tit4: "情報リマインダ",
      desc4: "最近の財務データとニュース情報",
      tit5: "インテリジェント変動リマインダ",
      desc5: "重要品目の価格変動を含め、迅速な機会の獲得を支援",
      type2: "レポートクラス",
      tit6: "アカウントレポート",
      desc6: "アカウントレポートは月ごとに次の方法で送信されます",
      cycle: "じゅんかん",
      month: "月に1回",
    },
    protocol: {
      tit: "宣言とプロトコル",
      label1: "プライバシーポリシー",
      label2: "製品開示ステートメント",
      label3: "顧客基本契約",
      label4: "リスク開示声明",
    },
    setting: {
      tit: "設定＃セッテイ＃",
      label1: "セキュリティ設定",
      label2: "市場設定",
      label3: "取引設定",
      label4: "言語",
      label5: "トピック・モード",
      label6: "通知設定",
      label7: "取引ガイド",
      label8: "宣言とプロトコル",
      label9: "キャッシュのクリーンアップ",
      label10: "バージョン",
      logout: "ログオンの終了",
    },
    theme: {
      tit: "トピック・モード",
      theme1: "ライトらいと",
      theme2: "暗い"
    },
    trade: {
      tit: "取引設定",
      onclick_close: "ワンタッチクローズ位置"
    },
  },
  trade: {
    fliter_type1: "オプション",
    fliter_type2: "市場",
    place_search: "品目名/コード検索の入力",
    fliter_type3: "人気がある",
    fliter_type4: "外国人",
    fliter_type5: "商品",
    fliter_type6: "分かち合う",
    fliter_type7: "インデックス",
    fliter_type8: "暗号化通貨",
    tip1: "預金して取引を開始するための認証の完了",
    sell: "売り出す",
    buy: "購入する",
    add_optional: "オプションを追加",
    optional_recommend: "自選推薦",
    change_batch: "ロットの変更",
    edit_tit: "選択内容の編集",
    breed: "品種",
    topping: "そびえ立つ",
    sort: "ソート＃ソート＃",
    all: "すべて選択",
  },
  table: {
    label_breed: "品種",
    label_direction: "方向",
    label_price: "価格",
    label_operate: "操作",
    empty_tip1: "今のところリマインダはありません",
    empty_tip2: "預金記録なし",
    empty_tip3: "キャッシュレス支出記録",
    label_amount: "数量",
    label_type: "を選択してオプションを設定します。",
    label_time: "時間",
    label_name: "名前",
    label_email: "Eメール",
    label_address: "国",
    label_account: "取引口座",
    label_currency: "基本通貨",
    label_company: "現在のサービスエンティティ",
  },
  form: {
    to: "へ",
    start_time: "開始時間",
    end_time: "終了時間",
    week: "先週",
    month: "先月",
    threeMonth: "過去3か月",
    sixMonth: "過去6ヶ月",
    label_feedback: "問題と推奨事項",
    place_feedback: "質問やアドバイスを入力してください",
    label_img: "写真",
    label_img_tip: "オプションで、問題のスクリーンショットを提供",
    feedback_tip: "お急ぎのご質問がございましたら、お問い合わせください",
    online: "オンラインカスタマーサービス",
    label_cardNumber: "身分証明書番号",
    label_realName: "の名前をあげる",
    place_realName: "名前を入力してください",
    message_realName: "名前を入力してください",
    label_firstName: "姓",
    place_firstName: "姓を入力してください",
    message_firstName: "姓を入力してください",
    label_lastName: "ミドルネーム",
    place_lastName: "オプション",
    message_lastName: "名前を入力してください",
    label_birthday: "誕生日：",
    place_birthday: "日/月/年",
    message_birthday: "生年月日を入力してください",
    label_idType: "認証タイプ",
    tip_cardMain: "身分証明書の正面の写真を撮影またはアップロードする",
    tip_cardBack: "身分証明書の裏の写真を撮影またはアップロードする",
    tip_cardMain1: "運転免許証の正面の写真を撮影またはアップロードする",
    tip_cardBack1: "運転免許証の裏の写真を撮影またはアップロードする",
    tip_cardMain2: "パスポート写真の撮影またはアップロード",
    tip_cardBack2: "パスポート写真の撮影またはアップロード",
    label_password: "パスワード",
    place_password: "パスワードを入力してください",
    message_password: "パスワードを入力してください",
    label_crmpsd: "パスワードの確認",
    place_crmpsd: "パスワードを再入力してください",
    message_crmpsd: "パスワードを再入力してください",
    label_email: "Eメール",
    place_email: "Eメールアドレス",
    message_email: "電子メールアドレスを入力してください",
    label_captcha: "認証コード",
    place_captcha: "認証コードを入力してください",
    message_captcha: "認証コードを入力してください",
    get_captcha: "認証コード",
    label_phone: "電話番号",
    place_phone: "電話番号を入力してください",
    message_phone: "電話番号を入力してください",
    labelAmount: "数量",
    placeAmount: "金額を入力してください",
    messageAmount: "金額を入力してください",
    labelWalletName: "ウォレット名",
    messageWalletName: "ウォレット名を入力してください",
    placeWalletName: "ウォレット名を入力してください",
    labelWalletAddress: "ウォレットアドレス",
    messageWalletAddress: "ウォレットアドレスを入力してください",
    placeWalletAddress: "ウォレットアドレスを入力してください",
    labelWithdrawAccount: "引き出し口座",
    placeWithdrawAccount: "引き出し口座",
    messageWithdrawAccount: "引き出し口座",
    placeTimeLimit: "時間制限を入力してください",
    messageTimeLimit: "時間制限を入力してください",
  },
  city: {
    albania: "アルバニア",
    algeria: "アルジェリア",
    argentina: "アルゼンチン",
    armenia: "アルメニア",
    australia: "オーストラリア",
    pakistan: "パキスタン",
    austria: "オーストリア",
    bahrain: "オーストリア",
    belgium: "ベルギー",
    bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
    brazil: "ブラジル",
    brunei: "ブルネイ",
    bulgaria: "ブルガリア",
    cambodia: "カンボジア",
    canada: "カナダ",
    cameroon: "カメルーン",
    chile: "チリ",
    colombia: "コロンビア",
    costa_Rica: "コスタリカ",
    croatia: "クロアチア",
    cyprus: "キプロス",
    czech_Republic: "チェコ共和国",
    denmark: "デンマーク",
    dominican_Republic: "ドミニカ共和国",
    egypt: "エジプト",
    estonia: "エストニア",
    ethiopia: "エチオピア",
    finland: "フィンランド",
    france: "フランス",
    georgia: "グルジア",
    germany: "ドイツ",
    ghana: "ガーナ",
    greece: "ギリシャ",
    guyana: "ガイアナ",
    honduras: "ホンジュラス",
    hong_Kong_China: "中国香港",
    hungary: "ハンガリー",
    iceland: "アイスランド",
    ireland: "アイルランド",
    italy: "イタリア",
    india: "インド",
    indonesia: "インドネシア",
    israel: "イスラエル",
    iran: "イラン",
    iraq: "イラク",
    japan: "日本",
    kazakstan: "カザフスタン",
    kenya: "ケニア",
    korea: "韓国",
    kuwait: "クウェート",
    kyrgyzstan: "キルギス",
    laos: "ラオス",
    latvia: "ラトビア",
    lithuania: "リトアニア",
    luxembourg: "ルクセンブルク",
    macao_China: "中国マカオ",
    macedonia: "マケドニア",
    malaysia: "マレーシア",
    malta: "マルタ",
    mexico: "メキシコ",
    moldova: "モルドバ",
    monaco: "モナコ",
    mongolia: "モンゴル",
    montenegro: "モンテネグロ共和国",
    morocco: "モロッコ",
    myanmar: "ミャンマー",
    netherlands: "オランダ",
    new_Zealand: "ニュージーランド",
    nepal: "ネパール",
    nigeria: "ナイジェリア",
    norway: "ノルウェー",
    oman: "オマーン",
    palestine: "パレスチナ",
    panama: "パナマ",
    paraguay: "パラグアイ",
    peru: "ペルー",
    philippines: "フィリピン",
    poland: "ポーランド",
    portugal: "ポルトガル",
    puerto_Rico: "プエルトリコ",
    qatar: "カタール",
    romania: "ルーマニア",
    russia: "ロシア",
    republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
    rwanda: "ルワンダ",
    saudi_Arabia: "サウジアラビア",
    serbia: "セルビア",
    singapore: "シンガポール",
    slovakia: "スロバキア",
    slovenia: "スロベニア",
    south_Africa: "南アフリカ共和国",
    spain: "スペイン",
    sri_Lanka: "スリランカ",
    sweden: "スウェーデン",
    switzerland: "スイス",
    taiwan_China: "中国台湾",
    tajikistan: "タジキスタン",
    tanzania: "タンザニア",
    thailand: "タイ",
    turkey: "トルコ",
    turkmenistan: "トルクメニスタン",
    ukraine: "ウクライナ",
    united_Arab_Emirates: "アラブ首長国連邦",
    united_Kingdom: "英国",
    united_States: "アメリカ",
    uzbekistan: "ウズベキスタン",
    venezuela: "ベネズエラ",
    vietnam: "ベトナム",
    afghanistan: "アフガニスタン",
    angola: "アンゴラ",
    azerbaijan: "アゼルバイジャン",
    bangladesh: "バングラデシュ",
    belarus: "ベラルーシ",
    belize: "ベリーズ",
    benin: "ベニン",
    bhutan: "ブータン",
    bolivia: "ボリビア",
    botswana: "ボツワナ",
    british_Virgin_Islands: "イギリス領ヴァージン諸島",
    burkina_Faso: "ブルキナファソ",
    burundi: "ブルンジ",
    cape_Verde: "カーボベルデ角",
    cayman_Islands: "ケイマン諸島",
    central_African_Republic: "中央アフリカ共和国",
    chad: "チャド",
    comoros: "コモロ",
    the_Republic_of_the_Congo: "コンゴ（布）",
    democratic_Republic_of_the_Congo: "コンゴ（金）",
    djibouti: "ジブチ",
    ecuador: "エクアドル",
    el_Salvador: "エルサルバドル",
    equatorial_Guinea: "赤道ギニア",
    eritrea: "エリトリア",
    fiji: "フィジー",
    gabon: "ガボン",
    gambia: "ガンビア",
    greenland: "グリーンランド",
    guatemala: "グアテマラ",
    guinea: "ギニア",
    haiti: "ハイチ",
    isle_of_Man: "マーン島",
    cote_d_Ivoire: "コートジボワール",
    jamaica: "ジャマイカ",
    jordan: "ヨルダン",
    lebanon: "レバノン",
    lesotho: "レソト",
    liberia: "リベリア",
    libya: "リビア",
    madagascar: "マダガスカル",
    malawi: "マラウイ",
    maldives: "モルディブ",
    mali: "マリ",
    mauritania: "モーリタニア",
    mauritius: "モーリシャス",
    mozambique: "モザンビーク",
    namibia: "ナミビア",
    nicaragua: "ニカラグア",
    republic_of_Niger: "ニジェール",
    north_Korea: "朝鲜",
    reunion: "レユニオン",
    san_Marino: "サンマリノ",
    senegal: "セネガル",
    sierra_Leone: "シエラレオネ",
    somalia: "ソマリア",
    sudan: "スーダン",
    suriname: "スリナム",
    eswatini: "スワジランド",
    syria: "シリア",
    togo: "トーゴ",
    tonga: "トンガ",
    tunisia: "チュニジア",
    united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
    uganda: "ウガンダ",
    uruguay: "ウルグアイ",
    vatican: "バチカン",
    yemen: "イエメン",
    yugoslavia: "ユーゴスラビア",
    zambia: "ザンビア",
    zimbabwe: "ジンバブエ",
    china: "中国",
  },
};
