export default {
    common: {
        expected: "Erwartete Einnahmen",
        view: "Ansicht",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        deposit: "Kaution",
        withdraw: "Rückzug",
        noData: "Derzeit liegen keine Daten vor",
        submit: "Absenden",
        modify: "Ändern",
        hand: "Hand",
        confirm_verify: "Validierung bestätigen",
        next: "Nächster Schritt",
        complete: "Fertig",
        delete: "Löschen",
        countryCode: "Ländercode",
        no_balance: "Unzureichender Kontostand",
    },
    currency: {
        btcusdt: "Bitcoin",
        ethusdt: "Ätherländer",
        ltcusdt: "Litecoin",
        xrpusdt: "Ripple (Münze)",
        adausdt: "Ada Währung",
        eosusdt: "Pomelomünze",
        dotusdt: "Boca-Münze",
        trxusdt: "Wellenfeldmünze",
        xmrusdt: "Monroe-Münze",
        dogeusdt: "Dogecoin",
        bnbusdt: "BNB",
        solusdt: "SOL",
        aptusdt: "Aptusdt",
        fx_saudusd: "AUD USD",
        fx_sgbpusd: "GBP USD",
        fx_seurusd: "Eurodollar",
        fx_snzdusd: "Neuseeland-Dollar (NZD) USD",
        fx_susdcad: "USD CAD",
        fx_susdchf: "USD CHF",
        fx_susdjpy: "USD JPY",
        fx_saudcad: "AUD CAD",
        fx_saudchf: "AUD CHF",
        fx_saudjpy: "AUD JPY",
        fx_saudnzd: "AUD New Zealand Dollar (NZD)",
        fx_scadchf: "CAD Swiss Franc",
        fx_scadjpy: "Canadian yen",
        fx_schfjpy: "CHF JPY",
        fx_seuraud: "EUR AUD",
        fx_seurcad: "EUR CAD",
        fx_seurgbp: "Euro Pound",
        fx_seurjpy: "Euro Yen",
        fx_seurnzd: "Euro New Zealand dollar (NZD)",
        fx_sgbpaud: "GBP AUD",
        fx_sgbpchf: "GBP CHF",
        fx_sgbpjpy: "GBP JPY",
        fx_sgbpnzd: "GBP NZD",
        fx_scadnzd: "Kanadischer und neuseeländischer Dollar",
        hf_CL: "WTI New York Crude Oil",
        hf_OIL: "Brent crude oil",
        hf_GC: "New York gold",
        hf_SI: "New York silver",
        hf_HG: "Kupfer (US)",
        hf_NG: "U.S. Erdgas",
        hf_CAD: "Kupfer (Chemie)",
        hf_AHD: "London Aluminum",
        HX_AAPL: "Granatapfel",
        HX_BABA: "Alibaba, China E-Commerce Unternehmen",
        HX_GOOG: "Google",
        HX_WMT: "Wal-Mart, Walmart (Einzelhändler)",
        HX_AMZN: "HX_AMZN",
        HX_TSLA: "Tesla",
        HX_N225: "Nikkei 225",
        HX_GDAXI: "DAX30, Deutschland",
        HX_IBEX: "IBEX35, Spanien",
        HX_SPX: "S&P 500 index",
        HX_FTSE: "FTSE 100",
        HX_KS11: "KOSPI Korea",
        HX_NZ50: "Neuseeland 50",
        HX_PSI20: "Portugal PSI20",
        hf_ZSD: "London Zinc",
        hf_NID: "London Nickel",
        hf_PBD: "London lead",
        hf_SND: "London tin",
        hf_XAU: "London Gold (Spot Gold)",
        hf_XAG: "London Silver (Spot Silver)",
        hf_XPT: "Platin futures",
        hf_XPD: "Palladium Futures",
        hf_CT: "US Baumwolle",
        hf_SM: "Amerikanisches Sojabohnenmehl",
        hf_BO: "U.S. Sojabohnen oil",
        HX_SENSEX: "SENSEX, Mumbai, India",
        HX_KLSE: "FTSE Malaysia KLCI",
        HX_KSE100: "Karachi, Pakistan",
        HX_FCHI: "CAC40, Frankreich",
        HX_SX5E: "Europäischer Snooker 50",
        HX_ICEXI: "ICEX, Iceland",
        HX_ASE: "ASE Athens, Griechenland",
        HX_OMXC20: "OMX Copenhagen 20",
        HX_OSEBX: "OSEBX, Norwegen",
        HX_OMXSPI: "OMXSPI, Schweden",
        HX_AMD: "Chaowei Halbleiter",
        HX_DIS: "Disney (name)",
        HX_MELI: "MercadoLibre Inc",
        HX_PSX: "Phillips 66",
        HX_TRUE: "TrueCar Inc",
        HX_SDRL: "Seadrill Ltd",
        HX_MA: "MasterCard (brand)",
        HX_NVDA: "NVIDIA, Hersteller von Computergrafikkarten",
        HX_INTC: "Intel",
        HX_GOGO: "Gogo Inc",
        HX_STX: "Seagate Technologie",
        usdcusdt: "USDC",
        filusdt: "FIL",
        maticusdt: "MATIC",
        linkusdt: "LINK",
        avaxusdt: "AVAX",
        atomusdt: "AstroCoin",
        icpusdt: "ICP",
        uniusdt: "UNI",
    },
    currenctType: {
        title: "Bitte wählen Sie eine Währung",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dollar',
        currenctType15: 'Hong Kong currency',
        currenctType16: 'Taiwan currency',
        currenctType17: 'Ringgit',
        currenctType18: 'Singapore dollar',
        currenctType19: 'Yen',
        currenctType20: 'Euro',
        currenctType21: 'Dollar A',
        currenctType22: 'Rupiah',
        currenctType23: 'Pound',
        currenctType24: 'Thai baht',
        currenctType25: 'Dollar',
        currenctType26: 'VND',
        currenctType27: 'Korean won',
        currenctType28: 'MOP',
    },
    addnew3: {
        direction: "Richtung",
        sell_price: "Verkaufspreis",
        number: "Menge",
        type: "Lieferzeit",
        buy_price: "Kaufpreis",
        profit: "P/L",
        sell_time: "Zeit verkaufen",
    },
    addnew2: {
        address: {
            second_name: "Katakana",
            account_type: "Kontotyp",
            ifsc_code: "IFSC CODE",
            title: "Wallet-Adresse",
            account_number: "Kontonummer",
            add_btn: "Adresse hinzufügen",
            label_type: "Typ auswählen",
            place_type: "Bitte wählen Sie Typ",
            label_number: "Konto",
            place_number: "Bitte geben Sie die Kontonummer ein",
            wallet_address: 'Wallet-Adresse',
            bank_name: 'BANK NAME',
            bank_address: "Bankadresse",
            name: "Name",
            payee_address: 'Anschrift des Empfängers',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Zweigname",
            branch_code: "BRANCH CODE",
            bank_code: "Bank code",
            phone: "Mobiltelefonnummer",
            iban: "IBAN",
            sort_code: "Sortierungscode",
            place_inner: "Bitte Inhalt eingeben",
        },
    },
    addnew: {
        realFirst: "Bitte führen Sie zuerst Identitätsauthentifizierung durch",
        payFirst: "Bitte legen Sie zuerst das Transaktionskennwort fest",
        password1: "Login Passwort ändern",
        password2: "Passwort für Auszahlungen ändern",
        paypassTit1: "Bitte geben Sie Ihr Auszahlungskennwort ein",
        oldPass: "Altes Passwort",
        allView: "Alle gelesen",
        confirmOrder: "eine Bestellung bestätigen",
        name: "Name",
        fee: "Gebühr",
        orderCycle: "Timing",
        loan: "Darlehen",
        loanTit: "Hilfsdarlehen",
        loanHostory: "Kreditunterlagen",
        labelProductTitle: "Produktname",
        immediateRepayment: "Sofortige Rückzahlung",
        loadAmount: "Erwarteter Darlehensbetrag",
        RepaymentCycle: "Kreditrückzahlungszyklus",
        day: "Tag",
        dailyRate: "Tagessatz",
        repaymentMethod: "Rückzahlungsmethode",
        lendingCompany: "Darlehensgesellschaft",
        loanTip: "Kreditkredit (bitte stellen Sie sicher, dass das Bild klar und sichtbar ist)",
        loanTip1: "Eigenschaftsbescheinigung hochladen",
        loanTip2: "Einkommensnachweis (Arbeitsverhältnis)",
        loanTip3: "Angaben zum Kontoauszug",
        loanTip4: "Laden Sie ein Foto von der Vorderseite Ihres Personalausweises hoch",
        interest: "Zinsen",
        repaid: "Erstattung",
        unpaid: "Unbezahlt",
        tab1: "Krypto",
        tab2: "Vertrag",
        tab3: "Option",
        available: "Verfügbare Grenze",
        pending: "Sperren",
        equivalent: "Äquivalent",
        fundTransfer: "Mittelübertragung",
        flashExchange: "Flash-Austausch",
        select_breed: "Währung auswählen",
        from: "Von",
        to: "An",
        transferQuantity: "Übertragungsmenge",
        repaymentMethod1: "Eine Rückzahlung bei Fälligkeit"
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Rohstoffe",
        product_number: "Indizes",
        product_stock: "Aktien",
        product_coin: "Krypto",
        billTypes: [
            { v: 101, name: 'Aufladen' },
            { v: 102, name: 'Kaution' },
            { v: 103, name: 'Einfrieren' },
            { v: 104, name: 'Tauen' },
            { v: 105, name: 'Kaution' },
            { v: 106, name: 'Kaution' },
            { v: 201, name: 'Ausstehend' },
            { v: 202, name: 'Rücknahme' },
            { v: 203, name: 'Erfolgreich' },
            { v: 204, name: 'Auszahlung fehlgeschlagen' },
            { v: 205, name: 'Bearbeitungsgebühr für Auszahlungen' },
            { v: 206, name: 'Transfer aus' },
            { v: 207, name: 'Transfer in' },
            { v: 208, name: 'Münztransfer aus' },
            { v: 209, name: 'Geldtransfer in' },
            { v: 301, name: 'Vertragsabwicklungsgebühr' },
            { v: 302, name: 'Vertragseinkommen' },
            { v: 303, name: 'Vertragsverluste' },
            { v: 304, name: 'Kontraktspanne' },
            { v: 305, name: 'Rückerstattung der vertraglichen Kaution' },
            { v: 311, name: 'Kaufoptionen' },
            { v: 312, name: 'Option zurück' },
            { v: 313, name: 'Option Rückerstattung' },
            { v: 314, name: 'Bearbeitungsgebühr für Optionen' },
            { v: 315, name: 'Einfrieren des Währungskaufs' },
            { v: 316, name: 'Abzug des Währungskaufs' },
            { v: 317, name: 'Rückgabe des Münzkaufs' },
            { v: 318, name: 'Kauf von erhaltenen Münzen' },
            { v: 319, name: 'Kauf von erhaltenen Münzen' },
            { v: 320, name: 'Einfrieren der Währungsverkäufe' },
            { v: 321, name: 'Währungsumtauschabzug' },
            { v: 322, name: 'Rückgabe des Münzverkaufs' },
            { v: 323, name: 'Auf Rechnung verkaufte Münzen' },
            { v: 324, name: 'Auf Rechnung verkaufte Münzen' },
            { v: 325, name: 'Gebühren für die Währungsabwicklung' },
            { v: 401, name: 'Zugabe von Bergbaumaschinen' },
            { v: 402, name: 'Rückgabe von Bergbaumaschinen' },
            { v: 403, name: 'Einnahmen aus Bergbaumaschinen' },
            { v: 404, name: 'Ausgang der Bergbaumaschine' },
            { v: 405, name: 'Bearbeitungsgebühr für den Ausstieg von Bergbaumaschinen' },
            { v: 411, name: 'Krediteinkommen' },
            { v: 412, name: 'Abschlusskosten' },
            { v: 413, name: 'Erfolgreiche Rückzahlung' },
        ],
        transType0: "Spot-Waren",
        transType1: "Vertrag",
        directionType0: "Kaufen",
        directionType1: "Verkauf",
        lockStateType0: "Laufend",
        lockStateType1: "Beendet",
        lockStateType2: "已取消",
    },
    lockming: {
        profit: "Zuletzt (Tageseinkommen)",
        cycle: "Finanzzyklus",
        limit: "Einheitlicher Grenzwert",
        dividend_time: "Auszahlungszeit der Dividende",
        every_day: "täglich",
        funds: "Treuhandfonds",
        back: "Rückgabe nach Ablauf",
        ransom: "Frühzeitige Rückzahlung",
        label_amount: "Verpflichtungsbetrag",
        place_amount: "Bitte geben Sie ein,,,",
        available: "verfügbar",
        expect_income: "Erwartete Einnahmen",
        title: "Abgeschlossener Bergbau",
        tip: "Bergbau für Einkommen",
        label_today_income: "Geschätztes Ergebnis heute",
        label_total_income: "Kumuliertes Einkommen",
        label_order: "Treuhandaufträge",
        label_number: "Einheitlicher Betrag",
        daily_return: "Tägliche Rückgabe",
        subscribe: "abonnieren",
        position: "Position",
        label_state: "Status",
        subscribe_time: "subscription_time",
        expiration_time: "Ablaufzeit",
        income: "Einkommen",
        errorTip1: "Bitte geben Sie den Betrag ein",
        tip_tit: "Bist du sicher, dass du früher aussteigen willst?",
    },
    footer: {
        nav1: "Home",
        nav2: "Handel",
        nav3: "Positionen",
        nav4: "Hub",
        kline_set1: "Marktgestaltung",
        kline_set2: "Transaktionseinstellungen",
    },
    login: {
        login: "Login",
        register: "Registrieren",
        type1: "Telefonnummer",
        type2: "Email",
        login_btn: "Log In",
        register_btn: "Jetzt registrieren",
        psd_login: "Passwort Login",
        verify_login: "Anmeldung zum Verifizierungscode",
        forget: "Passwort vergessen?",
        no_account: "Kein Konto?",
        have_account: "Bestehendes Konto",
    },
    home: {
        home: {
            nav1: "Tagesrang",
            nav2: "Grundlagen",
            nav3: "Über uns",
            nav4: "Befassung",
            most_follow: "Beliebt",
            news_type1: "Strategie",
            news_type2: "News",
            news_type3: "Kalender",
            news_tab1: "Alle",
            news_tab2: "Forex",
            news_tab3: "Aktien",
            news_tab4: "Waren",
            news_tab5: "Indizes",
            news_tab6: "Daten",
            news_tab7: "Ereignis",
            news_tab8: "Urlaub",
            label_previous: "Vorherige",
            label_predictive: "Vorausschauend",
            label_announced: "Angekündigt ",
        },
        kilne: {
            label_bp: "Schließen",
            label_sp: "Öffnen",
            label_height: "Höhe",
            label_low: "Minimum ",
            tip: "Hallo, ich bin der CXMDirectsGPT Sortenassistent",
            row1_tit: "Strategie",
            row1_rinei: "Intraday",
            row1_short: "Kurzfristig",
            row1_middle: "Mittelfristig",
            row1_time: "Freigabezeit",
            row1_tit2: "Handelsstrategie",
            data_sources: "Datenquellen",
            row2_tit1: "Daten",
            row2_tit2: "Info",
            row2_label_sell: "Verkäufer",
            row2_label_buy: "Käufer",
            tip2: "Nur als Referenz, nicht als Position unseres Unternehmens",
            tip3: "Update alle 15 Minuten",
            row2_tit3: "Preisschwankungen",
            minute: "Minute",
            today: "Heute",
            right_tit7: "Preisschwankungsbereich",
            now_price: "Aktueller Preis",
            right_low_price: "Niedrig",
            right_height_price: "Höhe",
            details: "Details",
            right_label1: "Anzahl der Einzelgeschäfte",
            right_label2: "Maximale Hebelwirkung",
            right_label3: "Vertragsgröße",
            right_label4: "Maximale Gesamtposition",
            right_label5: "Schwankende Preisdifferenz",
            right_label6: "Übernachtungsgebühr",
            hand: "Hand",
            right_sell: "Verkaufen",
            right_buy: "Kaufen",
            right_collection_time: "Abholzeit",
            right_tip3: "Sammeln Sie aus der gesamten Transaktionsgröße",
            right_label9: "Handelssitzung",
            right_label10: "Aktueller Zeitraum",
            right_label11: "Nächster Zeitraum",
        },
        placeorder: {
            direction_buyPrice: "Kaufpreis",
            direction_sellPrice: "Verkaufskurs",
            direction_sell: "Verkaufen",
            direction_buy: "Kaufen",
            market_price: "Marktauftrag",
            check_price: "Registrierung",
            sell_tip: "Verkaufen, wenn der Preis die Bedingungen erfüllt",
            buy_tip: "Kaufen Sie, wenn der Preis die Bedingungen erfüllt",
            trade_number: "Transaktionsmenge",
            hand: "Hand",
            select_lever: "Auswahlhebel",
            right_tip: "Kaution erforderlich",
            balance: "Verfügbar",
            label_stop_win_price: "Überschuss stoppen",
            label_stop_lose_price: "Stop loss",
            expected_profit_loss: "Erwarteter Gewinn und Verlust",
            trailing_stop: "Trailing Stop",
        },
        ranking: {
            tit: "Tag auf und ab Ranking",
            p: "Hotspots erfassen und Chancen nutzen",
            renew: "Aktualisiert auf",
            growth_chart: "Wachstumsdiagramm",
            drop_list: "Auswahlliste",
            show_closed_market: "Anzeigen von Off-Market-Marken",
            filter1: "Ganzes",
            filter2: "Ausländische ",
            filter3: "Waren",
            filter4: "Aktienzertifikat",
            filter5: "Index",
            filter6: "Krypto",
            td1: "Rangliste",
            td2: "Trend",
            td3: "Täglicher Anstieg/Verkaufspreis",
        },
        remind: {
            now_sell_price: "Aktueller Verkaufspreis",
            direction_buyPrice: "Kaufpreis",
            direction_sellPrice: "Verkaufskurs",
            direction_sell: "Verkaufen",
            direction_buy: "Kaufen",
            equal: "Gleich",
            p: "Erinnern Sie mich, wenn die folgenden Preise erreicht sind",
            btn1: "Erinnerungen festlegen",
            list_tit: "Erinnerungsliste",
        },
    },
    my: {
        report: {
            tit: "Kontobericht",
            tab1: "Bericht",
            tab2: "Tagesabrechnung",
            tab3: "Monatsrechnung",
            history_tit: "Bericht über den Transaktionsverlauf",
            account_tit: "Kontobericht",
            filter: "Bildschirm",
            tip1: "Bitte wählen Sie den entsprechenden Zeitraum aus, um Ihren Transaktionsverlauf zu exportieren.",
            tip2: "Benutzerdefinierte Zeit",
            email_send: "An E-Mail senden",
        },
        accountMsg: {
            tit: "Kontoinformationen",
            label1: "Persönliche Informationen",
        },
        bill: {
            tit: "Angaben zum Kapitalfluss",
            all: "Ganzes",
            time: "Zeit",
        },
        feedback: {
            tit: "Funktionsempfehlungen",
        },
        identity: {
            tit: "Vollständiges Profil",
            type1: "Nationaler Ausweis",
            type2: "Führerschein",
            type3: "Reisepass",
            tip: "Ich bestätige, dass alle bereitgestellten Informationen und Dokumente vollständig, wahr und korrekt sind",
        },
        my: {
            is: "Stop",
            no: "Nicht verifiziert",
            verify: "Verifiziert",
            id_verify: "Identitätsprüfung",
            verify_tip: "Vollständige Identitätsprüfung, um einzuzahlen und mit dem Handel zu beginnen",
            no_verify: "Jetzt überprüfen",
            balance: "Verfügbar",
            profit_loss: "Gewinn und Verlust",
            marginLevel: "Margenebene",
            btn1: "Wechseln zu einem echten Konto",
            deposit_withdraw_record: "Ein- und Auszahlungsdaten",
            capital_flow_details: "Angaben zum Kapitalfluss",
            welfare_center: "Wohlfahrtszentrum",
            tip1: "Sie haben",
            tip2: "Pen Belohnung zu beanspruchen",
            feedback: "Funktionsempfehlungen",
            report: "Kontobericht",
            setting: "Einstellungen",
        },
        news: {
            tit: "Bekanntmachung",
        },
        record: {
            tit: "Ein- und Auszahlungsdaten",
            cycle: "Zyklus",
        },
        share: {
            tit: "Freunde einladen",
            invite_success: "Erfolgreich eingeladene Freunde",
            tip1: "Bargeldprämien",
            tip2: "Laden Sie Freunde ein, die höchste",
            tip3: "Freunde einladen, Bargeld zu sammeln",
        },
        welfare: {
            tit: "Wohlfahrtszentrum",
            tab1: "Kartenrolle",
            tab2: "Tätigkeit",
            history_record: "Geschichte",
            period_validity: "Geltungsdauer",
        },
        wallet: {
            tit: "Geldbörse",
            addNew: "Neue Geldbörse hinzufügen",
        },
        withdraw: {
            tit: "Auszahlung",
            addNew: "Neue Geldbörse hinzufügen",
        },
    },
    order: {
        demo_account: "Simuliertes Konto",
        real_account: "Live-Konto",
        tip: "Nach Eröffnung kann Gold für Transaktionen hinterlegt werden",
        tab1: "Position",
        tab2: "Registrierung",
        tab3: "Geschichte",
        list_empty: "Es gibt derzeit keine ausstehenden Aufträge",
        start_trade: "Handel beginnen",
        hand_tip: "Eingabebereich",
        kline: {
            direction_sell: "Verkaufen",
            direction_buy: "Kaufen",
            open_price: "Offener Preis",
            now_price: "Aktueller Preis",
            trade_account: "Handelskonto",
            demo_account: "Simuliertes Konto",
            order_id: "Bestellnummer",
            open_time: "Öffnungszeiten",
            stop_profit: "Überschuss stoppen",
            stop_loss: "Stop loss",
            trailing_stop: "Trailing Stop",
            float_profit_loss: "Floating P/L",
            overnight_fee: "Übernachtungsgebühr",
            margin: "Marge",
            maintainsMargin: "Wartungsspanne",
            open_value: "Eröffnungswert",
            now_value: "Aktueller Wert",
            notset: "Nicht gesetzt",
            close_position: "Schließposition",
            close_number: "Normale Menge",
            onclick_close: "Schließposition mit einem Klick",
            btn: "Schließposition bestätigen",
            balance: "Verfügbar",
            profit_loss: "Gewinn und Verlust",
            netValue: "Nettovermögen",
            marginLevel: "Margenebene",
        },
        modify: {
            tit: "Position ändern",
            expected_profit_loss: "Erwarteter Gewinn und Verlust",
        },
    },
    setting: {
        security: {
            modify_password_tit: "Neues Passwort festlegen",
            verify_email_tit: "E-Mail bestätigen",
            verify_password_tit: "Passwort bestätigen",
            verify_password_tip: "Geben Sie Ihr CXMDirects Login Passwort ein, um fortzufahren",
            verify_phone_tit: "Telefonnummer überprüfen",
            tit: "Sicherheit",
            tip: "Um die Sicherheit Ihres Kontos zu schützen und unsere Servicebenachrichtigungen zu akzeptieren, wird empfohlen, mindestens eine Art von Authentifizierung zu aktivieren.",
            label1: "Passwort ändern",
            label2: "Abmelden",
            label3: "Doppelte Überprüfung",
            label4: "Geräteverwaltung",
            tip2: "Neue Geräteanmeldung erfordert Überprüfung",
        },
        tradenews: {
            tit: "Transaktionshandbuch",
            tab1: "Schnell starten",
            tab2: "Fortgeschrittenes Lernen",
            tab3: "Hilfe",
            tab4: "Über CXMDirects",
            viewMore: "Mehr anzeigen",

            details: {
                tit: "Einführung in Devisen",
                tip1: "Geschätzte Lerndauer",
                tip2: "Minute",
            },
            tab1Row: {
                tit: "Dies ist ein Klassenzimmer, um schnell zu lernen, mit CXMDirects zu handeln",
                tit1: "Wie wird der Handel auf CXMDirects durchgeführt?",
                tit2: "Wie man ein echtes Konto eröffnet？",
                tit3: "Wie man eine Position für den Handel eröffnet？",
                tit4: "So legen Sie Stop Profit und Stop Loss fest？",
                tit5: "So schließen Sie eine Position？",
                p1: "Sie können den Gegenstand handeln, indem Sie ihn kaufen oder verkaufen.",
                p2: "Handel basierend auf Preisschwankungen des Zielprodukts",
                p3: "Wenn Sie beispielsweise erwarten, dass der Goldpreis steigt, kaufen Sie das Produkt; Im Gegenteil, wenn Sie vorhersagen, dass der Goldpreis sinken wird, dann verkaufen Sie das Produkt. Wenn die Zukunft den Erwartungen entspricht, können Sie",
                p4: "Davon profitieren",
                p5: "Darüber hinaus bietet CXMDirects 'Leveraged Trading'. Sie können Hebelwirkung verwenden, um mehr Ziele mit der gleichen Menge an Mitteln zu handeln.",
                p6: "Hebelwirkung kann Gewinne steigern und auch Verluste verstärken",
                p7: "Dies wird auch als 'Margin Trading' bezeichnet. Wenn Sie eine Position eröffnen, wird Ihr CXMDirects-Konto ein bestimmtes Guthaben als",
                p8: "AnfangsMARGIN",
                p9: "Wählen Sie Produkte und Kauf- und Verkaufsrichtungen",
                p10: "Transaktion",
                p11: "Klicken Sie auf 'Kaufen/Verkaufen', um die Bestellung abzuschließen",
                p12: "Die Einstellung 'Stop Profit' oder 'Stop Loss' kann Ihnen helfen",
                p13: "Rechtzeitige Gewinnsicherung und Begrenzung von Verlusten",
                p14: "Klicken Sie auf die erfolgreiche Startseite",
                p15: "Stop profit/stop loss",
                p16: "Richten Sie es ein, oder Sie können auf die Schaltfläche 'Ändern' klicken, um den nicht ausgeglichenen Auftrag zu stoppen, um Gewinn und Verlust zu stoppen",
                p17: "Setzen oder Abbrechen",
                p18: "Sie können Gewinne und Verluste in geschlossenen Positionen anzeigen'",
            },
            tab3Row: {
                h1: "Willkommen bei CXMDirects",
                h2: "Hilfe",
                tit0: "Alle Fragen",
                tit1: "Simuliertes Konto",
                tit2: "Eröffnung eines Kontos",
                tit3: "Bargeld ein und aus",
                tit4: "Plattformeinstellungen",
                tit5: "Transaktionen",
                tit6: "Gebühren und Gebühren",
                tit7: "Finanzielle Sicherheit und Vorschriften",
                tit1Tit1: "Bietet CXMDirects simulierte Konten an",
                tit1Cont1: "<p>CXMDirects stellt simulierte Konten für Kunden zur Verfügung, die es Ihnen ermöglichen, unsere Online-Plattform und mobile Anwendungen für Transaktionen zu nutzen und sich risikofrei mit dem Plattformbetrieb vertraut zu machen</p>",
                tit1Tit2: "So erstellen Sie ein simuliertes Konto?",
                tit1Cont2: "<p>Sie müssen nur hier klicken, um Ihre E-Mail-Adresse oder Telefonnummer einzugeben und Ihr persönliches Passwort festzulegen, um die Registrierung abzuschließen und ein simuliertes Konto zu erhalten </p>",
                tit1Tit3: "Was ist die Verwendung von simulierten Konten?",
                tit1Cont3: "<p>Simulierte Konten sind in Bezug auf Transaktionsschnittstelle, Daten und Operationen meist identisch mit realen Konten. Es gibt einen virtuellen Fonds von $50000 auf dem simulierten Konto, mit dem Ziel, Kunden mit den verschiedenen Funktionen der Plattform durch simulierte Operationen ohne finanzielles Risiko vertraut zu machen</p>",
                tit1Tit4: "Wie man Geld auf ein simuliertes Konto injiziert?",
                tit1Cont4: "<p>Sie müssen kein Geld in das Simulationskonto einspeisen. Sobald Sie das Simulationskonto erfolgreich registrieren, gibt es insgesamt 50000 USD oder AUD auf dem Konto</p><p>Wenn der Nettowert im simulierten Konto kleiner als $200 USD oder AUD ist, füllt das System automatisch Ihr Konto auf $50000 USD oder AUD auf $50000 USD oder AUD auf</p>",
                tit1Tit5: "Hat das simulierte Konto einen Nutzungszeitraum",
                tit1Cont5: "<p>Die Gültigkeitsdauer des simulierten Kontos beträgt 90 Tage. Wenn Sie nach 90-Tagen kein echtes Konto eröffnen, wird das simulierte Konto eingefroren und kann nicht gehandelt werden. Es wird bei Eröffnung eines echten Kontos aufgehoben.</p><p>Wenn Sie innerhalb von 90 Tagen ein echtes Konto eröffnen, ist das simulierte Konto lange gültig. Auch nach Eröffnung eines realen Kontos können Sie weiterhin auf einem simulierten Konto arbeiten.</p>",
                tit2Tit1: "Wie viele Kontotypen bietet CXMDirects an?",
                tit2Cont1: "<p>Wir bieten nur Standard- und professionelle Handelskonten für Einzelpersonen und unterstützen nicht die Eröffnung von Firmen- oder Gemeinschaftskonten</p>",
                tit2Tit2: "In welcher Währung kann ich ein Handelskonto eröffnen?",
                tit2Cont3: "<p>Wenn Sie ein Konto eröffnen, ermittelt das System automatisch Ihre Abrechnungswährung basierend auf Ihrer Region und kann nicht geändert werden</p>",
                tit2Tit4: "Wie man ein echtes Konto eröffnet?",
                tit2Cont4: "<p>Bitte befolgen Sie die folgenden Schritte, um ein echtes Konto zu eröffnen</p><p>1. Gehen Sie auf die CXMDirects-Registrierungsseite und folgen Sie den Anweisungen, um eine E-Mail-Telefonnummer zu registrieren, ein Login-Passwort festzulegen und es nach Absenden der Bestätigung automatisch zu erstellen.</p><p></p><p></p>",
                tit3Tit1: "Wie kann ich eine Einzahlung tätigen?",
                tit3Cont1: "<p>CXMDirects CXMDirects bietet Kunden verschiedene Einzahlungsmethoden zur Auswahl, einschließlich, aber nicht beschränkt auf die folgenden:</p><p>1.Visa/Mastercard - Normalerweise sofort erhalten</p><p>2.Online Banking Zahlung erfolgt in der Regel innerhalb eines Werktages im Voraus</p><p>3.Elektronische Geldbörsen (wie Skrill, Momo, Zalo, Touch'n GO, Boost, etc.) - typischerweise sofortiger Empfang</p><p>4. Bankomatkarte in der Regel sofortiger Empfang</p><p>5.QR mobile online banking - in der Regel sofortiger Empfang</p>",
                tit3Tit2: "Kann ich ein Bankkonto/eine Karte unter einem Namen eines Drittanbieters verwenden, um Geld einzuzahlen oder abzuheben",
                tit3Cont2: "<p>CXMDirects akzeptiert keine Einzahlungen durch Dritte. Bitte verwenden Sie Ihr persönliches Bankkonto/Ihre Karte unter Ihrem eigenen Namen, um Geld einzuzahlen oder abzuheben. Wenn Sie den Namen einer anderen Person oder Firmenbankkonto/Karte verwenden, um Geld einzuzahlen, wird das Geld zurückgegeben</p>",
                tit3Tit3: "So beantragen Sie eine Auszahlung vom CXMDirects-Konto?",
                tit3Cont3: "<p>Nachdem Sie sich auf der Website-Plattform angemeldet haben, können Sie auf 'Auszahlung' klicken, ein Bankkonto oder Visa/Mastercard auswählen, den Auszahlungsbetrag eingeben und dann auf 'Absenden' klicken. Nach Bestätigung der Richtigkeit der Angaben wird CXMDirects Ihre Bewerbung innerhalb von 1-2 Werktagen bearbeiten.</p>",
                tit4Tit1: "Wie oft bietet CXMDirects Leveraged Trading an?",
                tit4Cont1: "<p>Die Verschuldungsquote, die von der CXMDirects-Plattform bereitgestellt wird, wird nach den Vorschriften der Regulierungsbehörden festgelegt, und die maximale Verschuldung für verschiedene Produkte variiert ebenfalls. Bitte loggen Sie sich in die Handelsplattform ein, um Details zu erhalten.</p>",
                tit4Tit2: "Wie kann ich mein Passwort ändern?",
                tit4Cont2: "<p>Nach der Anmeldung auf der Plattform können Sie [Mehr] - [Sicherheitseinstellungen T Passwort ändern] wählen, um das neue Passwort zurückzusetzen</p>",
                tit4Tit3: "So melden Sie sich bei der CXMDirects Handelsplattform an?",
                tit4Cont3: "<p>App:Gehen Sie zu 'Mein'und klicken Sie auf 'Anmelden Registrierung' in der oberen linken Ecke, um die Anmeldeseite zu öffnen.</p>Web: Klicken Sie oben rechts auf der offiziellen CXMDirects-Website auf 'Anmelden', um zur Anmeldeseite zu gelangen.<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Was sind die Methoden für Plattform Erinnerungen?",
                tit4Cont4: "<p>CXMDirects Benachrichtigungen per E-Mail, SMS und Plattform Push.</p>",
                tit5Tit1: "Welchen Preisunterschied bietet CXMDirects an?",
                tit5Cont1: "<p>Wir berechnen eine geringe Preisdifferenz und berechnen keine Provision. Die Preisdifferenz ist schwankend, und die tatsächliche Preisdifferenz hängt von der Sorte ab, die Sie handeln, und die spezifischen Informationen werden auf der Handelsplattform in Echtzeit angezeigt.</p>",
                tit5Tit2: "So erstellen Sie eine Marktpreisliste?",
                tit5Cont2: "<p>Wenn Sie auf 'Handel' klicken, werden alle Produkte angezeigt, die die Plattform für den Handel anbieten kann. Sie können den Sortencode oder Namen in das Eingabefeld auf der rechten Seite eingeben, um zu suchen, ein Produkt auswählen und auf 'Kaufen/Verkaufen'SEL/KAUFEN klicken, um ein Handelsfenster zu öffnen. Im Handelsfenster sehen Sie den aktuellen Preis und die erwartete erforderliche Margin. Sie können die Anzahl der offenen Positionen manuell anpassen und Stop Profit und Stop Loss zur Risikokontrolle einrichten</p>",
                tit5Tit3: "So erstellen Sie ein Anmeldeformular?",
                tit5Cont3: "<p>Wenn Sie ein Produkt in [Trade] auswählen und auf [Verkaufen/KAUFEN] klicken, öffnet sich ein Handelsfenster</p>",
                tit5Tit4: "So schließen Sie eine Position?",
                tit5Cont4: "<p>Um eine Position zu schließen, klicken Sie auf 'Öffnen' auf der Plattform in 'Position', wählen Sie dann die Position, die Sie schließen möchten, und klicken Sie dann auf die Schaltfläche 'Schließen' auf der rechten Seite</p>",
                tit5Tit5: "So ändern oder löschen Sie eine ausstehende Order?",
                tit5Cont5: "<p>Um Aufträge zu ändern oder zu löschen, klicken Sie auf der Anzeigeplattform im Abschnitt 'Position' und wählen Sie dann die Aufträge aus, die Sie ändern oder löschen möchten.</p>",
                tit6Tit1: "Wird für die Nutzung der Handelsplattform eine Bearbeitungsgebühr erhoben?",
                tit6Cont1: "<p>CXMDirects ist eine völlig provisionsfreie Handelsplattform. Wir berechnen eine geringe Preisdifferenz und abhängig von Ihrer spezifischen Transaktionssituation können zusätzliche Gebühren für die Plattform anfallen, wie z.B. Übernachtzinsen</p>",
                tit6Tit2: "Gibt es eine Gebühr für die Anzahlung?",
                tit6Cont2: "<p>Wir berechnen Kunden keine einzahlungsbezogenen Gebühren, aber Ihre Zahlungs- oder Intermediärbank kann Bearbeitungsgebühren erheben. Wir empfehlen Ihnen, sich bei den jeweiligen Banken zu erkundigen, ob diese Ihnen Gebühren berechnet haben.</p>",
                tit6Tit3: "Gibt es eine Gebühr für die Auszahlung?",
                tit6Cont3: "<p>CXMDirects berechnet Kunden keine Gebühren im Zusammenhang mit der Auszahlung von Geldern, es sei denn, der Betrag, den Sie abheben, liegt unter der Mindestanforderung oder überschreitet die maximale Anzahl an kostenlosen Auszahlungen für den Monat. Weitere Informationen finden Sie in unserer Auszahlungsrichtlinie.</p>",
                tit7Tit1: "Wird das Geld, das ich auf mein CXMDirects-Konto einzahle, für andere Zwecke verwendet?",
                tit7Cont1: "<p>Nein. Einlagen von Privatkunden werden bei Bedarf gemäß den gesetzlichen Vorschriften separat auf Treuhandkonten hinterlegt. Bei Einzahlungen auf Kundenkonten, Auszahlungen von Kundenkonten oder Zahlungen hält CXMDirects sich strikt an die gesetzlichen Vorschriften bezüglich Kundenzahlungen.</p>",
                tit7Tit2: "Wenn CXMDirects bankrott geht oder Schulden hat, werden auch meine Gelder betroffen sein?",
                tit7Cont2: "<p>Kleinkundeneinlagen werden bei Bedarf gemäß den gesetzlichen Vorschriften separat auf Treuhandkonten hinterlegt. Wenn CXMDirects Konkurs geht, seine Lizenz widerrufen wird oder nicht in der Lage ist, seinen Betrieb fortzusetzen, können Kundengelder weiterhin unter Quarantäne gestellt werden und unterliegen der Aufsicht von Kundengeldvorschriften in regulatorischen Gesetzen.</p>",
                tit7Tit3: "Erfüllt CXMDirects die gesetzlichen Vorschriften?",
                tit7Cont3: "<p>Unser Unternehmen hält sich vollständig an die einschlägigen Unternehmensgesetze und Finanzaufsichtsgesetze und -vorschriften und ist ein Finanzdienstleister, der von den zuständigen Aufsichtsbehörden autorisiert und streng reguliert wird. Unser Unternehmen bietet umfassenden und ausreichenden Schutz der finanziellen Sicherheit unserer Kunden gemäß strengen regulatorischen Anforderungen.</p>",
                tit7Tit4: "Sind die persönlichen Daten des Kunden sicher?",
                tit7Cont4: "<p>CXMDirects hält sich strikt an die Bestimmungen der Datenschutzbestimmungen und die personenbezogenen Daten der Kunden werden niemals offengelegt. Das Unternehmen wird sicherstellen, dass die Mitarbeiter die strengen Sicherheits- und Vertraulichkeitsstandards einhalten, die durch Gesetze und Vorschriften vorgeschrieben sind, und den Regelungen zur Vertraulichkeit und Verwendung von Kundendaten große Aufmerksamkeit widmen. Einzelheiten entnehmen Sie bitte den rechtlichen Hinweisdokumenten unseres Unternehmens.</p>",
            },
            tab4Row: {
                span: "Q Prüfmethode",
                p1: "CXMDirects ist die Antwort für diejenigen, die nach professionellen Kryptowährungs-Derivatplattformen suchen. Unser Ziel ist es, einen effizienten und fairen Markt zu schaffen, der Händler mit verschiedenen Hintergründen und Handelsstilen verbindet. Hohe Matching Engine Kapazität, niedrige Latenz, fortschrittliches Risikomanagement und hohe Liquidität machen CXMDirects zu einem einzigartigen Marktteilnehmer.",
                p2: "Das ursprüngliche Ziel von CXMDirects war es, die weltweit erste Börse für Kryptowährungsoptionen zu schaffen. Trotz einer sehr anspruchsvollen Aufgabe hat das Team die Systementwicklungsarbeit in etwas mehr als zwei Jahren abgeschlossen. Im Juni 2016 wurde CXMDirects offiziell gestartet.",
                p3: "CXMDirects ist eine Kryptowährungs-Futures- und Optionsbörse mit Hauptsitz in Panama City, Panama. CXMDirects war ursprünglich eine vollständig dedizierte BTC-Handelsplattform, seitdem wurden jedoch ETH-Verträge hinzugefügt und weitere Währungskontrakte werden ebenfalls eintreffen.",
                p4: "Derzeit können unsere Kunden unbefristete Verträge, Terminkontrakte und Optionsverträge handeln. CXMDirects hat sich schnell entwickelt und ist eine der Top-Börsen, die Kryptowährungs-Futures und unbefristeten Kontrakthandel anbietet. Darüber hinaus bleibt CXMDirects eine führende Börse, die Kryptowährungsoptionen im europäischen Stil für Bargeldabwicklung anbietet und weiterhin Standards für die Branche setzt.",
                p5: "CXMDirects Holding ist von der Cayman Islands Monetary Authority (CIMA) mit einer SIB-Lizenznummer von 1612446 autorisiert und reguliert. Weitere Informationen zum Cayman Nummernschild finden Sie auf der offiziellen Website von CIMA unter www.cima.ky（",
                p6: ") Führen Sie eine Anfrage durch. CXMDirectsGlobal besitzt eine Financial Services License (AFSL 398528), die von der Australian Securities and Investment Commission (ASIC) genehmigt wurde. Weitere Informationen zum australischen Kennzeichen finden Sie auf der offiziellen ASIC-Website unter www.asic.gov.au (",
                p7: ") Führen Sie eine Anfrage durch. CXMDirects International ist von der Mauritius Financial Services Commission (FSC) mit einer Lizenznummer von GB20025791 autorisiert und reguliert. Weitere Informationen zum Mauritius Kennzeichen finden Sie auf der offiziellen Website des FSC unter www.fscmauricius.org(",
                p8: ")Führen Sie eine Anfrage durch. Alle Geschäftstätigkeiten von CXMDirects werden unter strenger Aufsicht durchgeführt und entsprechen allen Vorschriften",
                p9: "Hintergrund",
                p10: "CXMDirects ist ein Senior-Team mit reicher Erfahrung und Wissen in der Finanz- und Fintech-Industrie, gegründet in Melbourne, Australien",
                p11: "CXMDirectss Philosophie ist es, Transaktionen einfacher und freundlicher zu machen. Unsere ganzheitliche Strategie und unser Geschäftsmodell ermöglichen es uns, ein umfassendes und tiefes Verständnis der Branchenentwicklung und der Marktnachfrage zu haben. So können wir Ressourcen gezielter zuweisen, Technologien kontinuierlich innovieren und die Effizienz optimieren und unseren Kunden kontinuierlich komfortablere und freundlichere Handelserfahrungen bieten.",
                p12: "Warum wählen",
                p13: "Niedrigschwellige Handelsbedingungen",
                p14: "Einfache und intuitive Handelsplattform",
                p15: "Wettbewerbsfähige Transaktionskosten",
                p16: "Beaufsichtigt durch Industriebehörden",
                p17: "Online-Support auf hohem Niveau",
                p18: "Schutz vor negativem Saldo",
                p19: "Auszeichnungen und Auszeichnungen",
                p20: "CXMDirects wird geehrt, mehrere Auszeichnungen zu erhalten, darunter der beste Multi Asset Broker der World Finance Awards, die beste mobile Handelsplattform des Global BrandsMagazine in der Region Asien-Pazifik und der am schnellsten wachsende Broker für Finanztechnologie, die alle die kontinuierlichen Bemühungen und das Engagement des Teams bestätigen.",
                p21: "Bester Multi Asset Broker",
                p22: "Unsere Betriebsphilosophie",
                p23: "Unsere Werte",
                p24: "Wir sind eine der am schnellsten wachsenden Kryptowährungs-Handelsplattformen der Welt und glauben, dass unsere Kernwerte die treibende Kraft hinter diesem Erfolg sind.",
                p25: "Ehrlichkeit und Integrität",
                p26: "Das Vertrauen der Nutzer ist entscheidend; Daher führen wir unser Geschäft mit den höchsten Standards der Integrität durch die Bereitstellung eines fairen Risikomanagementsystems, die gleichberechtigte Behandlung aller Marktteilnehmer und fortschrittliche Sicherheitslösungen.",
                p27: "Servicequalität",
                p28: "Kryptowährungstransaktionen sind ein grenzenloser Allwetterprozess, bei dem die Servicequalität nicht beeinträchtigt wird. Kunden sind unser wichtigster Wert; Daher ist es unser Ziel, ein unterbrechungsfreies und reibungsloses Handelserlebnis zu bieten. Unser Ziel ist es, dies zu erreichen, indem wir unsere Systemarchitektur kontinuierlich erweitern und verbessern, Wartungszeiten minimieren und einen zeitnahen und mehrsprachigen Kundenservice bieten.",
                p29: "erstellen",
                p30: "In der schnelllebigen Kryptowährungsumgebung ist Innovation notwendig. CXMDirects ist ein lösungsorientiertes Unternehmen, das darauf abzielt, Millionen von Benutzern das beste Handelserlebnis zu bieten, basierend auf unserer skalierbaren und leistungsstarken Systemarchitektur.",
                p31: "Warum Derivate wählen？",
                p32: "Der Handel mit Derivaten hat viele Vorteile wie niedrigere Transaktionskosten, höhere Hebelwirkung, höhere Liquidität und einfachere Leerverkäufe.",
                p33: "Der Handel mit Derivaten ermöglicht auch vielfältigere Handelsstrategien. Händler können Derivate verwenden, um Risiken, Arbitrage oder spekulativen Handel abzusichern.",
                p34: "Wissenschaft und Technik",
                p35: "Um ein leistungsfähiges System zu gewährleisten, wurde es seit über zwei Jahren entwickelt, bevor es live geht.",
                p36: "Das für diese Plattform entwickelte Framework soll sicherstellen, dass eine große Anzahl von Anfragen mit geringer Latenz verarbeitet werden kann. Wir haben eine einzigartige Matching Engine speziell für CXMDirects entwickelt, und alle unsere Technologien sind proprietär.",
                p37: "Seit seiner Einführung sorgt unser inkrementelles Clearing- und Risikomanagementsystem für null sozialisierte Verluste.",
                p38: "Bei CXMDirects glauben wir an die Zukunft der Kryptowährungen. Unser Ziel ist es, weiterhin an der Spitze des Marktes für Kryptowährungs-Derivate zu sein. Wir stellen uns vor, dass Kryptowährungen in Zukunft von allen genutzt und von Millionen von Menschen gehandelt werden. Wir sind bereit für die Zukunft und unser System auch.",
            },
        },
        language: {
            tit: "Sprache wählen",
        },
        market: {
            tit: "Marktgestaltung",
            tit1: "Preisanzeige",
            tit2: "Farbe der Höhen und Tiefen",
            buy_price: "Kaufpreis",
            buy_price_tip: "Zeichnen Sie eine K-Linie zum Kaufpreis",
            sell_price: "Verkaufskurs",
            sell_price_tip: "K-Linie zum Verkaufspreis ziehen",
            color1: "Grüner Aufstieg und roter Fall",
            color2: "Roter Aufstieg und grüner Fall",
        },
        notice: {
            tit: "Einstellungen für Benachrichtigungen",
            tit1: "Offene Benachrichtigungskanäle",
            p1: "Bitte öffnen/verifizieren Sie die folgenden Benachrichtigungskanäle, wichtige Nachrichten werden nicht verpasst!",
            message: "Textnachricht",
            push: "Drücken",
            verify: "Prüfung und Überprüfung",
            verified: "Verifiziert",
            email: "E-mail",
            type1: "VERMARKTUNG",
            tit2: "Benachrichtigung über Marketingereignisse",
            desc2: "Werbeinformationen, operative Aktivitäten und andere Mitteilungen der Plattform",
            tit3: "Bedienungsanleitung",
            desc3: "Reichhaltiges und professionelles Wissensmaterial",
            tit4: "Informationserinnerung",
            desc4: "Aktuelle Finanzdaten und Nachrichten",
            tit5: "Intelligente Fluktuationserinnerung",
            desc5: "Einschließlich Preisschwankungen wichtiger Sorten, damit Sie Chancen schnell ergreifen können",
            type2: "Berichtsklasse",
            tit6: "Kontobericht",
            desc6: "Ihr Kontobericht wird monatlich wie folgt verschickt:",
            cycle: "Zyklus",
            month: "Monatlich",
        },
        protocol: {
            tit: "Erklärung und Vereinbarung",
            label1: "Datenschutzerklärung",
            label2: "Erklärung zur Produktinformation",
            label3: "Kundenvereinbarung",
            label4: "Risikohinweis",
        },
        setting: {
            tit: "Einstellungen",
            label1: "Sicherheitseinstellungen",
            label2: "Marktgestaltung",
            label3: "Transaktionseinstellungen",
            label4: "Sprache",
            label5: "Theme-Modus",
            label6: "Einstellungen für Benachrichtigungen",
            label7: "Transaktionshandbuch",
            label8: "Erklärung und Vereinbarung",
            label9: "Cache leeren",
            label10: "Version",
            logout: "Abmelden vom Login",
        },
        theme: {
            tit: "Theme-Modus",
            theme1: "Licht",
            theme2: "Dunkel",
        },
        trade: {
            tit: "Transaktionseinstellungen",
            onclick_close: "Schließposition mit einem Klick",
        }
    },
    trade: {
        fliter_type1: "Optional",
        fliter_type2: "Markt",
        place_search: "Suche nach Sortennamen/Code eingeben",
        fliter_type3: "Beliebt",
        fliter_type4: "Ausländische ",
        fliter_type5: "Waren",
        fliter_type6: "Anteil ",
        fliter_type7: "Index",
        fliter_type8: "Krypto",
        tip1: "Vollständige Identitätsprüfung, um einzuzahlen und mit dem Handel zu beginnen",
        sell: "Verkaufen",
        buy: "Kaufen",
        add_optional: "Selbstauswahl hinzufügen",
        optional_recommend: "Empfehlung zur Selbstauswahl",
        change_batch: "Batch ändern",
        edit_tit: "Auswahl bearbeiten",
        breed: "Rasse",
        topping: "Topping",
        sort: "Sortieren",
        all: "Alle auswählen",
    },
    table: {
        label_breed: "Rasse",
        label_direction: "Richtung",
        label_price: "Preis",
        label_operate: "Betrieb",
        empty_tip1: "Im Moment keine Erinnerung",
        empty_tip2: "Aufzeichnungen ohne Einzahlung",
        empty_tip3: "Keine Barauszahlungsregister",
        label_amount: "Betrag",
        label_type: "Typen ",
        label_time: "Zeit",
        label_name: "Name",
        label_email: "Email",
        label_address: "Land",
        label_account: "Handelskontonummer",
        label_currency: "Basiswährung",
        label_company: "Aktuelle Dienststelle",
    },
    form: {
        to: "An",
        start_time: "Startzeit",
        end_time: "Endzeit",
        week: "Letzte Woche",
        month: "Letzten Monat",
        threeMonth: "Die letzten drei Monate",
        sixMonth: "Letztes halbes Jahr",
        label_feedback: "Probleme und Anregungen",
        place_feedback: "Bitte geben Sie Ihre Frage oder Ihren Vorschlag ein",
        label_img: "Bild",
        label_img_tip: "Optional Screenshots des Problems bereitstellen",
        feedback_tip: "Bei dringenden Fragen wenden Sie sich bitte an",
        online: "Online Service",
        label_cardNumber: "ID-Nummer",
        label_realName: "Vorname",
        place_realName: "Bitte geben Sie Ihren Vornamen ein",
        message_realName: "Bitte geben Sie Ihren Vornamen ein",
        label_firstName: "Name",
        place_firstName: "Bitte geben Sie Ihren Nachnamen ein",
        message_firstName: "Bitte geben Sie Ihren Nachnamen ein",
        label_lastName: "Mittelname",
        place_lastName: "Optional",
        message_lastName: "Bitte geben Sie einen Namen ein",
        label_birthday: "Geburtsdatum",
        place_birthday: "DD/MM/YYYY",
        message_birthday: "Bitte geben Sie das Geburtsdatum ein",
        label_idType: "Art der Überprüfung",
        tip_cardMain: "Machen oder Hochladen eines Fotos von der Vorderseite Ihres Personalausweises",
        tip_cardBack: "Machen oder laden Sie ein Foto von der Rückseite Ihres Personalausweises hoch",
        tip_cardMain1: "Machen oder Hochladen eines Fotos von der Vorderseite Ihres Führerscheins",
        tip_cardBack1: "Machen oder laden Sie ein Foto von der Rückseite Ihres Führerscheins hoch",
        tip_cardMain2: "Machen oder laden Sie ein Foto Ihres Reisepasses hoch",
        tip_cardBack2: "Machen oder laden Sie ein Foto Ihres Reisepasses hoch",
        label_password: "Passwort",
        place_password: "Bitte geben Sie das Passwort ein",
        message_password: "Bitte geben Sie das Passwort ein",
        label_crmpsd: "Passwort bestätigen",
        place_crmpsd: "Bitte geben Sie das Passwort erneut ein",
        message_crmpsd: "Bitte geben Sie das Passwort erneut ein",
        label_email: "E-mail",
        place_email: "E-Mail-Adresse",
        message_email: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        label_captcha: " Prüfcode",
        place_captcha: "Bitte geben Sie den Bestätigungscode ein",
        message_captcha: "Bitte geben Sie den Bestätigungscode ein",
        get_captcha: "Prüfcode",
        label_phone: "Telefon",
        place_phone: "Bitte geben Sie eine Telefonnummer ein",
        message_phone: "Bitte geben Sie eine Telefonnummer ein",
        labelAmount: "Betrag",
        placeAmount: "Bitte geben Sie den Betrag ein",
        messageAmount: "Bitte geben Sie den Betrag ein",
        labelWalletName: "Name der Geldbörse",
        messageWalletName: "Bitte geben Sie den Wallet-Namen ein",
        placeWalletName: "Bitte geben Sie den Wallet-Namen ein",
        labelWalletAddress: "Wallet-Adresse",
        messageWalletAddress: "Bitte geben Sie die Wallet-Adresse ein",
        placeWalletAddress: "Bitte geben Sie die Wallet-Adresse ein",
        labelWithdrawAccount: "Auszahlungskonto",
        placeWithdrawAccount: "Auszahlungskonto",
        messageWithdrawAccount: "Auszahlungskonto",
        placeTimeLimit: "Bitte geben Sie Zeitlimit ein",
        messageTimeLimit: "Bitte geben Sie Zeitlimit ein",
    },
    city: {
        albania: "Albanien",
        algeria: "Algerien",
        argentina: "Argentinien",
        armenia: "Armenien",
        australia: "Australien",
        pakistan: "Pakistan",
        austria: "Österreich",
        bahrain: "Bahrain",
        belgium: "Belgien",
        bosnia_and_Herzegovina: "Bosnien und Herzegowina",
        brazil: "Brasilien",
        brunei: "Brunei",
        bulgaria: "Bulgarien",
        cambodia: "Kambodscha",
        canada: "Kanada",
        cameroon: "Kamerun",
        chile: "Chile",
        colombia: "Kolumbien",
        costa_Rica: "Costa Rica",
        croatia: "Kroatien",
        cyprus: "Zypern",
        czech_Republic: "Tschechische Republik",
        denmark: "Dänemark",
        dominican_Republic: "Dominikanische Republik",
        egypt: "Ägypten",
        estonia: "Estland",
        ethiopia: "Äthiopien",
        finland: "Finnland",
        france: "Frankreich",
        georgia: "Georgien",
        germany: "Deutschland",
        ghana: "Ghana",
        greece: "Griechenland",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Ungarn",
        iceland: "Iceland",
        ireland: "Irland",
        italy: "Italien",
        india: "Indien",
        indonesia: "Indonesien",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japan",
        kazakstan: "Kasachstan",
        kenya: "Kenia",
        korea: "korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgisistan",
        laos: "Laos",
        latvia: "Lettland",
        lithuania: "Litauen",
        luxembourg: "Luxemburg",
        macao_China: "Macao_China",
        macedonia: "Mazedonien",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexiko",
        moldova: "Moldawien",
        monaco: "Monaco",
        mongolia: "Mongolei",
        montenegro: "Montenegro",
        morocco: "Marokko",
        myanmar: "Myanmar",
        netherlands: "Niederlande",
        new_Zealand: " Neuseeland",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norwegen",
        oman: "Oman",
        palestine: "Palästina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "die Philippinen",
        poland: "Polen",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Katar",
        romania: "Rumänien",
        russia: "Russland",
        republic_of_Trinidad_and_Tobago: "trinidad and tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Saudi Arabien",
        serbia: "Serbien",
        singapore: "Singapur",
        slovakia: "Slowakei",
        slovenia: "Slowenien",
        south_Africa: "Südafrika",
        spain: "Spanien",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Schweiz",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tadschikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Die Vereinigten Arabischen Emirate",
        united_Kingdom: "Großbritannien",
        united_States: " Amerika",
        uzbekistan: "Usbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Aserbaidschan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivien",
        botswana: "Botswana",
        british_Virgin_Islands: "Britische Jungferninseln",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Kap Verde",
        cayman_Islands: "Kaimaninseln",
        central_African_Republic: "Zentralafrikanische Republik",
        chad: "Tschad",
        comoros: "Komoren",
        the_Republic_of_the_Congo: " Kongo (Brazzaville)",
        democratic_Republic_of_the_Congo: "Kongo (Kinshasa)",
        djibouti: "Dschibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Äquatorialguinea",
        eritrea: "Eritrea",
        fiji: "Fidschi",
        gabon: "Gabun",
        gambia: "Gambia",
        greenland: "Grönland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Insel Man",
        cote_d_Ivoire: "Côte d'Ivoire",
        jamaica: "Jamaika",
        jordan: "Jordan",
        lebanon: "Libanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libyen",
        madagascar: "Madagaskar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauretanien",
        mauritius: "Mauritius",
        mozambique: "Mosambik",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "the Niger",
        north_Korea: " North_Korea",
        reunion: "Wiedervereinigung",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "sultan",
        suriname: "Surinam",
        eswatini: "Eswatini",
        syria: "Syrien",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunesien",
        united_States_Virgin_Islands: "Virgin Islands, Vereinigte Staaten",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikan",
        yemen: "Jemen",
        yugoslavia: "Jugoslawien",
        zambia: "Sambia",
        zimbabwe: "Simbabwe",
        china: "China",
    }
};
